import type { DataEntry } from 'types/dataEntries';
import type { DrillDownData } from 'types/costViews/drillDown';

import { ValueBox } from 'shared/ValueBox';
import { GroupByType } from 'services/metric';
import { useDrillDownQuery } from 'hooks/useDrillDownQuery';

const LABELS: Record<GroupByType, string> = {
  accounts: 'Accounts',
  regions: 'Regions',
  services: 'Services',
  usage_types: 'Usage Types',
  operations: 'Operations',
  resources: 'Resources',
}

export interface BreakdownHeaderStatusProps {
  drillDown: DataEntry<DrillDownData>;
  format: (value: number) => string;
}

export const BreakdownHeaderStatus = (props: BreakdownHeaderStatusProps) => {
  const { drillDown, format } = props;
  const { breakdown } = useDrillDownQuery();

  const drilldownTableData = drillDown.status === 'success' ? drillDown.data.table_data : [];
  const currentTotal = drilldownTableData.reduce((st, next) => st + next.current_timeframe_cost, 0)

  const values = [
    { label: `Total ${LABELS[breakdown]}`, text: drilldownTableData.length },
    { label: 'Current Total', text: format(currentTotal) },
  ]

  return (
    <div className='flex gap-5'>
      {values.map((v) => <ValueBox.SimpleValueBox key={v.label}  label={v.label} text={v.text} />)}
    </div>
  )
};
