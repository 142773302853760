import { Link } from 'react-router-dom';
import { Icon } from 'shared/Icon';
import { EditableTitle } from 'shared/EditableTitle';
import { useCostViewConstructor, useCostViewsStore } from 'store/costLab';

export interface PageTitleProps {
  viewId: number | string;
}

export const PageTitle = (props: PageTitleProps) => {
  const {
    viewId
  } = props;

  const costViewConstructor = useCostViewConstructor();
  const draft = useCostViewsStore((store) => store.getDraft(viewId, costViewConstructor));
  const setDraft = useCostViewsStore((store) => store.setDraft);

  if (draft.status === 'loading') {
    return <>Loading</>;
  }

  if (draft.status !== 'success') {
    return <></>;
  }

  if (viewId === 'new') {
    return <>Cost Lab</>
  }

  return (
    <div className='flex items-center gap-2'>
      <Link
        to='/costs-overview/library'
        className='text-blue hover:text-blue-hover'
      >
        Cost Library
      </Link>

      <Icon icon='arrow-right' />

      <EditableTitle
        value={draft.data.name}
        placeholder='Enter view name'
        onChange={(name) => {
          setDraft(viewId, {
            ...draft.data,
            name
          });
        }}
      />
    </div>
  );
}
