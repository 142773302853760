import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import { Header, InputField } from 'components/Auth'
import { ReactComponent as Checkmark } from 'assets/images/Checkmark.svg'
import apiService from 'helpers/api'

const SignUpByInvite = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [password, setPassword] = useState('')
  const [isAgreedTerms, setIsAgreedTerms] = useState(false)
  const [onceSubmitted, setOnceSubmitted] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const isDisabledSubmit = !password || !isAgreedTerms || submitting;

  const emailParam = searchParams.get('email')

  const handlePrivacy = () => {
    window.open('https://www.cloudthread.io/privacy-policy', '_blank')
  }

  const handleTerms = () => {
    window.open('https://www.cloudthread.io/terms-of-use', '_blank')
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    if (e.target.value.length === 0 && onceSubmitted) {
      setPasswordError('Required Field')
    } else {
      setPasswordError('')
    }
  }

  const toggleAgreeTerms = () => {
    setIsAgreedTerms(!isAgreedTerms)
  }

  const handleCreateAccount = async () => {
    if (isDisabledSubmit) return
    if (!onceSubmitted) {
      setOnceSubmitted(true)
    }

    if (password.length <= 7) {
      setPasswordError('Password should be more than 7 characters long')
      return
    }

    if (password.length > 20) {
      setPasswordError('Password should be less than 20 characters long')
      return
    }

    const regularExpression = /^(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@\^_`{|}~])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@\^_`{|}~]{8,20}$/;
    if (!regularExpression.test(password)) {
      setPasswordError('Password should contain at least one number and one special character')
      return
    }

    const token = searchParams.get('invite_token')
    if (!token) return

    try {
      setSubmitting(true)
      await apiService.post(`/signup/invite?invite_token=${token}`, {
        password,
      })

      message.info('Sign up successful')
      navigate(`/login${emailParam ? `?email=${emailParam}` : ''}`)
    } catch (err: any) {
      setSubmitting(false)
      message.error(err.response.data.message)
    }
  }

  return (
    <div className="w-full min-h-screen bg-white md:bg-silver-grey pb-[100px]">
      <Header type="signup" />
      <div className="mt-[30px] w-full md:w-[560px] mx-auto bg-white md:rounded-[20px] p-[50px] md:shadow-light-shadow">
        <h1 className="font-semibold text-[24px] leading-[34px] color-oxford-blue mb-[30px]">
          Create your Cloudthread account
        </h1>
        <InputField label="Invited email" value={emailParam ? emailParam : 'Unknown'} disabled={true} onChange={() => {}} />
        <InputField type="password" placeholder="More than 7 characters" label="Password" value={password} error={passwordError} onChange={handleChangePassword} />
        <div className="flex items-center mt-[36px]">
          <button
            type="button"
            className={`flex justify-center items-center w-[18px] h-[18px] p-[4.5px] ml-[6px] border-[1.2px] border-silver-grey-500 rounded-[5px] ${isAgreedTerms && 'bg-cyanine-blue border-none'}`}
            onClick={toggleAgreeTerms}
          >
            {isAgreedTerms && <Checkmark className="w-full" />}
          </button>
          <span className="text-[12px] leading-[16px] text-oxford-blue ml-[8px]">
            I agree to Cloudthread&apos;s <a onClick={handlePrivacy}>Privacy Policy</a> and <a onClick={handleTerms}>Terms of Use</a>
          </span>
        </div>
        <button
          type="button"
          disabled={isDisabledSubmit}
          className="w-full h-[46px] mt-[30px] bg-caladon-blue rounded-[10px] font-medium text-[15px] leading-[22px] tracking-[-0.24px] text-white disabled:opacity-25"
          onClick={handleCreateAccount}
        >
          Create an Account
        </button>
      </div>
    </div>
  )
}

export default SignUpByInvite
