import { Link } from 'react-router-dom';
import { Table } from 'antd';

import { Page } from 'shared/Page';
import { Button2 as Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { DeleteDialog } from '../Dashboard/dialogs';

import { useDashboardsStore, useDashboardDeleteDialogStore } from 'store/dashboards';

const sortStrings = (a: string, b: string) => a > b ? 1 : a < b ? -1 : 0;

export const DashboardLibraryPage = () => {
  const dashboardsLibrary = useDashboardsStore((state) => state.getLibrary());
  const openDeleteDialog = useDashboardDeleteDialogStore((store) => store.open);

  return (
    <Page>
      <Page.Head title='Dashboards Library'>
        <Page.Right>
          <Button
            size='xs'
            theme='black'
            layout='inline'
            iconLeft='circle-plus'
            to='/dashboards'
          >
            Create New Dashboard
          </Button>
        </Page.Right>
      </Page.Head>

      <Section className='relative p-5'>
        <Table
          dataSource={dashboardsLibrary.status === 'success' ? dashboardsLibrary.data : []}
          columns={[
            {
              key: 'name',
              title: 'Dashboard',
              dataIndex: 'name',
              sorter: (a, b) => sortStrings(a.name, b.name),
              render: (name, dashboard) => (
                <Link
                  to={`/dashboards/${dashboard.id}/`}
                >
                  {name}
                </Link>
              )
            },
            {
              key: 'delete',
              width: 44,
              align: 'center',
              dataIndex: 'id',
              render: (id) => (
                <Button
                  size='s'
                  theme='gray'
                  layout='inline'
                  icon='trash'
                  className='hover:!text-red'
                  onClick={() => {
                    openDeleteDialog(id);
                  }}
                />
              )
            }
          ]}
        />

        <StatusOverlay status={dashboardsLibrary.status} />
      </Section>

      <DeleteDialog />
    </Page>
  );
};
