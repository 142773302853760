import type { Moment } from 'moment';
import type { DataEntry } from 'types/dataEntries';
import type { Granularity } from 'types/common';
import type { CostFilterFetchParams, CostViewChart } from 'types/costViews';

import { SERVER_DATE_FORMAT } from 'helpers/constants';
import { useCostViewChartStore, useCostViewsStore } from 'store/costLab';

export const useCostViewCharts = (ids: number[], params: {
  startDate: Moment,
  endDate: Moment,
  granularity: Granularity,
} & CostFilterFetchParams) => {
  const {
    startDate,
    endDate,
    ...rest
  } = params;

  const views = useCostViewsStore((store) => ids.map(store.getEntry));
  const charts: DataEntry<CostViewChart>[] = useCostViewChartStore((store) => views.map((view) => {
    if (view.status !== 'success') {
      return view;
    }

    return store.getEntry({
      ...rest,
      startDate: startDate.format(SERVER_DATE_FORMAT),
      endDate: endDate.format(SERVER_DATE_FORMAT),
      filter: view.data.filter
    });
  }));

  return views.map((view, index) => {
    const chart = charts[index];

    return {
      label: view.status === 'success' ? view.data.name : 'Loading...',
      color: `hsl(${100 * index % 360}, 50%, 50%)`,
      data: chart.status === 'success' ?
        chart.data.map(({ current_timeframe_cost, date }) => ({ y: current_timeframe_cost || 0, x: date })) :
        []
    };
  });
}
