import type { Moment } from 'moment';
import { DateText } from 'shared/DateText';
import { PeriodChart } from 'shared/PeriodChart';
import { StatusOverlay } from 'shared/Overlay';
import { Section, SectionTitle } from 'shared/Section';
import { ArrowButton } from './ArrowButton';
import { useCostViewsStore, useCostViewChart } from 'store/costLab';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { identity } from 'helpers/utils';

export interface CostViewSectionProps {
  viewId: number;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
}

const commonParams = {
  granularity: 'day' as const,
  costType: 'unblended_cost' as const,
  costDimensions: [],
  costAmortization: false,
};

export const CostViewSection = (props: CostViewSectionProps) => {
  const {
    viewId,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
  } = props;

  const costView = useCostViewsStore((store) => store.getEntry(viewId));

  const chartParams = useDerivedDataEntry({
    ...commonParams,
    startDate: startMoment.format('YYYY-MM-DD'),
    endDate: endMoment.format('YYYY-MM-DD'),
    costView
  }, ({
    costView: { filter },
    ...rest
  }) => ({
    ...rest,
    filter
  }));

  const prevChartParams = useDerivedDataEntry({
    ...commonParams,
    startDate: prevStartMoment.format('YYYY-MM-DD'),
    endDate: prevEndMoment.format('YYYY-MM-DD'),
    costView
  }, ({
    costView: { filter },
    ...rest
  }) => ({
    ...rest,
    filter
  }));

  const chartData = useCostViewChart(chartParams);
  const prevChartData = useCostViewChart(prevChartParams);

  const data = useDerivedDataEntry({
    costView,
    chartData,
    prevChartData
  }, identity);

  console.log(viewId, costView, chartData, prevChartData);

  return (
    <Section className='relative h-[290px]'>
      <SectionTitle className='flex px-5 pt-4'>
        {costView.status === 'success' && <>
          {costView.data.name}
          <ArrowButton to={`/costs-overview/${viewId}`} />
        </>}
      </SectionTitle>

      <PeriodChart
        height={220}
        data={data.status === 'success' ? data.data.chartData : []}
        prevData={data.status === 'success' ? data.data.prevChartData : []}
        granularity='day'
        currentKey='current_timeframe_cost'
        previousKey='previous_timeframe_cost'
      />

      <div className='flex mb-5 ml-5 gap-5'>
        <DateText
          description={'Current Period'}
          startMoment={startMoment}
          endMoment={endMoment}
        />

        <DateText
          description={'Previous Period'}
          startMoment={prevStartMoment}
          endMoment={prevEndMoment}
        />
      </div>

      <StatusOverlay status={data.status} />
    </Section>
  );
}
