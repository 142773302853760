import type { DataEntry } from 'types/dataEntries';
import type { CostViewFilter, CostViewFilterWithTeam } from 'types/costViews';
import type { TagViewChart } from 'types/tagViews'

import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { DateFilter } from 'shared/DateFilter';
import { Page } from 'shared/Page';
import { EditableTitle } from 'shared/EditableTitle';
import { Breakdown } from './Breakdown/Breakdown';
import { Drilldown } from './Drilldown/Drilldown';
import { Total } from './Total/Total';
import { TagList } from './TagList';
import { FilterButton } from 'shared/FilterButton';
import { FiltersBlock } from './FiltersBlock';

import { parseCostViewFilter, validateCostViewFilter } from 'helpers/costViews';

import { useTagOverviewStore } from './useTagOverviewStore'
import { useDatesQuery } from 'hooks/useDatesQuery';
import { useDrillDownQuery } from 'hooks/useDrillDownQuery';
import { useGlobalState } from 'state/globalState';
import { useTagViewChartStore } from 'store/tagViewChart';
import { useTagViewDrillDownStore } from 'store/tagViewDrillDown';
import { useEventStore } from 'store/events';
import { useCostViewConstructor } from 'store/costLab';
import { useTagListFilterValue } from 'hooks/tagList';

const TagsOverview = () => {
  const {
    startDate,
    endDate,
    granularity,
    setDatesQuery
  } = useDatesQuery();

  const tagFilterValue = useTagListFilterValue();

  const {
    breakdown,
    drillDownSteps,
    availableSteps,
    availableBreakdowns,
    setCurrentStep,
    setBreakdown,
    swapStepField,
    swapStepValue,
    removeDrillDown,
    rollbackDrillDown
  } = useDrillDownQuery();

  const {
    filterParam: filter,
    filterChanged,
    setFilter,
    updateFilter,
    updateView,
    setPeriod: setDatesToStore,
    isFiltersApplied,
    drillDownOpened,
    deleteView,
    view,
    viewStatus,
    fetchView,
    newViewName,
    setNewViewName,
    setDrillDown,
  } = useTagOverviewStore();

  const {
    events,
    eventTypes,
    selectedEventTypes,
    fetchEvents,
    fetchEventTypes,
    setEventTypes,
  } = useEventStore();

  const costViewConstructor = useCostViewConstructor();

  useEffect(() => {
    setDrillDown(drillDownSteps);
  }, [drillDownSteps, breakdown]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user, collapseSideMenu } = useGlobalState()
  //const logEvent = useGlobalState((state) => state.logEvent);
  const [filtersOpened, setFiltersOpened] = useState<boolean>(() => !!searchParams.get('filtersOpened'))
  const [deleteViewDialogOpen, setDeleteViewDialogOpen] = useState(false)

  const params = useParams<{id?: string}>()
  const id = params.id ? +params.id : null;

  useEffect(() => {
    fetchView(id);
    setNewViewName('')
  }, [id]);

  useEffect(() => {
    if (costViewConstructor.status === 'success' && viewStatus === 'success') {
      if (view) {
        setFilter(
          parseCostViewFilter(view.filter, costViewConstructor.data)
        );
      } else {
        setFilter({
          src: 'cur',
          filter: { operator: 'or', condition: [] }
        });
      }
    }
  }, [costViewConstructor.status, viewStatus]);

  const handleFiltersClick = () => {
    setFiltersOpened(!filtersOpened)
    if (!filtersOpened) {
      collapseSideMenu()
    }
  }

  const handleFiltersBlockChange = (value: any) => {
    updateFilter(value);
  }

  const filtersApplied = isFiltersApplied();

  const viewDataEntry = { status: viewStatus };

  const today = useMemo(() => moment(), []);

  const endDateAdjusted = endDate.isAfter(today) ? today : endDate;
  const prevStartDateAdjusted = startDate.clone().subtract(endDateAdjusted.diff(startDate, 'days') + 1, 'days')
  const prevEndDateAdjusted = endDateAdjusted.clone().subtract(endDateAdjusted.diff(startDate, 'days') + 1, 'days')

  const startDateStr = startDate.format('YYYY-MM-DD');
  const endDateStr = endDateAdjusted.format('YYYY-MM-DD');

  useEffect(() => {
    setDatesToStore(
      startDate.format('YYYY-MM-DD'),
      endDateAdjusted.format('YYYY-MM-DD')
    );
    fetchEvents({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDateAdjusted.format('YYYY-MM-DD')
    });
    fetchEventTypes({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDateAdjusted.format('YYYY-MM-DD')
    });
  }, [startDate, endDate]);

  const chartDataEntry = useTagViewChartStore(({ getEntry }) => {
    if (viewDataEntry.status !== 'success') {
      return viewDataEntry as DataEntry<TagViewChart>;
    }

    if (costViewConstructor.status !== 'success') {
      return costViewConstructor as DataEntry<TagViewChart>;
    }
    /*
    if (teamView && teamView.status !== 'success') {
      return teamView as DataEntry<TagViewChart>;
    }
    */
    if (!validateCostViewFilter(filter, costViewConstructor.data)) {
      return { status: 'idle' } as DataEntry<TagViewChart>;
    }

    let requestFilter: CostViewFilter | CostViewFilterWithTeam = filter;

    /*
    if (teamFilterApplied && teamView?.costView) {
      requestFilter = {
        src: filter.src,
        filter: {
          operator: 'and',
          condition: [
            teamView.costView.filter.filter,
            filter.filter 
          ]
        }
      };
    }
    */

    return getEntry({
      ...tagFilterValue,
      startDate: startDateStr,
      endDate: endDateStr,
      granularity,
      filter: requestFilter
    });
  });

  const drillDownEntry = useTagViewDrillDownStore((store) => {
    if (!filter || filter.src !== 'cur') {
      return { status: 'idle' } as DataEntry;
    }

    if (viewDataEntry.status !== 'success') {
      return viewDataEntry as DataEntry;
    }
    /*
    if (teamView && teamView.status !== 'success') {
      return teamView as DataEntry;
    }
    */
    let requestFilter: CostViewFilter | CostViewFilterWithTeam = filter;
    /*
    if (teamFilterApplied && teamView?.costView) {
      requestFilter = {
        src: filter.src,
        filter: {
          operator: 'and',
          condition: [
            teamView.costView.filter.filter,
            filter.filter 
          ]
        }
      };
    }
    */
    return store.getEntry({
      ...tagFilterValue,
      startDate: startDateStr,
      endDate: endDateStr,
      granularity,
      filter: requestFilter,
      breakdown,
      drillDownSteps
    });
  });

  let requestFilter: CostViewFilter | CostViewFilterWithTeam = filter;
  /*
  if (teamFilterApplied && teamView?.costView) {
    requestFilter = {
      src: filter.src,
      filter: {
        operator: 'and',
        condition: [
          teamView.costView.filter.filter,
          filter.filter 
        ]
      }
    };
  }
  */

  const total = <>
    <TagList />
    <Total
      viewId={id}
      className='grow mb-[15px]'
      chartDataEntry={chartDataEntry}
      startMoment={startDate}
      endMoment={endDateAdjusted}
      prevStartMoment={prevStartDateAdjusted}
      prevEndMoment={prevEndDateAdjusted}
      events={events}
      eventTypes={eventTypes}
      selectedEventTypes={selectedEventTypes}
      setEventTypes={setEventTypes}
      granularity={granularity}
      requestFilter={requestFilter}
    />
  </>;

  const pageTitle = useMemo(() => {
    if (viewStatus == 'loading') {
      return 'Loading';
    }

    if (view?.name !== null || newViewName !== '') {
      return <div className='flex flex-col items-start'>
        <EditableTitle
          value={newViewName || view?.name || ''}
          placeholder='Enter view name'
          onChange={setNewViewName}
        />
      </div>;
    }

    return '';
  }, [viewStatus, view, newViewName]);

  if (viewStatus === 'error') {
    return <div className='h-[100vh] flex items-center justify-center font-bold text-[128px] text-silver-grey-500'>
      Error
    </div>;
  }

  return (
    <Page>
      <Page.Head title='Tags Overview'>
        <div className='flex items-center justify-end ml-[15px] shrink min-w-0'>
          <DateFilter
            withCumulative
            startDate={startDate}
            endDate={endDateAdjusted}
            granularity={granularity}
            onChange={(params) => {
              setDatesQuery(params);

              if (params.granularity !== granularity) {
                //logEvent('set_granularity', { 'view': 'cost_view', 'granularity': params.granularity });
              }

              if (params.startDate !== startDate || params.endDate !== endDateAdjusted) {
                //logEvent('set_period', { 'view': 'cost_view' });
              }
            }}
          />
          <FilterButton checked={filtersOpened} onClick={handleFiltersClick} />
        </div>
      </Page.Head>

      <div>
        {!filtersOpened && total} 

        {filtersOpened && 
          <div
            className={
              `ml-[15px] w-[370px] float-right min-h-[0px] flex flex-col  mb-[15px] ${drillDownOpened ? 'relative ' : 'sticky top-[65px]'}`
            }
            style={{ height: drillDownOpened ? 440 : 'calc(100vh - 80px)' }}
          > 
          {/*costViewConstructor.status === 'success' ?
            //{(costViewConstructor.status === 'success' && (!teamView || teamView.status === 'success')) ?
              
              <FiltersBlock
                costViewConstructor={costViewConstructor.data}
                value={filter}
                onChange={handleFiltersBlockChange}
              /> :
              <Loading />
            */}
          </div>
        }

        {(<>
          {filter && filter.src === 'cur' && (filtersOpened || drillDownOpened) && (
            <div className="ml-[15px] mb-[15px] w-[370px] sticky top-[65px] min-h-[1px] float-right clear-right flex flex-col" style={{ maxHeight: 'calc(100vh - 80px)' }}>
              {drillDownOpened && <Drilldown
                className="min-h-0 shrink"
                startDate={startDateStr}
                endDate={endDateStr}
                granularity={granularity}
                filter={filter}
                drillDown={drillDownEntry}
                breakdown={breakdown}
                drillDownSteps={drillDownSteps}
                availableSteps={availableSteps}
                availableBreakdowns={availableBreakdowns}
                setCurrentStep={setCurrentStep}
                setBreakdown={setBreakdown}
                swapStepField={swapStepField}
                swapStepValue={swapStepValue}
                removeDrillDown={removeDrillDown}
                rollbackDrillDown={rollbackDrillDown}
              />}
            </div>
          )}

          <div className={filtersOpened || drillDownOpened ? 'mr-[385px] pb-[15px]' : 'pb-[15px]'}>
            {filtersOpened && total}

          {filter && filter.src === 'cur' && <Breakdown
              startDate={startDateStr}
              endDate={endDateStr}
              startMoment={startDate}
              endMoment={endDateAdjusted}
              prevStartMoment={prevStartDateAdjusted}
              prevEndMoment={prevEndDateAdjusted}
              granularity={granularity}
              breakdown={breakdown}
              drillDown={drillDownEntry}
              drillDownEnabled={drillDownSteps.length > 0}
              availableBreakdowns={availableBreakdowns}
              setBreakdown={setBreakdown}
              setCurrentStep={setCurrentStep}
            />}
          </div>
        </>)}
      </div>
   </Page>
  )
}

export default TagsOverview
