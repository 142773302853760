import type { Moment } from 'moment';
import type { Granularity } from 'types/common';
import type { DataEntry } from 'types/dataEntries';
import type { CostViewChart } from 'types/costViews/charts';
import type { EventsData } from 'types/costViews/events';
import type { CostViewFilter, CostViewFilterWithTeam } from 'types/costViews';
import type { ChartType } from 'types/chart'

import moment from 'moment';
import { useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Select, Checkbox } from 'antd';

import { Section, SectionTitle } from 'shared/Section';
import { Button2 as Button } from 'shared/Button';
import { Legend } from 'shared/Legend';
import { EventsTable } from 'shared/EventsTable';
import { ChartTypeControl } from 'shared/ChartTypeControl';
import { ExportToCsvButton } from 'shared/ExportToCsvButton';

import { TotalChart } from './TotalChart';
import { EstTotalValue } from './EstTotalValue';
import { GrowthRateValue } from './GrowthRateValue';
import { CurrentTotalValue } from './CurrentTotalValue';
import { PreviousTotalValue } from './PreviousTotalValue';

import { CumulativeToggle, BudgetEditor } from '../controls';
import { BudgetDialog, ForecastDialog } from '../dialogs'

import { identity } from 'helpers/utils';
import { formatCurrency, formatCompact, formatRange } from 'helpers/formatter';

import { useCumulative, useForecast } from '../hooks';
import { useCostFilterValue } from 'hooks/costFilter';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useComparisonDialog, useCostViewsStore } from 'store/costLab';
import { useComparison, useComparisonIds } from '../hooks/comparison';

export interface TotalSectionProps {
  viewId: number | string;
  className?: string;
  chartDataEntry: DataEntry<CostViewChart>;
  prevChartDataEntry: DataEntry<CostViewChart>;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  events: EventsData[];
  eventTypes: string[];
  selectedEventTypes: string[];
  setEventTypes: (types: string[]) => void;
  granularity: Granularity;
  requestFilter: DataEntry<CostViewFilter | CostViewFilterWithTeam>;
}

export const TotalSection = (props: TotalSectionProps) => {
  const {
    viewId,
    className,
    chartDataEntry,
    prevChartDataEntry,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    events,
    eventTypes,
    selectedEventTypes,
    setEventTypes,
    granularity,
    requestFilter
  } = props;

  const forecastStartDate = moment().startOf('day');
  const forecastEndDate = endMoment;

  const [searchParams] = useSearchParams();

  const [cumulative, setCumulative] = useCumulative();
  const [comparisonIds, setComparisonIds] = useComparisonIds();

  const comparisonData = useComparison(comparisonIds, {
    startDate: startMoment,
    endDate: endMoment,
    granularity: cumulative ? 'day' : granularity,
    cumulative,
    forecastStartDate,
    forecastEndDate
  });

  const openComparisonDialog = useComparisonDialog((store) => store.open);

  const unit = requestFilter.status === 'success' && ['cur', 'gcp'].includes(requestFilter.data.src) ? 'cost' : 'unit';

  const [chartType, setChartType] = useState<ChartType>('line')
  const [eventsOverlay, setEventsOverlay] = useState<boolean>(false)
  const [eventsToDisplay, setEventsToDisplay] = useState<EventsData[]>([])

  const handleSetEventsToDisplay = (targetEvents: EventsData[]) => {
    if (targetEvents.length && eventsToDisplay.length) {
      setEventsToDisplay(targetEvents)
    }
  }

  const filteredEvents = useMemo(() => {
    return events.filter((event) => selectedEventTypes.includes(event.payload.type))
  }, [selectedEventTypes])

  const filteredEventsToDisplay = useMemo(() => {
    return eventsToDisplay.filter((event) => selectedEventTypes.includes(event.payload.type))
  }, [selectedEventTypes, eventsToDisplay])

  const format = unit === 'cost' ? formatCurrency : formatCompact;
  const data = chartDataEntry.status === 'success' ? chartDataEntry.data : [];

  const showBudget = useCostViewsStore((store) => {
    if (typeof viewId === 'string') {
      return true;
    }

    const view = store.getEntry(viewId);

    return view.status === 'success' && view.data.is_custom;
  });

  const costFilterValue = useCostFilterValue();

  const forecastParams = useDerivedDataEntry({
    ...costFilterValue,
    startDate: startMoment.format('YYYY-MM-DD'),
    endDate: moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
    forecastStartDate: forecastStartDate.format('YYYY-MM-DD'),
    forecastEndDate: forecastEndDate.format('YYYY-MM-DD'),
    granularity: (cumulative ? {
      status: 'success',
      data: 'day'
    } : {
      status: 'idle'
    }) as DataEntry<Granularity>,
    filter: requestFilter
  }, identity);

  const forecast = useForecast(forecastParams);

  const [budgetEnabled, setBudgetEnabled] = useState(true);

  const period = granularity === 'quarter' ? 'quarterly' : 'monthly';
  const budget = useCostViewsStore((store) => store.getBudget(viewId, period));

  return (
    <Section className={className}>
      <div className='flex'>
        <div className='min-w-0 basis-[100%] shrink'>
          <div className='relative flex items-center px-5 -mb-5 gap-5 h-[60px] z-1'>
            <SectionTitle className='overflow-hidden whitespace-nowrap text-ellipsis'>
              Absolute {unit === 'cost' ? 'Costs' : 'Values'} - Overview
            </SectionTitle>

            <ChartTypeControl
              value={chartType}
              onChange={setChartType}
            />

            <CumulativeToggle />

            {!cumulative && (
              <div className='flex ml-auto gap-5'>
                <PreviousTotalValue
                  chartData={chartDataEntry}
                  format={format}
                />

                <CurrentTotalValue
                  chartData={chartDataEntry}
                  format={format}
                />
              </div>
            )}
          </div>

          <TotalChart 
            chartDataEntry={chartDataEntry}
            prevChartDataEntry={prevChartDataEntry}
            forecast={forecast}
            comparisonData={comparisonData}
            chartType={chartType}
            events={eventsOverlay ? filteredEvents : []}
            setEventsToDisplay={handleSetEventsToDisplay}
            format={format}
            granularity={granularity}
            budget={cumulative && budgetEnabled && budget ? budget.value : undefined}
          />
        </div>

        {cumulative &&
          <div className='flex flex-col py-2 mx-5 gap-4 shrink-0' >
            <EstTotalValue
              granularity={granularity}
              chartData={chartDataEntry}
              forecast={forecast}
              budget={budget}
              format={format}
            />

            <PreviousTotalValue
              chartData={chartDataEntry}
              format={format}
            />

            {showBudget && 
              <BudgetEditor
                viewId={viewId as number}
                granularity={granularity}
              />
            }

            <GrowthRateValue
              requestFilter={requestFilter}
            />
          </div>
        }
      </div>

      <div className='flex items-center pr-5 mt-2 border-t gap-5'>
        <div className='flex items-center px-5 py-2 overflow-x-auto gap-5 no-scrollbar'>
          <Legend series={[
            {
              name: 'Current period',
              color: '#00b4d8',
              subtitle: formatRange(startMoment, endMoment, granularity)
            },
            {
              name: 'Previous period',
              color: '#DA5665',
              subtitle: formatRange(prevStartMoment, prevEndMoment, granularity)
            },
          ]} />

          {showBudget &&
            <div className='flex items-center -mr-2 gap-1'>
              <Checkbox
                checked={cumulative && budgetEnabled}
                onChange={() => {
                  if (!cumulative) {
                    setCumulative(true);
                    setBudgetEnabled(true);
                  } else {
                    setBudgetEnabled(!budgetEnabled);
                  }
                }}
              >
                <Legend series={[
                  {
                    name: 'Budget',
                    color: 'gray',
                    subtitle: 'Cumulative only'
                  }
                ]} />
              </Checkbox>
            </div>
          }

          <div className='flex items-center gap-1'>
            <Checkbox
              className='whitespace-nowrap'
              checked={eventsOverlay}
              onChange={() => {
                setEventsOverlay(!eventsOverlay);
              }}
            >
              <span
                className='inline-block w-[10px] h-[10px] rounded-[50%] mr-2'
                style={({ backgroundColor: 'rgba(5, 223, 247, 0.8)' })}
              />

              Events
            </Checkbox>

            {eventsOverlay && 
              <Select 
                size='small'
                mode='multiple'
                className='min-w-[300px]'
                value={selectedEventTypes}
                onChange={setEventTypes}
                options={eventTypes.map((type) => ({
                  label: type,
                  value: type
                }))}
                filterOption
              />
            }
          </div>

          {comparisonData.map(({ label, color }, index) => {
            const compIds = [...comparisonIds];
            const newSearchParams = new URLSearchParams(searchParams);

            compIds.splice(index, 1);
            newSearchParams.set('comparisonIds', (typeof viewId === 'number' ? [viewId, ...compIds] : compIds).join(','));

            const url = `/costs-overview/${comparisonIds[index]}?${newSearchParams.toString()}`;

            return <div className='flex items-center'>
              <Legend
                series={[{
                  name: label,
                  subtitle: (
                    <Link to={url} className='font-medium text-blue hover:text-blue-hover'>Set as Current</Link>
                  ),
                  color
                }]}
              />
              <Button
                size='xs'
                icon='close'
                theme='gray'
                onClick={() => {
                  setComparisonIds(compIds);
                }}
              />
            </div>
          })}

          <Button
            theme='black'
            layout='inline'
            iconLeft='plus'
            onClick={() => {
              openComparisonDialog(comparisonIds, setComparisonIds);
            }}
          >
            Compare
          </Button>
        </div>

        <div className='ml-auto shrink-0'>
          <ExportToCsvButton fileName='total_chart.csv' data={data} />
        </div>
      </div>

      {filteredEventsToDisplay.length > 0 && (
        <div className='mx-5 mt-4'>
          <EventsTable events={filteredEventsToDisplay} />
        </div>
      )}

      {forecast.status === 'success' && 
        <ForecastDialog
          value={forecast.data.settings}
          onChange={forecast.data.setSettings}
          lastCost={forecast.data.lastCost}
          avgCost={forecast.data.avgCost}
          format={format}
        />
      }

      {typeof viewId === 'number' && 
        <BudgetDialog
          viewId={viewId}
          granularity={granularity}
        />
      }
    </Section>
  );
};
