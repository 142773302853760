import { Button2 as Button } from 'shared/Button';

export interface ArrowButtonProps {
  to: string;
}

export const ArrowButton = (props: ArrowButtonProps) => {
  const { to } = props;

  return (
    <Button
      to={to}
      icon='arrow-right'
      size='s'
      theme='gray'
      layout='inline'
      className='ml-auto'
    />
  );
};
