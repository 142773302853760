import type { Setter } from 'types/common'

import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Checkbox, DatePicker, InputNumber, Radio } from 'antd';

import { Dialog, DialogBody } from 'shared/Dialog';
import { Button2 as Button } from 'shared/Button';
import { useForecastDialog } from 'store/forecastDialog';
import { ForecastSettings } from 'types/costViews';
import { FormRow, Label } from 'shared/Form';

export interface ForecastDialogProps {
  value: ForecastSettings;
  onChange: Setter<ForecastSettings>;
  lastCost: number;
  avgCost: number;
  format: (val: number) => string;
}

export const ForecastDialog = (props: ForecastDialogProps) => {
  const {
    value,
    onChange,
    avgCost,
    lastCost,
    format
  } = props;

  const [localValue, setLocalValue] = useState<ForecastSettings>(value);

  const { opened, close } = useForecastDialog();

  useEffect(() => {
    setLocalValue(value);
  }, [opened]);

  return (
    <Dialog
      className='w-[480px]'
      open={opened}
      onClose={close}
    >
      <Dialog.Title>
        Edit Forecast
      </Dialog.Title>

      <DialogBody>
        <FormRow size='m'>
          <Checkbox
            checked={localValue.fixed}
            onChange={() => {
              setLocalValue({
                ...localValue,
                fixed: !localValue.fixed
              });
            }}
          >
            Use fixed daily cost
          </Checkbox>
        </FormRow>

        <FormRow size='m'>
          <Label size='m'>
            Base daily cost
          </Label>

          <Radio
            disabled={!localValue.fixed}
            checked={localValue.fixedMode === 'last'}
            onChange={(e) => {
              if (e.target.checked) {
                setLocalValue({ ...localValue, fixedMode: 'last' });
              }
            }}
          >
            Last day ({format(lastCost)})
          </Radio>

          <Radio
            disabled={!localValue.fixed}
            checked={localValue.fixedMode === 'avg'}
            onChange={(e) => {
              if (e.target.checked) {
                setLocalValue({ ...localValue, fixedMode: 'avg' });
              }
            }}
          >
            Average ({format(avgCost)})
          </Radio>
        </FormRow>

        <FormRow size='m'>
          <Label size='m'>
            Expected one-time cost increase
          </Label>
        
          <div className='flex items-center gap-4'>
            <InputNumber
              disabled={!localValue.fixed}
              prefix='%'
              type='number'
              size='large'
              value={localValue.increasePercentage}
              onChange={(increasePercentage) => {
                setLocalValue({
                  ...localValue,
                  increasePercentage: increasePercentage || 0
                });
              }}
            />

            <span className='text-body'>
              The new daily cost will be <b>{format((localValue.fixedMode === 'last' ? lastCost : avgCost) * (1 + 0.01 * localValue.increasePercentage))}</b>
            </span>
          </div>
        </FormRow>

        <FormRow size='m'>
          <Label size='m'>
            Expected cost increase date
          </Label>
          
          <DatePicker
            disabled={!localValue.fixed}
            size='large'
            value={dayjs(localValue.increaseDate.toString())}
            disabledDate={(date) => date.isBefore(dayjs().startOf('day'))}
            onChange={(increaseDate) => {
              increaseDate && setLocalValue({
                ...localValue,
                increaseDate: moment(increaseDate.toString()).startOf('day')
              });
            }}
          />

          <div className='mt-2 text-gray-500 text-body'>
            The base daily cost will be used for the forecast until the above date
          </div>
        </FormRow>
      </DialogBody>

      <div className='flex justify-end p-8 pt-5'>
        <Button
          size='m'
          disabled={localValue === value}
          onClick={() => {
            onChange(localValue);
            close();
          }}
        >
          Update
        </Button>
      </div>
    </Dialog>
  );
}
