import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Radio, Select } from 'antd';
import { FormRow, Label } from 'shared/Form';
import { Button2 as Button } from 'shared/Button';
import { Dialog, DialogBody, DialogFooter} from 'shared/Dialog';
import { useDashboardsStore } from 'store/dashboards';
import { useAddToDashboardDialog } from 'store/costLab';

export const AddToDashboardDialog = () => {
  const { id: viewIds, close } = useAddToDashboardDialog();
  const navigate = useNavigate();
  const dashboards = useDashboardsStore((store) => store.getLibrary());
  const [widgetType, setWidgetType] = useState<'cost-view-chart' | 'multi-cost-view-chart'>('cost-view-chart');
  const [dashboardId, setDashboardId] = useState<number | 'new'>('new');

  const dashboardDraft = useDashboardsStore((store) => store.getDraft(dashboardId));
  const setDashboardDraft = useDashboardsStore((store) => store.setDraft);

  return (
    <Dialog
      className='w-[480px]'
      open={!!viewIds}
      onClose={close}
    >
      <Dialog.Title>
        Add to Dashboard
      </Dialog.Title>

      <DialogBody>
        <FormRow size='m'>
          <Label size='m'>
            Widget Type
          </Label>

          <Radio.Group
            value={widgetType}
            onChange={(event) => {
              setWidgetType(event.target.value);
            }}
          >
            <Radio name='widget-type' value='cost-view-chart'>
              Cost View
            </Radio>

            <Radio name='widget-type' value='multi-cost-view-chart'>
              Cost View Comparison
            </Radio>
          </Radio.Group>
        </FormRow>

        <FormRow size='m'>
          <Label size='m'>
            Dashboard
          </Label>

          <Select 
            className='w-full'
            value={dashboardId}
            loading={dashboards.status === 'loading'}
            onChange={setDashboardId}
            options={
              dashboards.status === 'success' ? [
                {
                  value: 'new',
                  label: 'New Dashboard'
                },
                ...dashboards.data.map((dashboard) => ({
                  value: dashboard.id,
                  label: dashboard.name
                })) 
                ] : []
            }
          />
        </FormRow>
      </DialogBody>

      <DialogFooter>
        <Button
          size='m'
          disabled={dashboardDraft.status !== 'success'}
          onClick={() => {
            if (dashboardDraft.status === 'success' && viewIds) {
              setDashboardDraft(dashboardId, {
                ...dashboardDraft.data,
                widgets: [
                  ...dashboardDraft.data.widgets,
                  widgetType === 'cost-view-chart' ? {
                    type: 'cost-view-chart',
                    settings: {
                      viewId: viewIds[0],
                      duration: 7,
                      granularity: 'day',
                      costType: 'unblended_cost' as const,
                      costDimensions: [],
                      costAmortization: false,
                    }
                  } : {
                    type: 'multi-cost-view-chart',
                    settings: {
                      viewIds,
                      duration: 7,
                      granularity: 'day',
                      costType: 'unblended_cost' as const,
                      costDimensions: [],
                      costAmortization: false,
                    }
                  }
                ]
              });

              close();
              navigate(`/dashboards/${dashboardId === 'new' ? '' : dashboardId}`);
            }
          }}
        >
          Add to Dashboard
        </Button>
      </DialogFooter>

    </Dialog>
  );
}
