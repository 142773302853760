import type { Setter } from 'types/common';
import type { ReactNode } from 'react';

import { Select } from 'antd';
import { Section } from 'shared/Section';
import { Button2 as Button } from 'shared/Button';
import { Widget } from 'types/dashboards';
import { useCopyWidgetDialog } from 'store/dashboards';

export interface WidgetWrapperProps<T extends Widget> {
  widget: T;
  onChange: Setter<T>;
  editing: boolean;
  onEditingChange: (editing: boolean) => void;
  onRemove: () => void;
  form: ReactNode;
  children: ReactNode
}

export const WidgetWrapper = <T extends Widget>(props: WidgetWrapperProps<T>) => {
  const {
    widget,
    onChange,
    editing,
    onEditingChange,
    onRemove,
    form,
    children
  } = props;

  const openCopyWidgetDialog = useCopyWidgetDialog((store) => store.open);
  const { width = 6 } = widget;

  return (
    <Section
      className={`relative min-h-[320px] ${{
        3: 'col-span-3',
        4: 'col-span-4',
        6: 'col-span-6',
        8: 'col-span-8',
        9: 'col-span-9',
        12: 'col-span-12'
      }[width]}`}
    >
      {children}

      {editing &&
        <div
          className='absolute top-0 bottom-0 left-0 right-0 z-10 px-4 py-12 bg-white/90'
        >
          <div className='absolute flex items-center font-medium top-4 left-4 gap-2'>
            Size:
            <Select
              size='small'
              value={width}
              onChange={(width) => {
                onChange({ ...widget, width });
              }}
              options={[
                { value: 3, label: '1/4' },
                { value: 4, label: '1/3' },
                { value: 6, label: '1/2' },
                { value: 8, label: '2/3' },
                { value: 9, label: '3/4' },
                { value: 12, label: 'Full' },
              ]}
            />
          </div> 

          {form}
          
          <Button
            className='absolute bottom-4 right-4'
            onClick={() => {
              onEditingChange(false);
            }}
          >
            Save Widget
          </Button>
        </div>
      }

      <div
        className={'absolute z-[30] right-0 top-0 m-5 flex gap-2'}
      >
        <Button
          theme='gray'
          size='s'
          layout='inline'
          onClick={() => {
            openCopyWidgetDialog(widget);
          }}
        >
          Copy
        </Button>

        <Button
          theme='gray'
          size='s'
          layout='inline'
          icon='edit'
          onClick={() => { onEditingChange(!editing); }}
        />

        <Button
          theme='gray'
          size='s'
          layout='inline'
          icon='trash'
          onClick={onRemove}
        />
      </div>
    </Section>
  );
}
