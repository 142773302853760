import { Page } from 'shared/Page'
import { Section } from 'shared/Section';
import { Button2 as Button } from 'shared/Button';
import { DateFilter } from 'shared/DateFilter';
import { LibraryBlock } from './content';
import { DeleteViewDialog } from './dialogs';

import { useDatesQuery } from 'hooks/useDatesQuery';
import { useSearchTerm } from 'hooks/useSearchTerm';
import { useQueryParam } from 'hooks/queryParams';

import { useDeleteViewDialog } from 'store/costLab';

type FilterType = 'all' | 'base' | 'custom'

export const Library = () => {
  const [
    searchTerm,
    setSearchTerm,
  ] = useSearchTerm();

  const {
    startDate,
    endDate,
    setDatesQuery
  } = useDatesQuery();

  const [viewType, setViewType] = useQueryParam<FilterType>({
    name: 'type',
    parse: (param: string | null) => ['base', 'custom'].includes(param as string) ? param as FilterType : 'all',
    serialize: (value) => value === 'all' ? null : value
  });

  const openDeleteDialog = useDeleteViewDialog((store) => store.open);

  return (
    <Page>
      <Page.Head title='Cost Library'>
        <div className='flex items-center ml-auto gap-5'>
          <DateFilter
            withCumulative
            startDate={startDate}
            endDate={endDate}
            granularity={'day'}
            onChange={(params) => {
              setDatesQuery(params);
            }}
          />

          <Button
            size='xs'
            theme='black'
            layout='inline'
            iconLeft='circle-plus'
            to='/costs-overview?filtersOpened=true'
          >
            Create New View
          </Button>
        </div>
      </Page.Head>

      <Section className='relative px-5 py-4'>
        <LibraryBlock
          searchTerm={searchTerm}
          onSearchTermChange={setSearchTerm}
          viewType={viewType}
          onViewTypeChange={setViewType}
          startDate={startDate}
          endDate={endDate}
          extraColumns={[
            {
              key: 'delete',
              width: 32,
              render: (_, view) => view.is_custom ? (
                <Button
                  size='s'
                  theme='gray'
                  layout='inline'
                  icon='trash'
                  className='hover:!text-red'
                  onClick={() => {
                    openDeleteDialog(view);
                  }}
                />
               ) : ''
            }
          ]}
        />

        <DeleteViewDialog />
      </Section>
    </Page>
  );
};
