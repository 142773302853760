import type { ReactNode } from 'react';

import { Link } from 'react-router-dom';
import { Icon } from 'shared/Icon';

export interface LinkSectionProps {
  to: string;
  children: ReactNode;
}

export const LinkSection = (props: LinkSectionProps) => {
  const {
    to,
    children
  } = props;

  return (
    <Link
      to={to}
      className='flex items-center justify-center font-medium rounded grow text-subtitle-1 text-blue hover:text-blue-hover hover:bg-white '
    >
      <Icon
        icon='circle-plus'
        className='w-6 h-6 mr-2'
      />

      {children}
    </Link>
  );
};
