import { fetchCostViewForecast } from 'services/costViews';
import { costViewForecastFetchParamsToKey } from 'helpers/storeKeys/costViewForecast';
import { createKeyedStore } from '../createKeyedStore';
import { createKeyedStoreSelector } from '../createKeyedStoreSelector';

export const useCostViewForecastStore = createKeyedStore({
  paramsToKey: costViewForecastFetchParamsToKey,
  fetchData: fetchCostViewForecast
});

export const useCostViewForecast = createKeyedStoreSelector(useCostViewForecastStore);
