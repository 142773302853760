import { message } from 'antd';

import { Dialog } from 'shared/Dialog';
import { Button2 as Button } from 'shared/Button';

import { useCostViewsStore, useDeleteViewDialog } from 'store/costLab';
import { useNavigate } from 'react-router-dom';

export const DeleteViewDialog = () => {
  const {
    id: view,
    close
  } = useDeleteViewDialog();

  const navigate = useNavigate();
  const deleteView = useCostViewsStore((store) => store.deleteEntry);

  return (
    <Dialog
      className='w-[420px] p-8'
      open={view !== null}
      onClose={close}
    >
      <div className='font-semibold text-center text-subtitle-1'>
        Are you sure you want to delete <b>{view?.name}</b> permanently?
      </div>

      <Button
        size='m'
        className='w-full my-4 !bg-red'
        onClick={() => {
          if (view !== null) {
            deleteView(view.id)
              .then(() => {
                message.success(<>
                  Cost view <b>{view.name}</b> deleted
                </>);

                close();
                navigate('/costs-overview/library');
              })
              .catch(() => {
                message.error(<>
                  Unable to delete <b>{view.name}</b>
                </>);
              });
          }
        }}
      >
        Yes, delete this view
      </Button>

      <Button
        size='m'
        className='w-full'
        theme='black'
        onClick={close}
      >
        Cancel
      </Button>
    </Dialog>
  );
};
