import { Toggle } from 'shared/Toggle';
import { useCumulative } from '../hooks';

export const CumulativeToggle = () => {
  const [cumulative, setCumulative] = useCumulative();

  return (
    <div className='flex items-center font-medium gap-2 text-caption-1'>
      <Toggle
        checked={cumulative}
        onChange={() => {
          setCumulative(!cumulative);
        }}
      />

      <div>
        Cumulative
        <div className='font-normal text-gray-500 text-caption-2'>
          + Forecast & Budget
        </div>
      </div>
    </div>
  );
}
