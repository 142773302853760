import { IconButton } from 'shared/Button';
import { useNavigate } from 'react-router-dom';

export interface AlertButtonProps {
  viewId: number;
}

export const AlertButton = (props: AlertButtonProps) => {
  const { viewId } = props;
  const navigate = useNavigate();

  return (
    <IconButton
      className='text-[10px] shrink-0 w-9'
      icon='alert'
      geometry='circle'
      hoverStyle='normal'
      type='tertiary'
      size='xs'
      direction='column'
      onClick={() => {
        navigate(`/notification-center?new_view_alert=${viewId}`);
      }}
    >
      Alert
    </IconButton>
  );
}
