import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import { Header, InputField } from 'components/Auth'
import { validateEmail } from 'helpers/validator'
import { useGlobalState } from 'state/globalState'

const LoginMarketplace = () => {
  const { loginMarketplace } = useGlobalState()
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [onceSubmitted, setOnceSubmitted] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [submitting, setSubmitting] = useState(false);

  const isDisabledSubmit = !email || !password || submitting;

  const token = searchParams.get('token')

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (e.target.value.length > 0 && onceSubmitted) {
      setEmailError('');
    }
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length > 0 && onceSubmitted) {
      setPasswordError('');
    }
  }

  const handleLogin =  async () => {
    if (isDisabledSubmit) return
    if (!onceSubmitted) {
      setOnceSubmitted(true)
    }

    if (!validateEmail(email)) {
      setEmailError('Invalid email format')
      return
    }

    if (password.length == 0) {
      setPasswordError('Password cannot be empty')
      return
    }

    if (!token) return

    setSubmitting(true);
    loginMarketplace(email, password, token)
      .catch((err: any) => {
        if (err.response.data.message === 'Domain exists as enabled SSO integration') {
          message.error('To active AWS Marketplace you need to log in via email and password, but this domain has SSO enabled')
        } else {
          message.error(err.response.data.message)
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <div className='w-full min-h-screen bg-white md:bg-silver-grey'>
      <Header type="login" tokenQueryParam={`token=${token}`} />
      <div className="mt-[30px] w-full md:w-[560px] mx-auto bg-white md:rounded-[20px] p-[50px] md:shadow-light-shadow">
        <h1 className="font-semibold text-[24px] leading-[34px] color-oxford-blue mb-[30px]">
          Sign In to activate AWS Marketplace
        </h1>
        <InputField label="Email" value={email} error={emailError} onChange={handleChangeEmail} />
        <InputField type="password" label="Password" value={password} error={passwordError} onChange={handleChangePassword} />
        <button
          type="button"
          disabled={isDisabledSubmit}
          className='w-full h-[46px] mt-[30px] bg-caladon-blue rounded-[10px] font-medium text-[15px] leading-[22px] tracking-[-0.24px] text-white disabled:opacity-25'
          onClick={handleLogin}
        >
          Sign In
        </button>
        <div className='flex justify-center font-medium text-[11px] leading-[13px] tracking-[0.07px] mt-[11.5px]'>
          <span className='text-oxford-blue'>
            Don&apos;t have an account?&nbsp;
          </span>
          <Link className='text-caladon-blue hover:text-caladon-blue' to={`/sign-up/marketplace?token=${token}`}>
            Create account now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginMarketplace;
