import type { CostViewFilter } from 'types/costViews';
import type { ConstructorRules, Filter, FilterGroupCondition, FilterValueCondition } from 'types/filters';

export interface Tag {
  key: string;
  value: string;
}

export interface CURTeamFilterFields {
  aws_account_ids?: string[];
  aws_bill_payer_account_ids?: string[];
  aws_account_org_ids?: string[];
  aws_region_names?: string[];
  aws_services?: string[];
  aws_tags?: Tag[];
  aws_account_tags?: Tag[];
}

export type CURTeamFilterFieldKeys = keyof CURTeamFilterFields;

export interface GCPTeamFilterFields {
  gcp_project_names?: string[]
  gcp_billing_account_ids?: string[]
  gcp_locations?: string[]
  gcp_services?: string[]
  gcp_labels?: Tag[];
}

export type GCPTeamFilterFieldKeys = keyof GCPTeamFilterFields;

export interface CustomTeamFilterFields {
  custom_stream_tokens?: string[];
  custom_metric_names?: string[];
  custom_dimensions?: Tag[];
}

export type CustomTeamFilterFieldKeys = keyof CustomTeamFilterFields;


export interface TeamFilterFields extends
  CURTeamFilterFields,
  GCPTeamFilterFields,
  CustomTeamFilterFields {}

export type TeamFilterFieldKey = keyof TeamFilterFields;

export interface TeamPayload extends TeamFilterFields {
  name: string;
  slack_channel: string | null;
  default_cost_column_id: number | null;
  opportunity_priorities?: number[];
}

export interface Team extends TeamPayload {
  id: number;
  new_id: string;
  created_by: number;
  default_cost_column_name: string | null;
}

export interface TeamFilterDescription {
  enabled: boolean;
  filter: CostViewFilter;
}

export type GeneralMember = {
  id: number
  name: string
}

export const TEAM_FILTER_SOURCES = ['cur', 'gcp', 'custom_data', 'snowflake'] as const;

export type TeamFilterSource = typeof TEAM_FILTER_SOURCES[number];

export interface TeamFilterCondition extends FilterGroupCondition {
  operator: 'and',
  condition: FilterValueCondition[];
}

export interface TeamFilter<TSrc extends TeamFilterSource> extends Filter {
  src: TSrc;
  filter: TeamFilterCondition;
}

export type TeamFilterMap = {
  [Src in TeamFilterSource]: TeamFilter<Src>;
}

export type TeamFilterConstructor = {
  [Src in TeamFilterSource]?: ConstructorRules;
}

export interface TeamDraft {
  name: string;
  slack_channel: string | null;
  default_cost_column_id: number | null;
  opportunity_priorities: number[];
  filters: TeamFilterMap;
}

export interface TeamDeps {
  teamFilterConstructor: TeamFilterConstructor;
}

export interface BackendErrorData {
  error: 'string';
  message: any;
}

export interface TeamConflictData {
  team_name: string;
  conflicts: {
    [Key in TeamFilterFieldKey]: TeamFilterFields[Key];
  }
}

export interface MutualExclusivityErrorData {
  error: 'team_mutual_exclusivity_error';
  message: TeamConflictData[];
}

export interface MutualExclusivityError {
  response: {
    data: MutualExclusivityErrorData
  }
}

export const isMutualExclusivityError = (error: any): error is MutualExclusivityError => error?.response?.data?.error === 'team_mutual_exclusivity_error';
