import type { Moment } from 'moment';
import type { Setter } from 'types/common';
import type { ColumnType } from 'antd/lib/table';
import type { CostViewLibraryEntry } from 'types/costViews';

import { Link } from 'react-router-dom'
import { Table, Tooltip } from 'antd'

import { DateText } from 'shared/DateText'
import { InputSearch } from 'shared/Input';
import { StatusOverlay } from 'shared/Overlay';
import { RadioButtonGroup, RadioButton } from 'shared/RadioButtonGroup';

import { SERVER_DATE_FORMAT } from 'helpers/constants'
import { formatPercent, formatCurrency } from 'helpers/formatter';
import { createNullableNumbersComparator, createStringsComparator } from 'helpers/sort';

import { useCostViewsStore } from 'store/costLab';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import {TableRowSelection} from 'antd/lib/table/interface';

export interface LibraryBlockProps {
  startDate: Moment;
  endDate: Moment;
  searchTerm: string;
  onSearchTermChange: Setter<string>;
  viewType: 'all' | 'custom' | 'base';
  onViewTypeChange: Setter<'all' | 'custom' | 'base'>;
  rowSelection?: TableRowSelection<CostViewLibraryEntry>;
  extraColumns?: ColumnType<CostViewLibraryEntry>[];
}

const Dot = ({ className = '', color }: { className?: string, color: string }) => <span className={`inline-block scale-[1.5] ${className}`} style={{ color }}>•</span>;
type Src = 'custom_data' | 'cur' | 'gcp' | 'cloudwatch'

const srcMap: Record<Src, string> = { custom_data: 'Custom', cur: 'AWS CUR', gcp: 'GCP CUR', cloudwatch: 'Cloudwatch' }

export const LibraryBlock = (props: LibraryBlockProps) => {
  const {
    startDate,
    endDate,
    searchTerm,
    onSearchTermChange,
    viewType,
    onViewTypeChange,
    rowSelection,
    extraColumns = []
  } = props;

  const library = useCostViewsStore((store) => store.getLibrary({
    startDate: startDate.format(SERVER_DATE_FORMAT),
    endDate: endDate.format(SERVER_DATE_FORMAT)
  }));

  const filteredLibrary = useDerivedDataEntry({
    library,
    searchTerm,
    viewType
  }, ({
    library,
    searchTerm,
    viewType
  }) => {
    let filteredByType;

    filteredByType =
      viewType === 'custom' ? library.filter((view) => view.is_custom) :
      viewType === 'base' ? library.filter((view) => !view.is_custom) :
      library;

    const searchTextNormalized = searchTerm.trim().toLowerCase();

    return searchTextNormalized ?
      filteredByType.filter(({ name, default_filters, src }) => [
        name,
        default_filters?.team_name,
        srcMap[src as Src]
      ].some((str) => str && str.toLowerCase().includes(searchTextNormalized))) :
      filteredByType; 
  });

  return (
    <div className='relative'>
      <div className='flex mb-5'>
        <InputSearch
          size='m'
          value={searchTerm}
          onChange={onSearchTermChange}
        />

        <div className='ml-[20px] grow-0'>
          <RadioButtonGroup
            name='metric-type'
            value={viewType}
            onChange={(_, params) => {
              onViewTypeChange(params.value);
            }}
          >
            <RadioButton value='all'>
              All Views
            </RadioButton>

            <RadioButton value='base'>
              <Tooltip title='Out of the box Views to analyze, report on, and use as starting point to customize'>
                <Dot color='#2A7BE4' className='mr-1.5' />
                Base Views
              </Tooltip>
            </RadioButton>

            <RadioButton value='custom'>
              <Tooltip title='Views filtered and saved by you and your teammates'>
                <Dot color='#EABB56' className='mr-1.5' />
                Custom Views
              </Tooltip>
            </RadioButton>
          </RadioButtonGroup>
        </div>
      </div>

      <div className='mb-5'>
        <div className='flex gap-5'>
          <DateText
            description={'Current Period'}
            startMoment={startDate}
            endMoment={endDate}
          />

          <DateText
            description={'Previous Period'}
            startMoment={startDate.clone().subtract(endDate.diff(startDate, 'days') + 1, 'days')}
            endMoment={endDate.clone().subtract(endDate.diff(startDate, 'days') + 1, 'days')}
          />
        </div>
      </div>

      <Table
        rowKey={({ id }) => id}
        size='small'
        rowClassName='text-caption-1'
        onHeaderRow={() => ({ className: 'text-caption-1' })}
        dataSource={filteredLibrary.status === 'success' ? filteredLibrary.data : []}
        rowSelection={rowSelection}
        columns={[
          {
            key: 'type',
            dataIndex: 'type',
            render: (_, metric) => (
              <Dot color={metric.is_custom && !metric.global_filter_team_id ? '#EABB56' : !metric.is_custom && !metric.global_filter_team_id ? '#2A7BE4' : '#0fab07'} />
            )
          },
          {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
            render: (name, {id}) => <Link className='w-auto' to={`/costs-overview/${id}`}>{name}</Link>
          },
          {
            key: 'team',
            title: 'Team',
            render: (_, metric) => <div className='w-auto'>{metric.default_filters?.team_name}</div>
          },
          {
            key: 'budget',
            title: 'Monthly Budget',
            render: (_, metric) => <div className='w-auto'>{
              metric.budgets && metric.budgets.filter((budget) => budget.period === 'monthly').length ? `$${metric.budgets.filter((budget) => budget.period === 'monthly')[0].value}` : ''
            }</div>
          },
          {
            key: 'src',
            title: 'Source',
            dataIndex: 'src',
            sorter: createStringsComparator((view) => view.src),
            render: (src: Src) => srcMap[src]
          },
          {
            key: 'total',
            title: 'Current Period',
            dataIndex: 'total',
            sorter: createNullableNumbersComparator('total'),
            render: (total: null | number, metric) => <div className='truncate'>{!metric.library_data ? 'New or edited library entries take up to 6 hours to update' : !total ? '': formatCurrency(total)}</div>
          },
          {
            key: 'prev_total',
            title: 'Previous Period',
            dataIndex: 'prev_total',
            sorter: createNullableNumbersComparator('prev_total'),
            render: (prevTotal: null | number, metric) => !metric.library_data || !prevTotal ? '' : formatCurrency(prevTotal)
          },
          {
            key: 'wow_change',
            title: 'Period-over-period Change %',
            dataIndex: 'wow_change',
            sorter: createNullableNumbersComparator('wow_change'),
            render: (wowChange, metric) =>  <div className='truncate'>{metric.prev_total === 0 ? 'Undefined (divide by 0)' : !metric.library_data || !wowChange ? '' : formatPercent(wowChange -1)}</div>
          },
          {
            key: 'wow_change',
            title: 'Period-over-period Change $',
            dataIndex: 'wow_diff',
            sorter: createNullableNumbersComparator('wow_diff'),
            render: (wowDiff, metric) => !metric.library_data || !wowDiff ? '' : formatCurrency(wowDiff -1)
          },
          ...extraColumns
        ]}
      />

      <StatusOverlay status={filteredLibrary.status} />
    </div>
  );
}
