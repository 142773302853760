import type { Moment } from 'moment';
import type { Granularity } from 'types/common';

import { Chart } from 'shared/Chart';
import { SERVER_DATE_FORMAT } from 'helpers/constants';
import { granularityTooltip } from 'helpers/formatter';

interface ChartSerie {
  label: string;
  color: string;
  previous?: boolean;
  data: {
    x: string,
    y: number,
    dashed?: boolean
  }[]
}

export interface TimelineChartProps {
  height?: number;
  type: 'line' | 'bar';
  startDate: Moment;
  endDate: Moment;
  prevStartDate?: Moment;
  prevEndDate?: Moment;
  granularity: Granularity;
  format: (value: number) => string;
  series: ChartSerie[];
}

export const TimelineChart = (props: TimelineChartProps) => {
  let {
    height = 250,
    type,
    startDate,
    endDate,
    prevStartDate,
    prevEndDate,
    granularity,
    format,
    series,
  } = props;

  if (type === 'bar' && prevStartDate) {
    series = series.reduce((acc, serie) => [
      ...acc,
      {
        label: '',
        color: '',
        data: [],
        previous: !serie.previous
      },
      serie
    ], [] as ChartSerie[]);
  }

  return (
    <div className='relative' style={{ height }}>
      <Chart
        type={type}
        options={{
          scales: {
            y: {
              ticks: {
                callback: format as (value: string | number) => string
              }
            },
            xCurrent: {
              position: 'bottom',
              type: 'timeseries',
              min: startDate.format(SERVER_DATE_FORMAT),
              max: endDate.format(SERVER_DATE_FORMAT),
              time: {
                unit: granularity,
                displayFormats: {
                  hour: 'hA',
                  day: 'MMM DD',
                  week: '[W]w YYYY',
                  month: 'MMM YYYY',
                  quarter: 'Qo YYYY',
                  year: 'YYYY'
                },
                tooltipFormat: granularityTooltip(granularity)
              },
              grid: {
                color: '#E0E4E9',
                borderDash: [4, 2]
              },
              ticks: {
                autoSkipPadding: 4,
                maxRotation: 0,
                font: {
                  size: 12,
                }
              }
            },
            xPrevious: {
              display: !!prevStartDate && !!prevEndDate,
              position: 'bottom',
              type: 'timeseries',
              min: prevStartDate?.format(SERVER_DATE_FORMAT),
              max: prevEndDate?.format(SERVER_DATE_FORMAT),
              time: {
                unit: granularity,
                displayFormats: {
                  hour: 'hA',
                  day: 'MMM DD',
                  week: '[W]w YYYY',
                  month: 'MMM YYYY',
                  quarter: 'Qo YYYY',
                  year: 'YYYY'
                },
                tooltipFormat: granularityTooltip(granularity)
              },
              grid: {
                display: false,
                drawBorder: false,
                tickLength: 0
              },
              ticks: {
                padding: 0,
                maxRotation: 0,
                includeBounds: true,
                autoSkipPadding: 10,
                color: 'rgba(0, 0, 0, .4)',
                font: {
                  size: 10,
                  lineHeight: 0.8
                }
              }
            }
          },
          datasets: {
            line: {
              clip: { left: 10, right: 10, top: 10, bottom: 10 }
            },
            bar: {
              barPercentage: .6,
              minBarLength: 2,
              clip: { left: 10, right: 10, top: 10, bottom: 10 }
            }
          }
        }}
        data={{
          datasets: series.map(({
            label,
            color,
            data,
            previous
          }) => ({
            xAxisId: previous ? 'xPrevious' : 'xCurrent',
            label,
            borderColor: color,
            backgroundColor: color,
            data
          }))
        }}
      />
    </div>
  );
}
