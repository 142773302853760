import { ReactNode } from 'react'

export interface TwoRowLayoutProps {
  topLeft?: ReactNode;
  topRight?: ReactNode;
  bottomLeft?: ReactNode;
  bottomRight?: ReactNode;
  topRightVisible?: boolean;
  bottomRightVisible?: boolean;
}

export const TwoRowLayout = (props: TwoRowLayoutProps) => {
  const {
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    topRightVisible,
    bottomRightVisible
  } = props;

  return (
    <div>
      {topRightVisible ? (
        <div
          className={
            `ml-4 w-[360px] float-right min-h-[0px] flex flex-col mb-4 ${bottomRightVisible ? 'relative ' : 'sticky top-[70px]'}`
          }
          style={{ height: bottomRightVisible ? 440 : 'calc(100vh - 80px)' }}
        >
          {topRight}
        </div> 
      ) : (
        topLeft
      )}

      {(topRightVisible || bottomRightVisible) && (
        <div
          className='ml-4 mb-4 w-[360px] sticky top-[70px] min-h-[1px] float-right clear-right flex flex-col'
          style={{ maxHeight: 'calc(100vh - 80px)' }}
        >
          {bottomRightVisible && bottomRight}
        </div>
      )}

      <div className={`${topRightVisible || bottomRightVisible ? 'mr-[376px]' : ''} pb-4`}>
        {topRightVisible && topLeft}
        {bottomLeft}
      </div>
    </div>
  );
};
