import { message, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Button2 as Button } from 'shared/Button';
import { Dialog, DialogBody } from 'shared/Dialog';
import { FormRow, Label } from 'shared/Form';

import { useCostViewsStore, useSaveViewDialog, useCostViewConstructor } from 'store/costLab';

export interface SaveViewDialogProps {
  viewId: number | string;
}

export const SaveViewDialog = (props: SaveViewDialogProps) => {
  const {
    viewId
  } = props;

  const navigate = useNavigate();
  const { opened, close } = useSaveViewDialog();
  const costViewConstructor = useCostViewConstructor();
  const setDraft = useCostViewsStore((store) => store.setDraft);
  const createView = useCostViewsStore((store) => store.createEntry);

  const draft = useCostViewsStore((store) => store.getDraft(viewId, costViewConstructor));

  if (draft.status !== 'success') {
    return null;
  }

  const handleSaveClick = () => {
    createView(viewId)
      .then((view) => {

        close();
        message.success(<>Cost view <b>{view.name}</b> created</>);
        navigate(`/costs-overview/${view.id}`);
      })
      .catch(() => {
        message.error(<>Unable to create <b>{draft.data.name}</b></>);
      });
  }

  return (
    <Dialog
      className='w-[420px]'
      open={opened}
      onClose={close}
    >
      <Dialog.Title>
        Save as New View
      </Dialog.Title>

      <DialogBody className='mb-8'>
        <FormRow size='m'>
          <Label size='m'>
            View Name
          </Label>

          <Input 
            size='large'
            value={draft.data.name}
            onChange={(event) => {
              setDraft(viewId, {
                ...draft.data,
                name: event.target.value
              });
            }}
          />
        </FormRow>

        <Button
          className='w-full'
          size='m'
          disabled={draft.data.name.trim() === ''}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </DialogBody>
    </Dialog>
  );
};
