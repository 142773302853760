import type { PartialState } from 'zustand';
import type { DataEntry } from 'types/dataEntries';

import { withKeyDep } from './decorators';

export const createSetEntry = <
  TEntry,
  TState extends {
    entries: { [key: number]: DataEntry<TEntry> }
  }
>({
  get,
  set
} : {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
}) => (
  id: number,
  entry: DataEntry<TEntry>
) => {
  const { entries } = get();

  set({
    entries: {
      ...entries,
      [id]: entry
    }
  } as PartialState<TState>);
};

export const createSetEntryWithLibraries = <
  TEntry,
  TLibraryEntry,
  TState extends {
    entries: { [key: number]: DataEntry<TEntry> }
    libraries: { [key: string]: DataEntry<TLibraryEntry[]> }
  }
>({
  get,
  set,
  toLibraryEntry,
  getLibraryEntryId
} : {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
  toLibraryEntry: (entry: TEntry) => TLibraryEntry;
  getLibraryEntryId: (libEntry: TLibraryEntry) => number;
}) => {
  const baseSetEntry = createSetEntry({ get, set });

  return (
    id: number,
    entry: DataEntry<TEntry>
  ) => {
    baseSetEntry(id, entry);

    if (entry.status === 'success') {
      let { libraries } = get();

      const newLibEntry = toLibraryEntry(entry.data);
      let changed = false;
        
      Object.entries(libraries).forEach(([key, library]) => {
        if (library.status === 'success') {
          const index = library.data.findIndex((libEntry) => getLibraryEntryId(libEntry) === id);

          if (index !== -1) {
            library = { ...library, data: [...library.data] };
            library.data[index] = newLibEntry;
            libraries = { ...libraries, [key]: library };
            changed = true;
          }
        }
      });

      if (changed) {
        set({ libraries } as PartialState<TState>);
      }
    }
  };
};

export const createDiscardEntry = <
  TEntry,
  TState extends {
    entries: { [key: number]: DataEntry<TEntry> }
  }
>({
  get,
  set
} : {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
}) => (
  id: number
) => {
  let { entries } = get();

  entries = { ...entries };

  delete entries[id];

  set({ entries } as PartialState<TState>);
};

export const createDiscardEntryWithLibraries = <
  TEntry,
  TLibraryEntry,
  TState extends {
    entries: { [key: number]: DataEntry<TEntry> }
    libraries: { [key: string]: DataEntry<TLibraryEntry[]> }
  }
>({
  get,
  set,
  getLibraryEntryId
} : {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
  getLibraryEntryId: (libEntry: TLibraryEntry) => number;
}) => {
  const baseDiscardEntry = createDiscardEntry({ get, set });

  return (
    id: number,
  ) => {
    baseDiscardEntry(id);

    let { libraries } = get();

    let changed = false;
        
    Object.entries(libraries).forEach(([key, library]) => {
      if (library.status === 'success') {
        const index = library.data.findIndex((libEntry) => getLibraryEntryId(libEntry) === id);

        if (index !== -1) {
          library = { ...library, data: [...library.data] };
          library.data.splice(index, 1);
          libraries = { ...libraries, [key]: library };
          changed = true;
        }
      }
    });

    if (changed) {
      set({ libraries } as PartialState<TState>);
    }
  };
};

export const createGetEntry = <TEntry, TState extends {
  entries: { [key: number]: DataEntry<TEntry> };
}>({
  get,
  set,
  fetchEntry
}: {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
  fetchEntry: (id: number) => Promise<{ data: TEntry }>;
}) => withKeyDep((id: number) => {
  const { entries } = get();

  let entry = entries[id];

  if (!entries[id]) {
    entry = { status: 'loading' };
    entries[id] = entry;

    fetchEntry(id)
      .then(({ data }) => ({
        status: 'success',
        data
      }))
      .catch((error) => ({
        error,
        status: 'error'
      }))
      .then((newEntry) => {
        const { entries } = get();

        const newState = {
          entries: {
            ...entries,
            [id]: newEntry
          }
        } as PartialState<TState>;

        set(newState);
      });
  }

  return entry;
});

export const createCreateEntry = <
  TEntry,
  TDraft,
  TPayload,
  TActionType,
  TState extends {
    setEntry: (id: number, entry: DataEntry<TEntry>) => void;
    getDraft: (key: number | string) => DataEntry<TDraft>;
    startAction: (id: number | string, type: TActionType) => void;
    finishAction: (id: number | string) => void;
  }
>({
  get,
  createActionType,
  createEntry,
  serializeDraft,
  getId
} : {
  get: () => TState;
  createActionType: TActionType,
  createEntry: (payload: TPayload) => Promise<{ data: TEntry }>
  serializeDraft: (draft: TDraft) => TPayload;
  getId: (entry: TEntry) => number;
}) => (id: number | string) => {
  const {
    getDraft,
    setEntry,
    startAction,
    finishAction
  } = get();

  const draft = getDraft(id);

  if (draft.status !== 'success') {
    throw new Error('Draft not loaded');
  }

  startAction(id, createActionType);

  const payload = serializeDraft(draft.data);

  return createEntry(payload)
    .then(({ data }) => {
      const entry: DataEntry<TEntry> = {
        status: 'success',
        data
      };

      const newId = getId(data);
      setEntry(newId, entry);

      return data;
    })
    .finally(() => {
      finishAction(id);
    });
};


export const createUpdateEntry = <
  TEntry,
  TDraft,
  TPayload,
  TActionType,
  TState extends {
    setEntry: (id: number, entry: DataEntry<TEntry>) => void;
    getDraft: (key: number | string) => DataEntry<TDraft>;
    startAction: (id: number | string, type: TActionType) => void;
    finishAction: (id: number | string) => void;
  }
>({
  get,
  updateActionType,
  updateEntry,
  serializeDraft
} : {
  get: () => TState;
  updateActionType: TActionType,
  updateEntry: (id: number, payload: TPayload) => Promise<{ data: TEntry }>
  serializeDraft: (draft: TDraft) => TPayload;
}) => (id: number) => {
  const {
    getDraft,
    setEntry,
    startAction,
    finishAction
  } = get();

  const draft = getDraft(id);

  if (draft.status !== 'success') {
    throw new Error('Draft not loaded');
  }

  startAction(id, updateActionType);

  const payload = serializeDraft(draft.data);

  return updateEntry(id, payload)
    .then(({ data }) => {
      const entry: DataEntry<TEntry> = {
        status: 'success',
        data
      };

      setEntry(id, entry);

      return data;
    })
    .catch((error) => {
      const entry: DataEntry<TEntry> = {
        status: 'error',
        error
      };

      setEntry(id, entry);

      return Promise.reject(error);
    })
    .finally(() => {
      finishAction(id);
    });
};

export const createDeleteEntry = <
  TActionType,
  TState extends {
    discardEntry: (id: number) => void;
    startAction: (id: number | string, type: TActionType) => void;
    finishAction: (id: number | string) => void;
  }
>({
  get,
  deleteActionType,
  deleteEntry
} : {
  get: () => TState;
  deleteActionType: TActionType,
  deleteEntry: (id: number) => Promise<any>
}) => (id: number) => {
  const {
    discardEntry,
    startAction,
    finishAction
  } = get();

  startAction(id, deleteActionType);

  return deleteEntry(id)
    .then(() => {
      discardEntry(id);
    })
    .finally(() => {
      finishAction(id);
    });
}
