import type { CostViewRequestFilter } from 'types/costViews';
import type { Granularity } from 'types/common';
import type { DataEntry } from 'types/dataEntries';
import type { DrillDownData } from 'types/costViews/drillDown';
import type { ChartType } from 'types/chart'
import type { Moment } from 'moment';

import { useState } from 'react';
import { Select } from 'antd';
import { Toggle } from 'shared/Toggle';
import { Button2 as Button } from 'shared/Button';
import { Section, SectionTitle } from 'shared/Section';

import { BreakdownChart } from './BreakdownChart';
import { BreakdownTable } from './BreakdownTable';
import { BreakdownHeaderStatus } from './BreakdownHeaderStatus';
import { FIELD_TITLES_PLURAL } from '../groupByOptions';
import { useGlobalState } from 'state/globalState';
import { ChartTypeControl } from 'shared/ChartTypeControl';
import { formatCurrency, formatNormal } from 'helpers/formatter';

import { useDrillDownQuery } from 'hooks/useDrillDownQuery';
import { useCostOverviewStore } from '../useCostOverviewStore';

export interface BreakdownSectionProps {
  className?: string;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  granularity: Granularity;
  drillDown: DataEntry<DrillDownData>;
  drillDownEnabled: boolean;
  requestFilter: DataEntry<CostViewRequestFilter>;
}

export const BreakdownSection = (props: BreakdownSectionProps) => {
  const {
    breakdown,
    availableBreakdowns,
    setCurrentStep,
    setBreakdown,
  } = useDrillDownQuery();

  const {
    className = '',
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    drillDown,
    drillDownEnabled,
    requestFilter
  } = props;

  const {
    drillDownOpened,
    chartCollapsed,
    setDrillDownOpened,
    setChartCollapsed,
  } = useCostOverviewStore()

  const logEvent = useGlobalState((state) => state.logEvent);

  const [chartType, setChartType] = useState<ChartType>('line')

  const options = availableBreakdowns.length ? 
    availableBreakdowns.map((value) => ({ value, label: FIELD_TITLES_PLURAL[value] })) :
    [{ value: breakdown, label: FIELD_TITLES_PLURAL[breakdown]}];

  return (
      <Section className={className}>
        <div className='flex items-center min-h-[60px] px-5 gap-5 -mb-4'>
          <SectionTitle className='overflow-hidden text-ellipsis'>Absolute Costs - Breakdown</SectionTitle>

          <Select
            className='min-w-[130px]'
            options={options}
            onChange={(key) => {
              setBreakdown(key);
              logEvent('set_breakdown', { 'breakdown': key, 'source': 'breakdown_pane' });
            }}
            value={breakdown}
            disabled={availableBreakdowns.length <= 1}
          />

          <div className='flex items-center font-medium text-caption-1 gap-2'>
            <Toggle
              checked={!chartCollapsed}
              onChange={() => {
                setChartCollapsed(!chartCollapsed);
                logEvent('collapse_drilldown_chart', { 'state': !chartCollapsed })
              }}
            />
            Chart
          </div>

          {!chartCollapsed &&
            <ChartTypeControl value={chartType} onChange={setChartType} />
          }

          {!drillDownOpened && drillDownEnabled && (
            <Button 
              theme='black'
              iconLeft='aside-layout'
              onClick={() => {
                setDrillDownOpened(true);
                logEvent('open_drilldown');
              }}
            >
              Show
            </Button>
          )}

          <div className='ml-auto'>
            <BreakdownHeaderStatus
              drillDown={drillDown}
              format={requestFilter.status === 'success' && ['cur', 'gcp'].includes(requestFilter.data.src) ? formatCurrency : formatNormal}
            />
          </div>
        </div>

        {!chartCollapsed && (
          <BreakdownChart
            drillDown={drillDown} 
            chartType={chartType}
            setCurrentStep={setCurrentStep} 
          />
        )}

        <Section.Content className='mt-5'>
          <BreakdownTable
            breakdown={breakdown}
            drillDown={drillDown}
            canDrillDown={availableBreakdowns.length > 0}
            setCurrentStep={setCurrentStep}
            startMoment={startMoment}
            endMoment={endMoment}
            prevStartMoment={prevStartMoment}
            prevEndMoment={prevEndMoment}
          />
        </Section.Content>
      </Section>
  )
};
