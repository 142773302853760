import type { DataEntry } from 'types/dataEntries';
import type { Granularity } from 'types/common';
import type { Filter } from 'types/filters';
import type { CostViewChartFetchParams } from 'types/costViews';

import { EMPTY_FILTER } from 'types/costViews';

import { useCostViewChart } from 'store/costLab';
import { useCostFilterValue } from 'hooks/costFilter';
import { useDerivedDataEntry } from './derivedDataEntry';
import { identity } from 'helpers/utils';

export interface UseTotalCostParams {
  startDate: string;
  endDate: string;
  filter?: Filter | DataEntry<Filter>;
  teamId?: number;
  globalFilterIds?: string[];
}

export const useTotalCost = (params: UseTotalCostParams) => {
  const {
    startDate,
    endDate,
    teamId,
    globalFilterIds,
    filter = EMPTY_FILTER
  } = params;

  const costFilter = useCostFilterValue();

  const chartEntryParams: DataEntry<CostViewChartFetchParams> = useDerivedDataEntry({ 
    startDate,
    endDate,
    teamId,
    globalFilterIds,
    filter,
    ...costFilter,
    granularity: 'day' as Granularity
  }, identity);

  const chart = useCostViewChart(chartEntryParams);

  const totalCost = useDerivedDataEntry(
    { chart },
    ({ chart }) => chart.reduce((sum, item) => item.current_timeframe_cost === null ? sum : sum + item.current_timeframe_cost, 0)
  );

  return totalCost;
}
