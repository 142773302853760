import type { Action, DataEntry, OptDataEntry } from 'types/dataEntries';
import type { Budget, BudgetPeriod, CostView, CostViewConstructor, CostViewDraft, CostViewLibraryEntry } from 'types/costViews';

import create from 'zustand';

import { IDLE_ENTRY } from 'types/dataEntries';
import { EMPTY_FILTER } from 'types/costViews';

import {
  createCostView,
  deleteCostView,
  fetchCostView,
  fetchCostViewLibrary,
  updateCostView,
} from 'services/costViews';

import {
  createStartAction,
  createFinishAction,

  createSetDraft,
  createGetDraftWithDeps,

  createSetEntryWithLibraries,
  createDiscardEntryWithLibraries,

  createGetEntry,
  createUpdateEntry,
  createDeleteEntry,
  createCreateEntry,
  createGetLibraryMultiple,
  createDiscardDraft
} from 'store/create';

import { identity } from 'helpers/utils';
import { parseCostViewFilter } from 'helpers/costViews';

export type CostViewActionType = 'create' | 'update' | 'delete';

export interface CostViewsStore {
  costViewConstructor: DataEntry<CostViewConstructor>;
  libraries: { [key: string]: DataEntry<CostViewLibraryEntry[]> };
  entries: { [key: number | string]: DataEntry<CostView> };
  actions: { [key: number | string]: Action<CostViewActionType> };
  drafts: { [key: number | string]: DataEntry<CostViewDraft> };

  startAction: (id: number | string, type: CostViewActionType) => void;
  finishAction: (id: number | string) => void;

  getEntry: (id: OptDataEntry<number>) => DataEntry<CostView>;
  setEntry: (id: number, entry: DataEntry<CostView>) => void;
  discardEntry: (id: number) => void;
  updateEntry: (id: number) => Promise<CostView>;
  createEntry: (id: number | string) => Promise<CostView>;
  deleteEntry: (id: number) => Promise<void>;

  getDraft: (id: OptDataEntry<number | string>, deps?: OptDataEntry<CostViewConstructor>) => DataEntry<CostViewDraft>;
  setDraft: (id: number | string, draft: CostViewDraft) => void;
  discardDraft: (id: number | string) => void;

  getLibrary: (params: { startDate: string, endDate: string }) => DataEntry<CostViewLibraryEntry[]>;

  getBudget: (viewId: number | string, period: BudgetPeriod) => Budget | null;
  setBudget: (viewId: number, budget: Budget) => void;
  discardBudget: (viewId: number, budgetId: number) => void;
}

const parseCostViewDraft = (
  view: CostView,
  deps: CostViewConstructor
) => {
  const {
    name,
    filter
  } = view;
    
  return {
    name,
    filter: parseCostViewFilter(filter, deps)
  };
}

export const useCostViewsStore = create<CostViewsStore>((set, get) => ({
  costViewConstructor: IDLE_ENTRY,
  libraries: {},
  entries: {},
  drafts: {},
  actions: {},

  startAction: createStartAction({ get, set }),
  finishAction: createFinishAction({ get, set }),

  setDraft: createSetDraft({ get, set }),
  getDraft: createGetDraftWithDeps({
    get,
    parseDraft: parseCostViewDraft,
    emptyDraft: {
      name: '',
      filter: EMPTY_FILTER
    }
  }),
  discardDraft: createDiscardDraft({ get, set }),

  setEntry: createSetEntryWithLibraries({
    get,
    set,
    toLibraryEntry: (view: CostView): CostViewLibraryEntry => ({
      ...view,
      total: null,
      prev_total: null,
      wow_change: null,
      wow_diff: null,
      created_by: null,
      library_data: false
    }),
    getLibraryEntryId: (libEntry: CostViewLibraryEntry) => libEntry.id
  }),

  discardEntry: createDiscardEntryWithLibraries({
    get,
    set,
    getLibraryEntryId: (libEntry: CostViewLibraryEntry) => libEntry.id
  }),

  getEntry: createGetEntry({
    get,
    set,
    fetchEntry: fetchCostView
  }),

  createEntry: createCreateEntry({
    get, 
    getId: (view: CostView) => view.id,
    createActionType: 'create' as const,
    serializeDraft: identity<CostViewDraft>,
    createEntry: createCostView,
  }),

  updateEntry: createUpdateEntry({
    get,
    updateActionType: 'update' as const,
    serializeDraft: identity<CostViewDraft>,
    updateEntry: updateCostView
  }),

  deleteEntry: createDeleteEntry({
    get,
    deleteActionType: 'delete' as const,
    deleteEntry: deleteCostView
  }),

  getLibrary: createGetLibraryMultiple({
    get,
    set,
    fetchLibrary: fetchCostViewLibrary,
    paramsToKey: (params: { startDate: string, endDate: string }) => `${params.startDate} ${params.endDate}`
  }),

  getBudget: (viewId: number | string, period: BudgetPeriod) => {
    if (typeof viewId === 'string') {
      return null;
    }

    const { getEntry } = get();

    const view = getEntry(viewId);

    if (view.status !== 'success') {
      return null;
    }

    return view.data.budgets.find((budget) => budget.period === period) || null;
  },

  setBudget: (viewId: number, budget: Budget) => {
    const {
      getEntry,
      setEntry
    } = get();

    const view = getEntry(viewId);

    if (view.status !== 'success') {
      return;
    }
    
    setEntry(viewId, {
      status: 'success',
      data: {
        ...view.data,
        budgets: [
          ...view.data.budgets.filter(({ id }) => id !== budget.id),
          budget
        ]
      }
    });
  },

  discardBudget: (viewId: number, budgetId: number) => {
    const {
      getEntry,
      setEntry
    } = get();

    const view = getEntry(viewId);

    if (view.status !== 'success') {
      return;
    }
    
    setEntry(viewId, {
      status: 'success',
      data: {
        ...view.data,
        budgets: view.data.budgets.filter(({ id }) => id !== budgetId),
      }
    });
  },
}));
