import type { UnitMetricChartWidget } from 'types/dashboards';
import type { LoadingDataEntry } from 'types/dataEntries';
import type { UnitMetric } from 'types/unitMetrics';

import moment from 'moment';
import { Select } from 'antd';
import { useEffect, useState } from 'react';

import { Loading } from 'shared/Loading';
import { PeriodChart } from 'shared/PeriodChart';
import { SectionTitle } from 'shared/Section';
import { FormRow, Label } from 'shared/Form';
import { WidgetWrapper } from './WidgetWrapper';
import { DateSettingsControl } from './controls';

import { useUnitMetricChartStore } from 'store/unitMetricChart';
import { useUnitMetricsStore, UnitMetricEntry } from 'store/unitMetrics';
import { useUnitMetricsLibraryStore } from 'store/unitMetricsLibrary';

export interface UnitMetricChartWidgetBlockProps {
  widget: UnitMetricChartWidget;
  onChange: (widget: UnitMetricChartWidget) => void;
  onRemove: () => void;
}

export const UnitMetricChartWidgetBlock = (props: UnitMetricChartWidgetBlockProps) => {
  const {
    widget,
    onChange,
    onRemove
  } = props;

  const {
    metricId,
    duration,
    granularity
  } = widget.settings;

  const [editing, setEditing] = useState(!metricId);

  const fetchUnitMetric = useUnitMetricsStore((store) => store.fetchUnitMetric);
  const metricEntry = useUnitMetricsStore((store) => metricId ?
    store.getUnitMetricEntry(metricId) :
    { status: 'idle' } as UnitMetricEntry
  );

  useEffect(() => {
    if (metricId !== null) {
      fetchUnitMetric(metricId);
    }
  }, [metricId]);

  const chart = useUnitMetricChartStore((store) => metricEntry.status === 'success' ? store.getEntry({
    endDate: moment().format('YYYY-MM-DD'),
    startDate: moment().subtract(duration, granularity).format('YYYY-MM-DD'),
    granularity,
    costType: 'unblended_cost',
    costDimensions: [],
    costAmortization: false,
    filter: (metricEntry.unitMetric as UnitMetric).filter
  }) : { status: 'loading' } as LoadingDataEntry);

  const { status: libraryStatus, unitMetrics, fetchUnitMetricsLibrary } = useUnitMetricsLibraryStore();

  useEffect(() => {
    if (libraryStatus === 'idle') {
      fetchUnitMetricsLibrary();
    }
  }, []);

  return (
    <WidgetWrapper
      widget={widget}
      onChange={onChange}
      editing={editing}
      onEditingChange={setEditing}
      onRemove={onRemove}
      form={<>
        <FormRow size='s'>
          <Label size='s'>
            Unit Metric
          </Label>

          <Select
            value={metricId + ''}
            loading={libraryStatus === 'loading'}
            options={unitMetrics.map((metric) => ({
              value: '' + metric.id,
              label: metric.name
            }))}
            onChange={(newViewId) => {
              onChange({
                ...widget,
                settings: {
                  ...widget.settings,
                  metricId: +newViewId
                }
              });
            }}
          />
        </FormRow>

        <DateSettingsControl
          value={widget.settings}
          onChange={(settings) => {
            onChange({
              ...widget,
              settings: {
                ...widget.settings,
                ...settings
              }
            });
          }}
        />
      </>}
    >
      {metricEntry.unitMetric && chart.status === 'success' ? (
        <>
          <SectionTitle className='mt-4 ml-5'>
            {metricEntry.unitMetric.name}
          </SectionTitle>

          <PeriodChart
            granularity={granularity}
            currentKey='current_timeframe_unit_metric'
            previousKey='previous_timeframe_unit_metric'
            data={chart.data}
          />
        </>
      ) : metricId ? (
        <div className={editing ? 'w-1/2 pt-[320px] absolute' : ''}><Loading /></div>
      ) : (
        <div className='text-center mr-[50%] pt-[150px] font-medium text-silver-grey-500 text-[24px]'>Select metric</div>
      )}
    </WidgetWrapper>
  );
}
