import type { PartialState } from 'zustand';
import { DataEntry, isDataEntry, OptDataEntry } from 'types/dataEntries';

import { withKeyDep } from './decorators';

export const createGetDraft = <
  TEntry,
  TDraft,
  TState extends {
    getEntry: (id: number) => DataEntry<TEntry>;
    drafts: { [key: string | number]: DataEntry<TDraft> };
  }
>({
  get,
  emptyDraft,
  parseDraft
}: {
  get: () => TState;
  emptyDraft?: TDraft;
  parseDraft: (entry: TEntry) => TDraft;
}) => withKeyDep((key: string | number) => {
  const { drafts, getEntry } = get(); 

  let draft = drafts[key];

  if (draft) {
    return draft;
  }

  if (typeof key === 'string') {
    if (!emptyDraft) {
      throw new Error('No empty draft provided');
    }

    draft = {
      status: 'success',
      data: emptyDraft
    };
  } else {
    const entry = getEntry(key);

    if (entry.status !== 'success') {
      return entry;
    }

    draft = {
      status: 'success',
      data: parseDraft(entry.data)
    };
  }

  drafts[key] = draft;

  return draft;
});

export const createSetDraft = <
  TDraft,
  TState extends {
    drafts: { [key: string | number]: DataEntry<TDraft> };
  }
>({
  get,
  set
}: {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
}) => (key: string | number, draft: TDraft) => {
  const { drafts } = get(); 

  set({
    drafts: {
      ...drafts,
      [key]: {
        status: 'success',
        data: draft
      }
    }
  } as PartialState<TState>);
};

export const createGetDraftWithDeps = <
  TEntry,
  TDraft,
  TParseDeps,
  TState extends {
    getEntry: (id: number) => DataEntry<TEntry>;
    drafts: { [key: string | number]: DataEntry<TDraft> };
  }
>({
  get,
  emptyDraft,
  parseDraft
}: {
  get: () => TState;
  emptyDraft?: TDraft;
  parseDraft: (entry: TEntry, deps: TParseDeps) => TDraft;
}) => (
  key: OptDataEntry<string | number>,
  deps?: OptDataEntry<TParseDeps>
): DataEntry<TDraft> => {
  if (isDataEntry(key)) {
    if (key.status !== 'success') {
      return key;
    }
    
    key = key.data;
  }

  if (isDataEntry(deps)) {
    if (deps.status !== 'success') {
      return deps;
    }

    deps = deps.data;
  }

  const { drafts, getEntry } = get(); 

  let draft = drafts[key];

  if (draft) {
    return draft;
  }

  if (typeof key === 'string') {
    if (!emptyDraft) {
      throw new Error('No empty draft provided');
    }

    draft = {
      status: 'success',
      data: emptyDraft
    };
  } else {
    if (deps === undefined) {
      throw new Error('No parse draft dependencies provided');
    }
    const entry = getEntry(key);

    if (entry.status !== 'success') {
      return entry;
    }

    draft = {
      status: 'success',
      data: parseDraft(entry.data, deps)
    };
  }

  drafts[key] = draft;

  return draft;
};

export const createDiscardDraft = <
  TDraft,
  TState extends {
    drafts: { [key: string | number]: DataEntry<TDraft> };
  }
>({
  get,
  set
}: {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
}) => (key: string | number) => {
  let { drafts } = get(); 

  drafts = { ...drafts };
  delete drafts[key];

  set({ drafts } as PartialState<TState>);
};
