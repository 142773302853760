import type {
  RootStringRule,
  ConstructorRule,
  ChildStringRule,
  ChildDictRule,
  LeafDictRule,
  ConstructorRules
} from 'types/filters';

import type {
  CostViewConstructor,
  CostViewConstructorBackend,
  CostViewConstructorBackendGCP,
  CostViewConstructorBackendSnowflake,
  RuleConstructorFields
} from 'types/costViews';

import type { UnitMetricConstructorBackendCustom } from 'types/unitMetrics';

export const parseRules = (constructorBackend: RuleConstructorFields): ConstructorRules => {
  const {
    rule_names,
    rule_tag_keys
  } = constructorBackend;

  if (!rule_names || !rule_tag_keys) {
    return {};
  }

  const ruleIds = Object.keys(rule_tag_keys);

  const rule_id: RootStringRule = {
    name: 'rule_id',
    valueType: 'string',
    options: ruleIds,
    aliases: rule_names,
    children: ['rule_tags'],
    requiredChildren: ['rule_tags'],
    exclusionAllowed: false,
    regexAllowed: false
  };

  const rule_tags: ChildDictRule = {
    name: 'rule_tags',
    valueType: 'dict',
    options: rule_tag_keys,
    parents: ['rule_id'],
    exclusionAllowed: false,
    regexAllowed: false
  };

  return {
    rule_id,
    rule_tags
  };
}

export const parseAccounts = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    aws_account_ids,
    aws_account_aliases
  } = constructorBackend;

  return {
    name: 'accounts',
    valueType: 'string',
    options: aws_account_ids,
    aliases: aws_account_aliases,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parsePayerAccounts = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    bill_payer_account_ids,
    aws_account_aliases
  } = constructorBackend;

  return {
    name: 'bill_payer_account_ids',
    valueType: 'string',
    options: bill_payer_account_ids,
    aliases: aws_account_aliases,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parseAccountTags = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    account_tags
  } = constructorBackend;

  return {
    name: 'account_tags',
    valueType: 'dict',
    options: account_tags,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parseRegions = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    region_names
  } = constructorBackend;

  return {
    name: 'regions',
    valueType: 'string',
    options: region_names,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parseServices = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    services
  } = constructorBackend;

  return {
    name: 'services',
    valueType: 'string',
    options: services,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parseUsageTypes = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    usage_types
  } = constructorBackend;

  return {
    name: 'usage_types',
    valueType: 'string',
    options: usage_types,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parseOperations = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    operations
  } = constructorBackend;

  return {
    name: 'operations',
    valueType: 'string',
    options: operations,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parseBillInvoiceIds = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    bill_invoice_ids
  } = constructorBackend;

  return {
    name: 'bill_invoice_ids',
    valueType: 'string',
    options: bill_invoice_ids,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parseChargeCategories = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    charge_categories
  } = constructorBackend;

  return {
    name: 'charge_categories',
    valueType: 'string',
    options: charge_categories,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parsePuchaseOptions = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    purchase_options
  } = constructorBackend;

  return {
    name: 'purchase_options',
    valueType: 'string',
    options: purchase_options,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parseRISPARNs = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    ri_sp_arns
  } = constructorBackend;

  return {
    name: 'ri_sp_arns',
    valueType: 'string',
    options: ri_sp_arns,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parseTags = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    tags
  } = constructorBackend;

  return {
    name: 'tags',
    valueType: 'dict',
    options: tags,
    regexAllowed: true,
    exclusionAllowed: true
  };
};

export const parsOpportunityTags = (constructorBackend: CostViewConstructorBackend): ConstructorRule => {
  const {
    opportunity_tags
  } = constructorBackend;

  return {
    name: 'opportunity_tags',
    valueType: 'dict',
    options: opportunity_tags,
    regexAllowed: true,
    exclusionAllowed: true,
    teamsOnly: true,
  };
};

export const parseServicesHierarchy = (constructorBackend: CostViewConstructorBackend): Record<string, ConstructorRule> => {
  const {
    services: serviceValues,
    service_filter_map: serviceFilterMap
  } = constructorBackend;

  const services: RootStringRule = {
    name: 'services',
    valueType: 'string',
    options: serviceValues,
    children: ['usage_types', 'k8s_cluster_ids', 'k8s_labels']
  };

  const usage_types: ChildStringRule = {
    name: 'usage_types',
    valueType: 'string',
    parents: ['services'],
    children: ['operations'],
    options: serviceValues.reduce((acc, service) => ({ ...acc, [service]: Object.keys(serviceFilterMap[service]) }), {})
  };

  const operations: ChildStringRule = {
    name: 'operations',
    valueType: 'string',
    parents: ['services', 'usage_types'],
    options: serviceFilterMap
  };

  return {
    services,
    usage_types,
    operations
  };
};

export const parseRegionsHierarchy = (constructorBackend: CostViewConstructorBackend): Record<string, ConstructorRule> => {
  const {
    region_names: regionValues,
    region_filter_map: regionFilterMap
  } = constructorBackend;

  const regions: RootStringRule = {
    name: 'regions',
    valueType: 'string',
    options: regionValues,
    children: ['availability_zones']
  };

  const availability_zones: ChildStringRule = {
    name: 'availability_zones',
    valueType: 'string',
    parents: ['region_names'],
    options: regionFilterMap
  };

  return {
    regions,
    availability_zones
  };
};

export const parseK8SHierarchy = (constructorBackend: CostViewConstructorBackend): Record<string, ConstructorRule> => {
  const {
    k8s_cluster_ids: clusterIds,
    k8s_cluster_filter_map: clusterFilterMap,
    k8s_labels: labels
  } = constructorBackend;

  const k8s_cluster_ids: ChildStringRule = {
    name: 'k8s_cluster_ids',
    valueType: 'string',
    options: {
      'AmazonEC2': clusterIds
    },
    parents: ['services'],
    children: ['k8s_namespaces'],
    exactParentRequired: true
  };

  const k8s_namespaces: ChildStringRule = {
    name: 'k8s_namespaces',
    valueType: 'string',
    parents: ['services', 'k8s_cluster_ids'],
    children: ['k8s_pods'],
    options: {
      'AmazonEC2': clusterIds.reduce((acc, clusterId) => ({ ...acc, [clusterId]: Object.keys(clusterFilterMap[clusterId]) }), {})
    }
  };

  const k8s_pods: ChildStringRule = {
    name: 'k8s_pods',
    valueType: 'string',
    parents: ['services', 'k8s_cluser_ids', 'k8s_namespaces'],
    options: {
      'AmazonEC2': clusterFilterMap
    }
  };

  const k8s_labels: LeafDictRule = {
    name: 'k8s_labels',
    valueType: 'dict',
    parents: ['services'],
    options: {
      'AmazonEC2': labels
    },
    exactParentRequired: true
  };

  return {
    k8s_cluster_ids,
    k8s_namespaces,
    k8s_pods,
    k8s_labels
  };
}

export const parseCustomData = (constructorCustom: UnitMetricConstructorBackendCustom): ConstructorRules => {
  return {
    stream_token: {
      name: 'stream_token',
      valueType: 'string',
      children: ['metric_name'],
      required: true,
      options: Object.keys(constructorCustom.denominator.metrics)
    },
    metric_name: {
      name: 'metric_name',
      valueType: 'string',
      parents: ['stream_token'],
      required: true,
      options: constructorCustom.denominator.metrics
    },
    dimensions: {
      name: 'dimensions',
      valueType: 'dict',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorCustom.denominator.dimensions
    }
  };
};

export const parseGCP = (constructorGCP: CostViewConstructorBackendGCP): ConstructorRules => {
  return {
    gcp_services: {
      name: 'gcp_services',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorGCP.services
    },
    gcp_locations: {
      name: 'gcp_locations',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorGCP.locations
    },
    gcp_accounts: {
      name: 'gcp_accounts',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorGCP.billing_account_ids
    },
    gcp_projects: {
      name: 'gcp_projects',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorGCP.projects_names
    },
    gcp_labels: {
      name: 'gcp_labels',
      valueType: 'dict',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorGCP.labels
    },
  };
}

export const parseSnowflake = (constructorSnowflake: CostViewConstructorBackendSnowflake): ConstructorRules => {
  return {
    snowflake_accounts: {
      name: 'snowflake_accounts',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorSnowflake.account_names
    },
    snowflake_account_locators: {
      name: 'snowflake_account_locators',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorSnowflake.account_locators
    },
    snowflake_regions: {
      name: 'snowflake_regions',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorSnowflake.regions
    },
    snowflake_usage_types: {
      name: 'snowflake_usage_types',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorSnowflake.usage_types
    },
    snowflake_organizations: {
      name: 'snowflake_organizations',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorSnowflake.organization_names
    },
    snowflake_balance_sources: {
      name: 'snowflake_balance_sources',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorSnowflake.balance_sources
    },
    snowflake_contract_numbers: {
      name: 'snowflake_contract_numbers',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorSnowflake.contract_numbers
    },
    /*
    snowflake_warehouses: {
      name: 'snowflake_warehouses',
      valueType: 'string',
      regexAllowed: true,
      exclusionAllowed: true,
      options: constructorSnowflake.warehouse
    },
    */
  };
}

export const filterEmpty = (constr: CostViewConstructor): CostViewConstructor => {
  Object.keys(constr).forEach((key) => {
    const empty = !Object.values(constr[key].rules).some((rule) => {
      return rule.options.length > 0;
    });

    if (empty) {
      delete constr[key];
    }
  });

  return constr;
}

export const parseCostViewConstructor = (
  constructorBackend: CostViewConstructorBackend,
  constructorCustom: UnitMetricConstructorBackendCustom,
  constructorGCP?: CostViewConstructorBackendGCP,
  constructorSnowflake?: CostViewConstructorBackendSnowflake
): CostViewConstructor => {
  const accounts = parseAccounts(constructorBackend);
  const regions = parseRegions(constructorBackend);
  const tags = parseTags(constructorBackend);
  const opportunity_tags = parsOpportunityTags(constructorBackend);
  const gcp = constructorGCP && parseGCP(constructorGCP);
  const snowflake = constructorSnowflake && parseSnowflake(constructorSnowflake);

  const serviceMapExists = Object.keys(constructorBackend.service_filter_map).length > 0
  let curFilters = {}
  if (serviceMapExists) {
    const account_tags = parseAccountTags(constructorBackend);
    const bill_payer_account_ids = parsePayerAccounts(constructorBackend);
    const servicesHierarchy = parseServicesHierarchy(constructorBackend);
    const k8sHierarchy = parseK8SHierarchy(constructorBackend);
    const regionsHiearchy = parseRegionsHierarchy(constructorBackend);
    const bill_invoice_ids = parseBillInvoiceIds(constructorBackend);
    const charge_categories = parseChargeCategories(constructorBackend);
    const purchase_options = parsePuchaseOptions(constructorBackend);
    const ri_sp_arns = parseRISPARNs(constructorBackend);
    curFilters = {
      accounts,
      bill_payer_account_ids,
      account_tags,
      ...regionsHiearchy,
      tags,
      opportunity_tags,
      ...k8sHierarchy,
      ...servicesHierarchy,
      bill_invoice_ids,
      charge_categories,
      purchase_options,
      ri_sp_arns
    }
  } else {
    const services = parseServices(constructorBackend);
    const usage_types = parseUsageTypes(constructorBackend);
    const operations = parseOperations(constructorBackend);
    curFilters = {
      accounts,
      regions,
      tags,
      opportunity_tags,
      services,
      usage_types,
      operations
    }
  }

  return filterEmpty({
    cur: {
      rules: {
        ...curFilters,
        ...parseRules(constructorBackend)
      }
    },
    custom_data: {
      minItems: 1,
      maxItems: 1,
      rules: {
        ...parseCustomData(constructorCustom),
        ...parseRules(constructorCustom.denominator),
      }
    },
    ...(gcp ? {
      gcp: {
        rules: gcp
      }
    } : {}),
    ...(snowflake ? {
      snowflake: {
        rules: snowflake
      }
    } : {})
  });
};
