import { Select } from 'antd';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useCostViewConstructor } from 'store/costLab';

export interface ServiceSelectProps {
  value: string | null;
  disabled?: boolean;
  onChange: (value: string | null) => void;
}

export const ServiceSelect = (props: ServiceSelectProps) => {
  const {
    value,
    disabled,
    onChange
  } = props;

  const costViewConstructor = useCostViewConstructor();
  const options = useDerivedDataEntry({ costViewConstructor }, ({ costViewConstructor }) => {
    const rule = costViewConstructor.cur?.rules?.services;

    return [
      {
        label: 'No Service',
        value: null
      },
      ...(rule ?
        (rule.options as string[])
          .filter(Boolean)
          .map((value) => ({
            value,
            label: rule.aliases && rule.aliases[value] || value
          })) :
          []
      )
    ];
  });

  return (
    <Select
      className='w-full'
      value={value || null}
      disabled={
        disabled ||
        options.status !== 'success' ||
        options.data.length === 0
      }
      options={options.status === 'success' ? options.data : []}
      loading={options.status === 'loading'}
      onChange={onChange}
    />
  );
}
