import create from 'zustand';
import { GroupByType } from 'services/metric';

export interface RightSizingRDSAttr {
  product_instance_type: string;
  product_database_engine: string; 
  product_orginal_target_memory: string;
  product_utilization: string;
  product_type: string;
  product_utilization_type: string; 
  product_memory: string;
  product_usage_type: string;
}

export interface RightSizingECSMemoryAttr {
  product_task_count: string;
  product_task_definition_arn: string; 
  product_task_arn: string;
  product_orginal_target_memory: string;
  product_utilization: string; 
  product_utilization_type: string; 
  product_memory: string;
}

export interface RightSizingECSCPUAttr {
  product_task_count: string;
  product_task_definition_arn: string; 
  product_task_arn: string;
  product_orginal_target_cpu: string;
  product_utilization: string; 
  product_utilization_type: string; 
  product_cpu: string;
}

export interface RightSizingEC2Attr {
  product_instance_type: string;
  product_operating_system: string; 
  product_orginal_target_memory: string;
  product_utilization: string; 
  product_type: string;
  product_utilization_type: string; 
  product_memory: string;
  product_usage_type: string;
}

export interface RightSizingEC2OptionAttr {
  target_instance_type: string;
  actual_target_memory: string;
  target_usage_type: string;
}

export interface RightSizingECSMemoryOptionAttr {
  actual_target_memory: string;
}

export interface RightSizingECSCPUOptionAttr {
  actual_target_cpu: string;
}

export interface RightSizingRDSOptionAttr {
  target_instance_type: string;
  actual_target_memory: string;
  target_usage_type: string;
}

export interface RightSizingData {
  aws_account_id: string;
  region_name: string;
  usage_type: string;
  billed_operation: string;
  resource_id: string;
  type: string;
  product_attr: RightSizingEC2Attr | RightSizingRDSAttr | RightSizingECSCPUAttr | RightSizingECSMemoryAttr;
  option_attr: RightSizingEC2OptionAttr | RightSizingRDSOptionAttr | RightSizingECSCPUOptionAttr | RightSizingECSMemoryOptionAttr;
  product_rate: number;
  option_rate: number;
}

type DrillDownStep = [GroupByType, string];

interface CostOverviewState {
  drillDownOpened: boolean;
  chartCollapsed: boolean;
}

interface CostOverviewActions {
  setDrillDownOpened: (opened: boolean) => void;
  setChartCollapsed: (collapsed: boolean) => void;
  setDrillDown: (drillDownSteps: DrillDownStep[]) => void;
}

interface CostOverviewStore extends CostOverviewState, CostOverviewActions {};

const DEFAULT_STATE: CostOverviewState = {
  drillDownOpened: false,
  chartCollapsed: false,
}

export const useCostOverviewStore = create<CostOverviewStore>((set) => ({
  ...DEFAULT_STATE,

  setDrillDownOpened: (drillDownOpened: boolean) => {
    set({ drillDownOpened })
  },

  setChartCollapsed: (chartCollapsed: boolean) => {
    set({ chartCollapsed })
  },

  setDrillDown: async (drillDownSteps) => {
    set({
      drillDownOpened: drillDownSteps.length > 0
    });
  }
}));
