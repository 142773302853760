import type { Granularity } from 'types/dateFilter';
import type { CostFilterFetchParams } from 'types/costViews';

export type WidgetType = 'cost-view-chart' | 'unit-metric-chart' | 'multi-cost-view-chart';

export interface WidgetBase {
  type: WidgetType | null;
  settings: {};
  width?: number;
}

export interface CostViewChartWidget extends WidgetBase {
  type: 'cost-view-chart';
  settings: {
    viewId: number | null;
    dashboardDates?: boolean;
    dashboardCost?: boolean;
    duration: number | null;
    granularity: Granularity;
  } & CostFilterFetchParams;
}

export interface MultiCostViewChartWidget extends WidgetBase {
  type: 'multi-cost-view-chart';
  settings: {
    viewIds: number[];
    dashboardDates?: boolean;
    dashboardCost?: boolean;
    duration: number | null;
    granularity: Granularity;
  } & CostFilterFetchParams;
}

export interface UnitMetricChartWidget extends WidgetBase {
  type: 'unit-metric-chart';
  settings: {
    metricId: number | null;
    dashboardDates?: boolean;
    dashboardCost?: boolean;
    duration: number | null;
    granularity: Granularity;
  } & CostFilterFetchParams;
}

export interface EmptyWidget extends WidgetBase {
  type: null;
}

export type Widget = CostViewChartWidget | MultiCostViewChartWidget | UnitMetricChartWidget | EmptyWidget;

export const isCostViewChartWidget = (widget: Widget): widget is CostViewChartWidget => widget.type === 'cost-view-chart';
export const isUnitMetricChartWidget = (widget: Widget): widget is UnitMetricChartWidget => widget.type === 'unit-metric-chart';
export const isMultiCostViewChartWidtget = (widget: Widget): widget is MultiCostViewChartWidget => widget.type === 'multi-cost-view-chart';
export const isEmptyWidget = (widget: Widget): widget is CostViewChartWidget => widget.type === null;
