import { createKeyedStore } from '../createKeyedStore';
import { createKeyedStoreSelector } from '../createKeyedStoreSelector';

import { drillDownFetchParamsToKey } from 'helpers/storeKeys/drillDown';
import { fetchCostViewDrillDown } from 'services/costViews';

export const useCostViewDrillDownStore = createKeyedStore({
  paramsToKey: drillDownFetchParamsToKey,
  fetchData: fetchCostViewDrillDown
});

export const useCostViewDrillDown = createKeyedStoreSelector(useCostViewDrillDownStore);
