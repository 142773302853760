import type { DataEntry } from 'types/dataEntries';
import type { SortDir } from 'types/common';
import type { Team } from 'types/teams';
import type { SorterResult } from 'antd/lib/table/interface';
import type { TeamLeaderboardSortField } from 'types/savings';

import moment, { Moment } from 'moment';
import { useMemo, useState } from 'react';
import { Page } from 'shared/Page';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { StatusOverlay } from 'shared/Overlay';
import { SettingsPanel } from './SettingsPanel';
import { ExportToCsvButton } from 'shared/ExportToCsvButton';
import { Section, SectionTitle } from 'shared/Section';
import { RadioButton, RadioButtonGroup } from 'shared/RadioButtonGroup';

import { isTeamLeaderboardSortField } from 'types/savings';
import { useTeamsStore } from 'store/teams';
import { useSortQueryParams } from 'hooks/queryParams';
import { 
  useSavingsSettingsStore,
  useTotalSpendSummary,
  useCostImpactSummaryChunk,
  useSavedAndWastedSummaryChunk,
 } from 'store/savings';
import { 
  ACTIVE_OPPORTUNITY_STATUSES,
  INPROGRESS_OPPORTUNITY_STATUSES,
 } from 'helpers/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { formatCurrency } from 'helpers/formatter';
import { EMPTY_FILTER } from 'types/costViews';
import { ThreadCreateDialog } from './dialogs/ThreadCreateDialog';

const MONTHS_TO_DISPLAY = 6;

interface TeamLeaderboardItem {
  id: number;
  name: string;
  activeSavings: {
    costImpact: number;
    opportunitiesCount: number;
  };
  activeSavingsInProgress: {
    costImpact: number;
    opportunitiesCount: number;
  };
  savedAndWastedAndSpent: {
    [key: string]: {
      wasted: number;
      achieved: number;
      spent: number;
    }
  };
}

export const TeamLeaderboardPage = () => {
  const teams = useTeamsStore((store) => store.getLibrary());
  const today = useMemo(() => moment().startOf('day'), []);
  const currentMonth = today.clone().startOf('month').format('YYYY-MM-DD');
  const chunkSize = 5;

  const [mutuallyExclusiveSavings, setMutuallyExclusiveSavings] = useState<boolean>(true)

  const months = useMemo(() => {
    const result: Moment[] = [];

    for (let i = 0; i < MONTHS_TO_DISPLAY; i++) {
      const month = today.clone().subtract(i + 1, 'month').startOf('month');

      result.push(month);
    }
    
    return result;
  }, [today]);

  const defaultDirs = useMemo(() => months.reduce((acc, month) => ({
    ...acc,
    [`${month.format('YYYY-MM-DD')}-wasted`]: 'descend',
    [`${month.format('YYYY-MM-DD')}-achieved`]: 'descend',
    [`${month.format('YYYY-MM-DD')}-spent`]: 'descend',
  }), {
    name: 'ascend',
    active: 'descend',
    'in-progress': 'descend',
    'this-month-spend': 'descend',
  } as Record<TeamLeaderboardSortField, SortDir>), [months]);

  const [sort, setSort] = useSortQueryParams({
    defaultField: 'active',
    isField: isTeamLeaderboardSortField,
    defaultDirs
  });

  const pageFilters = {
    ...useSavingsSettingsStore(),
    teamId: undefined
  };

  const teamChunks = useMemo(() => {
    const chunks = [];
    if (teams.status === 'success') {
      for (let i = 0; i < teams.data.length; i += chunkSize) {
        chunks.push(teams.data.slice(i, i + chunkSize).map((t) => t.id))
      }
    }
    return chunks;
  }, [teams])

  const costImpactSummaryChunk = useCostImpactSummaryChunk({
    groupBy: 'teams',
    teamChunks: teamChunks,
    prefilter: {
      status: ACTIVE_OPPORTUNITY_STATUSES
    },
    pageFilters,
    tableFilters: {},
  })

  const costImpactInProgressSummaryChunkParams = useDerivedDataEntry({
    costImpactSummaryChunk,
    groupBy: 'teams' as const,
    teamChunks: teamChunks,
    prefilter: {
      status: INPROGRESS_OPPORTUNITY_STATUSES
    },
    pageFilters,
    tableFilters: {},
  }, ({
    costImpactSummaryChunk,
    ...rest
  }) => rest);

  const costImpactInProgressSummaryChunk = useCostImpactSummaryChunk(costImpactInProgressSummaryChunkParams);

  const savedAndWastedSummaryChunkParams = useDerivedDataEntry({
    costImpactInProgressSummaryChunk,
    startDate: months[months.length - 1].format('YYYY-MM-DD'),
    endDate: today.format('YYYY-MM-DD'),
    granularity: 'monthly' as const,
    groupBy: 'teams' as const,
    teamChunks: teamChunks,
    teamsDeduplication: mutuallyExclusiveSavings,
    prefilter: {},
    pageFilters,
    tableFilters: {},
  }, ({
    costImpactInProgressSummaryChunk,
    ...rest
  }) => rest)

  const savedAndWastedSummaryChunk = useSavedAndWastedSummaryChunk(savedAndWastedSummaryChunkParams);
  
  const totalSpendSummaryParams = useDerivedDataEntry({
    savedAndWastedSummaryChunk,
    startDate: months[months.length - 1].format('YYYY-MM-DD'),
    endDate: today.format('YYYY-MM-DD'),
    granularity: 'month' as const,
    breakdown: 'teams' as const,
    costType: 'unblended_cost' as const,
    costAmortization: false,
    costDimensions: [],
    drillDownSteps: [],
    filter: EMPTY_FILTER,
  }, ({
    savedAndWastedSummaryChunk,
    ...rest
  }) => rest);

  const totalSpendSummary = useTotalSpendSummary(totalSpendSummaryParams);

  const tableData: DataEntry<TeamLeaderboardItem[]> = useDerivedDataEntry({
    teams,
    costImpactSummaryChunk,
    costImpactInProgressSummaryChunk,
    savedAndWastedSummaryChunk,
    totalSpendSummary,
  }, ({ 
    teams,
    costImpactSummaryChunk,
    costImpactInProgressSummaryChunk,
    savedAndWastedSummaryChunk,
    totalSpendSummary,
  }) => {
    const teamsMap = teams.reduce((acc, team) => {
      const {
        id,
        name,
      } = team;

      return {
        ...acc,
        [id]: {
          id,
          name,
          activeSavings: {
            costImpact: 0,
            opportunitiesCount: 0
          },
          activeSavingsInProgress: {
            costImpact: 0,
            opportunitiesCount: 0
          },
          savedAndWastedAndSpent: {}
        }
      };
    }, {} as {
      [key: number]: TeamLeaderboardItem
    });
    const costImpactSummary = costImpactSummaryChunk.reduce((acc, teamChunk) => acc.concat(teamChunk), [])
    costImpactSummary.forEach((summaryItem) => {
      const {
        group_by: teamId,
        cost_impact_sum: costImpact,
        opportunities_count: opportunitiesCount
      } = summaryItem;

      if (teamId && teamsMap[+teamId]) {
        teamsMap[+teamId].activeSavings = {
          costImpact,
          opportunitiesCount
        };
      }
    });
    const costImpactInProgressSummary = costImpactInProgressSummaryChunk.reduce((acc, teamChunk) => acc.concat(teamChunk), [])
    costImpactInProgressSummary.forEach((summaryItem) => {
      const {
        group_by: teamId,
        cost_impact_sum: costImpact,
        opportunities_count: opportunitiesCount
      } = summaryItem;

      if (teamId && teamsMap[+teamId]) {
        teamsMap[+teamId].activeSavingsInProgress = {
          costImpact,
          opportunitiesCount
        };
      }
    });
    const savedAndWastedSummary = savedAndWastedSummaryChunk.reduce((acc, teamChunk) => acc.concat(teamChunk), [])
    savedAndWastedSummary.forEach((summaryItem) => {
      const {
        group_by: teamId,
        start_date: month,
        wasted,
        cost_impact_saved: achieved
      } = summaryItem;

      if (teamId && teamsMap[+teamId]) {
        teamsMap[+teamId].savedAndWastedAndSpent[month] = {
          ...teamsMap[+teamId].savedAndWastedAndSpent[month],
          wasted,
          achieved
        }
      }
    });

    totalSpendSummary.data.forEach((summaryItem) => {
      const {
        team: teamId,
        date: month,
        current_timeframe_cost: spent,
      } = summaryItem;

      if (teamId && teamsMap[+teamId]) {
        teamsMap[+teamId].savedAndWastedAndSpent[month] = {
          ...teamsMap[+teamId].savedAndWastedAndSpent[month],
          spent: spent ?? 0,
        }
      }
    });

    return Array.from(Object.values(teamsMap));
  });

  return (
    <Page>
      <Page.Head title='Team Leaderboard'>
        <SettingsPanel showTeamSetting={false} />
      </Page.Head>

      <Section className='p-5 mb-[60px]'>
        <SectionTitle className='mb-5'>
          Team Savings Summary
        </SectionTitle>

        <div>
          <RadioButtonGroup
            name='teamsDeduplication'
            value={mutuallyExclusiveSavings + ''}  
            onChange={(_, { value }) => {
              setMutuallyExclusiveSavings(value === "true");
            }}
          >
            <RadioButton value='true'>Mutually Exclusive Savings</RadioButton>
            <RadioButton value='false'>Non-Mutually Exclusive Savings</RadioButton>
          </RadioButtonGroup>
        </div>

        <Table
          size='small'
          dataSource={
            tableData.status === 'success' ?
              tableData.data :
              []
          }
          scroll={{
            x: 2400
          }}
          pagination={false}
          rowKey={(data) => data.id}
          columns={[
            {
              key: 'name',
              title: 'Team',
              className: 'w-[240px]',
              fixed: true,
              sortOrder: sort.field === 'name' ? sort.dir : null,
              sorter: (dataA, dataB) => dataA.name > dataB.name ? -1 : 1,
              render: (data) => (
                <Link
                  className='!text-caladon-blue'
                  to={`/savings/opportunities?teamId=${data.id}`}
                >
                  {data.name}
                </Link>
              )
            },
            {
              key: today.format('YYYY-MM-DD'),
              title: 'Current Month',
              fixed: true,
              children: [
                {
                  key: 'active',
                  title: <span className='text-caption-2'>Active Savings</span>,
                  fixed: true,
                  sortOrder: sort.field === 'active' ? sort.dir : null,
                  sorter: (dataA, dataB) => dataA.activeSavings.costImpact - dataB.activeSavings.costImpact,
                  render: (data) => formatCurrency(data.activeSavings.costImpact * 30)
                },
                {
                  key: 'in-progress',
                  title: <span className='text-caption-2'>In Progress Savings</span>,
                  fixed: true,
                  sortOrder: sort.field === 'in-progress' ? sort.dir : null,
                  sorter: (dataA, dataB) => dataA.activeSavingsInProgress.costImpact - dataB.activeSavingsInProgress.costImpact,
                  render: (data) => formatCurrency(data.activeSavingsInProgress.costImpact * 30)
                },
                {
                  key: 'this-month-spend',
                  title: <span className='text-caption-2'>This Month Spend</span>,
                  fixed: true,
                  sortOrder: sort.field === 'this-month-spend' ? sort.dir : null,
                  sorter: (dataA: TeamLeaderboardItem, dataB: TeamLeaderboardItem) => dataA.savedAndWastedAndSpent[currentMonth].spent - dataB.savedAndWastedAndSpent[currentMonth].spent,
                  render: (data: TeamLeaderboardItem) => formatCurrency(data.savedAndWastedAndSpent[currentMonth].spent)
                }
              ]
            },
            ...months.map((month) => {
              const key = month.format('YYYY-MM-DD');

              return {
                key,
                title: month.format('MMMM YYYY'),
                className: 'border-l-2',
                children: [
                  {
                    className: 'border-l-2',
                    key: `${key}-wasted`,
                    title: <span className='text-caption-2'>Wasted</span>,
                    sortOrder: sort.field === `${key}-wasted` ? sort.dir : null,
                    sorter: (dataA: TeamLeaderboardItem, dataB: TeamLeaderboardItem) => dataA.savedAndWastedAndSpent[key].wasted - dataB.savedAndWastedAndSpent[key].wasted,
                    render: (data: TeamLeaderboardItem) => formatCurrency(data.savedAndWastedAndSpent[key].wasted || 0)
                  },
                  {
                    key: `${key}-spent`,
                    title: <span className='text-caption-2'>Spent</span>,
                    sortOrder: sort.field === `${key}-spent` ? sort.dir : null,
                    sorter: (dataA: TeamLeaderboardItem, dataB: TeamLeaderboardItem) => dataA.savedAndWastedAndSpent[key].spent - dataB.savedAndWastedAndSpent[key].spent,
                    render: (data: TeamLeaderboardItem) => formatCurrency(data.savedAndWastedAndSpent[key].spent || 0)
                  },
                  {
                    key: `${key}-achieved`,
                    title: <span className='text-caption-2'>Saved / Mo</span>,
                    sortOrder: sort.field === `${key}-achieved` ? sort.dir : null,
                    sorter: (dataA: TeamLeaderboardItem, dataB: TeamLeaderboardItem) => dataA.savedAndWastedAndSpent[key].achieved - dataB.savedAndWastedAndSpent[key].achieved,
                    render: (data: TeamLeaderboardItem) => formatCurrency((data.savedAndWastedAndSpent[key].achieved || 0) * 30)
                  },
                ]
              };
            }),
          ]}
          onChange={(_, _tableFilters, tableSort, meta) => {
            if (meta.action === 'sort') {
              const field = (tableSort as SorterResult<Team>).columnKey as TeamLeaderboardSortField;
              const dir = sort.field !== field ?
                defaultDirs[field] :
                sort.dir === 'ascend' ? 'descend' : 'ascend';

              setSort({ field, dir });
            }
          }}
          footer={() =>
            <div className='flex'>
              <div className='mt-[5px] ml-auto'>
                <ExportToCsvButton
                  fileName='teamLeaderboard'
                  data={tableData.status === 'success' ?
                    tableData.data.map((item) => ({
                      id: item.id,
                      name: item.name,
                      total_active_savings: item.activeSavings.costImpact,
                      total_inprogress_savings: item.activeSavingsInProgress.costImpact,
                      opportunities_count: item.activeSavings.opportunitiesCount,
                      ...Object.entries(item.savedAndWastedAndSpent).reduce((acc, [date, { wasted, achieved, spent }]) => ({
                        ...acc,
                        [`${date}_wasted`]: wasted,
                        [`${date}_achieved`]: achieved,
                        [`${date}_spent`]: spent,
                      }), {})
                    })):
                    []
                  }
                />
              </div>
            </div>
          }
        />

        <StatusOverlay status={tableData.status} />
      </Section>

      <ThreadCreateDialog />
    </Page>    
  );
};
