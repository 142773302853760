import { useEffect, useState, useMemo } from 'react'
import type { ReportConfig } from 'types/reports';
import { Link } from 'react-router-dom'
import { Table, message } from 'antd'
import { Section } from 'shared/Section';
import { Icon } from 'shared/Icon/Icon';
import { Toggle } from  'shared/Toggle/Toggle'
import { Loading } from 'shared/Loading/Loading';
import { DeleteReportDialog } from './DeleteReportDialog';
import Button from 'shared/Button/Button';
import IconButton from 'shared/Button/IconButton';
import { useReportStore } from 'store/reportConfigs';
import { useUnitMetricsLibraryStore } from 'store/unitMetricsLibrary'
import { useSlackChannelsStore } from 'store/slackChannels'
import { useCostViewConstructorStore, useCostViewsStore } from 'store/costLab';
import { createNullableStringsComparator } from 'helpers/sort';
import moment from 'moment';
import CreateDialog from './CreateDialog'
import { SERVER_DATE_FORMAT } from 'helpers/constants';

export interface LibraryProps {
  viewReport: string | null;
  metricReport: string | null;
  minSpend?: number;
  absoluteThreshold?: number;
  relativeThreshold?: number;
}

const libraryParams = {
  startDate: moment().format(SERVER_DATE_FORMAT),
  endDate: moment().subtract(7, 'days').format(SERVER_DATE_FORMAT)
};

const ReportsLibrary = (props: LibraryProps) => {
  const { 
    viewReport, 
    metricReport, 
    minSpend = 10, 
    absoluteThreshold = 1, 
    relativeThreshold = 0.1,
 } = props;
  const {
    status: configStatus,
    reportConfigs,
    fetchReportConfigs,
    deleteReportConfig,
    setIsActiveReportConfig,
  } = useReportStore();
  const { status: unitMetricStatus, unitMetrics, fetchUnitMetricsLibrary } = useUnitMetricsLibraryStore();
  const { slackChannels, slackIntegraitonId, fetchSlackChannels } = useSlackChannelsStore();
  const [searchText, setSearchText] = useState('');
  const [reportToDelete, setReportToDelete] = useState<ReportConfig | null>(null);
  const [reportToEdit, setReportToEdit] = useState<ReportConfig | null>(null);
  const [createDialogOpened, setCreateDialogOpened] = useState(false);

  const costViewLibrary = useCostViewsStore((store) => store.getLibrary(libraryParams));

  const costViews = useMemo(() =>
    costViewLibrary.status === 'success' ?
      costViewLibrary.data.filter((view) => view.src === 'cur') :
      [],
    [costViewLibrary]
  );

  const tags = useCostViewConstructorStore((store) => {
    const costViewConstructor = store.getCostViewConstructor();

    if (costViewConstructor.status === 'success') {
      return ((costViewConstructor.data.cur?.rules.tags?.options || []) as string[]).map((key) => ({ key, value: key }));
    }

    return [];
  });

  const handleSetActive = (config: ReportConfig, is_active: boolean) => {
    setIsActiveReportConfig(config.id, is_active);
  };

  useEffect(() => {
    fetchReportConfigs();
    fetchUnitMetricsLibrary();
    fetchSlackChannels();
    if (viewReport) {
      handleEditReport({
        id: -1,
        name: '',
        owner: '',
        report_type_id: 'GENERALCOST',
        cost_metric_filter_id: parseInt(viewReport),
        cost_metric_filter: {},
        unit_metric_filter_ids: [],
        unit_metric_filters: [],
        tag_keys: [],
        cadence: '',
        wow_change_spend_threshold: minSpend,
        wow_change_delta_abs_threshold: absoluteThreshold,
        wow_change_delta_rel_threshold: 1 + relativeThreshold / 100,
        dod_change_spend_threshold: 10,
        dod_change_delta_abs_threshold: 1,
        dod_change_delta_rel_threshold: 1.1,
        cost_column: 'unblended_cost',
        amortization: false,
        exclude: '',
        channels: '',
        email_recepients: [],
        slack_id: slackIntegraitonId,
        slack_channels: [],
        daily_movers: false,
        is_active: true,
        created_at: '',
      })
    } else if (metricReport) {
      handleEditReport({
        id: -1,
        name: '',
        owner: '',
        report_type_id: 'GENERALCOST',
        cost_metric_filter_id: null,
        cost_metric_filter: {},
        unit_metric_filter_ids: [parseInt(metricReport)],
        unit_metric_filters: [],
        tag_keys: [],
        cadence: '',
        wow_change_spend_threshold: 10,
        wow_change_delta_abs_threshold: 1,
        wow_change_delta_rel_threshold: 1.1,
        dod_change_spend_threshold: 10,
        dod_change_delta_abs_threshold: 1,
        dod_change_delta_rel_threshold: 1.1,
        cost_column: 'unblended_cost',
        amortization: false,
        exclude: '',
        channels: '',
        email_recepients: [],
        slack_id: slackIntegraitonId,
        slack_channels: [],
        daily_movers: false,
        is_active: true,
        created_at: '',
      })
    }
  }, [])

  const handleEditReport = (report: ReportConfig) => {
    setReportToEdit(report)
    setCreateDialogOpened(true)
  }

  const filteredReports = useMemo(() => {
    return searchText ?
      reportConfigs.filter((report) => report.name.toLowerCase().includes(searchText.toLowerCase())) :
      reportConfigs;
  }, [reportConfigs, searchText]);

  return (
    <div className='pt-[35px]'>
      <Section className='p-[20px]'>
        <div className='flex mb-[20px]'>
          <div className='flex items-center grow pl-[11px]'>
            <Icon icon='search' />
            <input
              type='text'
              className='w-full leading-[20px] text-[14px] my-[-5px] py-[10px] pl-[40px] ml-[-29px] bg-transparent outline-none border-b border-b-transparent focus:border-b-cyanine-blue'
              value={searchText} 
              placeholder='Search'
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
            />
          </div>
          <Button
            icon='circle-plus'
            size='sm'
            type='primary'
            style='caladon'
            label='Create New Report'
            onClick={() => setCreateDialogOpened(true)}
          />
        </div> 

        <Table
          rowKey={({ id }) => id}
          size='small'
          rowClassName='text-[12px] line-[14px]'
          onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
          dataSource={filteredReports}
          columns={[
            {
              key: 'name',
              title: 'Name',
              dataIndex: 'name',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
              render: (name) => name
            },
            {
              key: 'report_type_id',
              title: 'Report Type',
              dataIndex: 'report_type_id',
              sorter: (a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
              render: (report_type_id) => report_type_id == 'GENERALCOST' ? 'Visibility' : 'Savings'
            },
            {
              key: 'costview',
              title: 'Cost View',
              dataIndex: 'costview',
              render: (_, report) => (
                report.cost_metric_filter_id ? (
                  <Link className='text-cyanine-blue' to={`/costs-overview/${report.cost_metric_filter_id}`}>
                    {costViews.filter((view) => view.id === report.cost_metric_filter_id)[0]?.name}
                  </Link>
                ) : (
                  'n/a'
                )
              )  
            },
            {
              key: 'owner',
              dataIndex: 'owner',
              title: 'Created By',
              sorter: createNullableStringsComparator('owner'),
              render: (owner) => owner
            },
            {
              key: 'channels',
              dataIndex: 'channels',
              title: 'Channels',
              sorter: createNullableStringsComparator('channels'),
              render: (channels) => channels
            },
            {
              key: 'cadence',
              dataIndex: 'cadence',
              title: 'Schedule',
              render: () => 'Weekly on Monday'
            },
            {
              key: 'is_active',
              dataIndex: 'is_active',
              title: 'Active',
              sorter: (a) => a.is_active ? 1 : -1,
              render: (is_active, report) => <Toggle checked={is_active} onChange={() => handleSetActive(report, !is_active)} />
            },
            {
              key: 'created_at',
              title: 'Created at',
              dataIndex: 'created_at',
              sorter: createNullableStringsComparator('created_at'),
              render: (createdAt?: string) => createdAt ? moment(createdAt).format('YYYY-MM-DD') : 'n/a'
            },
            {
              key: 'edit',
              width: 32,
              render: (_, report) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='edit' onClick={() => handleEditReport(report)} />
            },     
            {
              key: 'delete',
              width: 32,
              render: (_, report) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='trash' onClick={() => setReportToDelete(report)} />
            },
          ]}
        />
        {(configStatus === 'loading' || costViewLibrary.status === 'loading' || unitMetricStatus === 'loading') && <Loading />}
        <CreateDialog
          opened={createDialogOpened}
          onClose={(search) => {
            setCreateDialogOpened(false);
            setReportToEdit(null);
            if (search) {
              setSearchText(search)
            }
          }}
          reportToEdit={reportToEdit}
          costViews={costViews}
          unitMetrics={unitMetrics}
          slackIntegraitonId={slackIntegraitonId}
          slackChannels={slackChannels}
          tags={tags}
        />
        <DeleteReportDialog
          open={!!reportToDelete}
          name={reportToDelete?.name || ''}
          onClose={() => setReportToDelete(null)}
          onConfirm={async () => {
            if (!reportToDelete) {
              return;
            }

            try {
              await deleteReportConfig(reportToDelete.id);
              setReportToDelete(null);
              message.success(`Report '${reportToDelete.name}' deleted`);
            } catch {
              message.error(`Unable to delete '${reportToDelete.name}'`);
            }
          }}
        />
      </Section>
    </div>
  )
}

export default ReportsLibrary
