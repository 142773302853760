import type { PartialState } from 'zustand';
import { DataEntry, LOADING_ENTRY } from 'types/dataEntries';

export const createFetchLibrary = <TEntry, TState extends { library: DataEntry<TEntry[]> }>({
  fetchLibrary,
  get,
  set
} : {
  fetchLibrary: () => Promise<{ data: TEntry[] }>;
  get: () => TState;
  set: (p: PartialState<TState>) => void;
}) => () => {
  let { library } = get();

  if (library.status === 'idle') {
    library = { status: 'loading' };

    set({ library } as PartialState<TState>);

    fetchLibrary()
      .then(({ data }) => {
        set({
          library: {
            data,
            status: 'success'
          }
        } as PartialState<TState>);
      })
      .catch((error) => {
        set({
          library: {
            error,
            status: 'error'
          }
        } as PartialState<TState>);
      });
  }
}

export const createGetLibrary = <TEntry, TState extends {
  library: DataEntry<TEntry[]>,
  fetchLibrary: () => void
}>({
  get,
} : {
  get: () => TState;
}) => () => {
  const { library, fetchLibrary } = get();

  if (library.status === 'idle') {
    fetchLibrary();

    return get().library;
  }

  return library;
};

export const createGetLibraryMultiple = <
  TEntry,
  TParams,
  TState extends {
    libraries: { [key: string]: DataEntry<TEntry[]> },
  }>({
  get,
  set,
  paramsToKey,
  fetchLibrary
} : {
  get: () => TState;
  set: (p: PartialState<TState>) => void;
  paramsToKey: (params: TParams) => string;
  fetchLibrary: (params: TParams) => Promise<{ data: TEntry[] }>;
}) => (params: TParams) => {
  const key = paramsToKey(params);

  const { libraries } = get();

  let library = libraries[key];

  if (!library) {
    library = LOADING_ENTRY;
    libraries[key] = library;

    fetchLibrary(params)
      .then(({ data }) => {
        set({
          libraries: {
            ...get().libraries,
            [key]: {
              status: 'success',
              data
            }
          }
        } as PartialState<TState>);
      })
      .catch((error) => {
        set({
          libraries: {
            ...get().libraries,
            [key]: {
              status: 'error',
              error
            }
          }
        } as PartialState<TState>);
      });
  }

  return library;
};
