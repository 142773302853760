import { IconButton } from 'shared/Button';
import { useCostViewsStore, useDeleteViewDialog } from 'store/costLab';

export interface DeleteViewButtonProps {
  viewId: number;
}

export const DeleteViewButton = (props: DeleteViewButtonProps) => {
  const {
    viewId
  } = props;

  const view = useCostViewsStore((store) => store.getEntry(viewId));
  const openDeleteViewDialog = useDeleteViewDialog((store) => store.open);

  return (
    <IconButton
      className='text-[10px] shrink-0 w-9'
      icon='trash-can'
      geometry='circle'
      hoverStyle='negative'
      type='tertiary'
      size='xs'
      direction='column'
      onClick={() => {
        if (view.status === 'success') {
          openDeleteViewDialog(view.data);
        }
      }}
    >
      Delete
    </IconButton>
  );
}
