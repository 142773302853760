import type { Source } from 'types/unitMetrics';

import { useEffect, useState, useMemo } from 'react';

import { Button2 as Button } from 'shared/Button';
import { Section, SectionTitle } from 'shared/Section';
import { PortalRoot } from 'shared/Portal';
import { StatusOverlay } from 'shared/Overlay';
import { TeamFilterSelect } from 'shared/TeamFilterSelect';
import { CostViewFilters, SourceControl } from 'shared/Filters';

import {
  toImmutableCostViewFilter,
  parseCostViewFilter,
  validateCostViewFilter,
  cleanupCostViewFilter
} from 'helpers/costViews';

import { identity } from 'helpers/utils';
import { EMPTY_FILTER } from 'types/costViews';

import { useTeamId } from 'hooks/teamId';
import { useTeamFilters } from 'hooks/teamFilters';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

import { useGlobalState } from 'state/globalState';
import { useCostViewsStore, useCostViewConstructor } from 'store/costLab';
import { useTeamFilterConstructor } from 'store/teamFilterConstructor';

export interface FiltersSectionProps {
  viewId: string | number;
}

export const FiltersSection = (props: FiltersSectionProps) => {
  const { viewId } = props;
  const { logEvent } = useGlobalState();

  const costViewConstructor = useCostViewConstructor();
  const teamFilterConstructor = useTeamFilterConstructor();

  const draft = useCostViewsStore((store) => store.getDraft(viewId, costViewConstructor));
  const setDraft = useCostViewsStore((store) => store.setDraft);

  const [teamId, setTeamId] = useTeamId();
  const [currentValue, setCurrentValue] = useState(EMPTY_FILTER);

  const teamFilters = useTeamFilters(teamId);

  useEffect(() => {
    if (draft.status === 'success') {
      setCurrentValue(draft.data.filter);
    }
  }, [draft]);
  
  const handleApplyClick = () => {
    if (draft.status === 'success') {
      setDraft(viewId, {
        ...draft.data,
        filter: cleanupCostViewFilter(currentValue)
      });

      logEvent('apply_filter', { 'view': 'cost_view' })
    }
  }

  const handleCleanClick = () => {
    if (costViewConstructor.status === 'success') {
      setCurrentValue(parseCostViewFilter({
        ...currentValue,
        filter: {
          operator: 'or',
          condition: []
        }
      }, costViewConstructor.data));

      logEvent('clear_filter', { 'view': 'cost_view' }) 
    }
  };

  const immutableValue = useMemo(() => toImmutableCostViewFilter(draft.status === 'success' ? draft.data.filter : EMPTY_FILTER), [draft]);
  const immutableCurrentValue = useMemo(() => toImmutableCostViewFilter(currentValue), [currentValue]);

  const empty = currentValue.filter.condition.length === 0;
  const changed = immutableCurrentValue.toString() !== immutableValue.toString();

  const valid = useMemo(
    () =>
      costViewConstructor.status === 'success' &&
      validateCostViewFilter(currentValue, costViewConstructor.data),
    [currentValue, costViewConstructor]
  );

  const deps = useDerivedDataEntry({
    draft,
    costViewConstructor,
    teamFilters,
    teamFilterConstructor
  }, identity);

  return (
    <Section className='relative flex flex-col grow'>
      <Section.Head wfull className='justify-between'>
        <SectionTitle>
          Filters
        </SectionTitle>

        {costViewConstructor.status === 'success' && (
          <SourceControl
            value={currentValue.src as Source}
            options={Object.keys(costViewConstructor.data)}
            onChange={(src) => {
              if (costViewConstructor.status === 'success') {
                setCurrentValue(parseCostViewFilter({
                  ...currentValue,
                  src,
                }, costViewConstructor.data));
              }
            }}
          />
        )}
      </Section.Head>

      {setTeamId && (
        <TeamFilterSelect
          value={teamId}
          onChange={setTeamId}
        />
      )}

      <div className='px-5 pb-5 overflow-auto basis-0 grow shrink no-scrollbar'>
        <PortalRoot>
          {(
            deps.status === 'success'
          ) && (
            <CostViewFilters
              value={currentValue}
              onChange={setCurrentValue}
              costViewConstructor={deps.data.costViewConstructor}
              teamFilters={deps.data.teamFilters}
              teamFilterConstructor={deps.data.teamFilterConstructor}
            />
          )}
        </PortalRoot>
      </div>

      <div className='px-5 py-2 border-t grid grid-cols-2 gap-2'>
        <Button
          size='m'
          theme='black'
          disabled={empty}
          onClick={handleCleanClick}
        >
          Clear All
        </Button>

        <Button
          size='m'
          theme='filled'
          disabled={!changed || !valid}
          onClick={handleApplyClick}
        >
          Apply
        </Button>
      </div>

      <StatusOverlay status={deps.status} />
    </Section>
  );
}
