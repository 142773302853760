import type { DataEntry } from 'types/dataEntries';
import type { Source } from 'types/unitMetrics';
import type { CostViewRequestFilter } from 'types/costViews';

import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Page } from 'shared/Page';
import { DateFilter } from 'shared/DateFilter';
import { CostFilter } from 'shared/CostFilter';
import { FilterButton } from 'shared/FilterButton';
import { getSourceTitle } from 'shared/Filters';

import {
  PageTitle,
  TotalSection,
  FiltersSection,
  BreakdownSection,
  DrilldownSection,
  TagAnalyzerSection,
  MoversAndShakers
} from './content';

import { TwoRowLayout } from './layout';

import {
  SaveViewDialog,
  DeleteViewDialog,
  ComparisonDialog
} from './dialogs';

import {
  DeleteViewButton,
  UpdateViewButton,
  CreateViewButton,
  AlertButton,
  ReportButton,
  AddToDashboardButton
} from './controls';

import { validateCostViewFilter } from 'helpers/costViews';

import { useCostOverviewStore } from './useCostOverviewStore'

import { useDatesQuery } from 'hooks/useDatesQuery';
import { useCostFilterValue } from 'hooks/costFilter';
import { useDrillDownQuery } from 'hooks/useDrillDownQuery';

import { useGlobalState } from 'state/globalState';
import { useCostViewChart } from 'store/costLab';
import { useEventStore } from 'store/events';
import { useCostViewConstructor, useCostViewsStore, useCostViewDrillDown } from 'store/costLab';

import { useTeamId } from 'hooks/teamId';
import { useTeamFilters } from 'hooks/teamFilters';

import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useCumulative } from './hooks/cumulative';
import { useBooleanQueryParam } from 'hooks/queryParams';

import { identity } from 'helpers/utils';
import {AddToDashboardDialog} from './dialogs/AddToDashboardDialog';

const CostsOverview = () => {
  const params = useParams<{id?: string}>()
  const id = params.id ? +params.id : 'new';
  const costViewConstructor = useCostViewConstructor();
  const draft = useCostViewsStore((store) => store.getDraft(id, costViewConstructor));
  const discardDraft = useCostViewsStore((store) => store.discardDraft);

  useEffect(() => {
    return () => {
      discardDraft(id);
    };
  }, [id]);

  const {
    startDate,
    endDate,
    granularity,
    setDatesQuery
  } = useDatesQuery();

  const costFilterValue = useCostFilterValue();

  const {
    breakdown,
    drillDownSteps,
    availableSteps,
    availableBreakdowns,
    setCurrentStep,
    setBreakdown,
    swapStepField,
    swapStepValue,
    removeDrillDown,
    rollbackDrillDown
  } = useDrillDownQuery();

  const {
    drillDownOpened,
    setDrillDownOpened
  } = useCostOverviewStore();

  const [cumulative, setCumulative] = useCumulative();

  const {
    events,
    eventTypes,
    selectedEventTypes,
    fetchEvents,
    fetchEventTypes,
    setEventTypes,
  } = useEventStore();

  useEffect(() => {
    setDrillDownOpened(drillDownSteps.length > 0);
  }, [drillDownSteps]);

  const { user, collapseSideMenu } = useGlobalState();
  const logEvent = useGlobalState((state) => state.logEvent);
  const [filtersOpened, setFiltersOpened] = useBooleanQueryParam('filtersOpened');

  const [teamId, onTeamIdChange] = useTeamId();
  const teamFilters = useTeamFilters(teamId);

  const handleFiltersClick = () => {
    setFiltersOpened(!filtersOpened)

    if (!filtersOpened) {
      collapseSideMenu()
    }

    logEvent('open_filters', { 'view': 'cost_view', 'state': !filtersOpened });
  }

  const filterEntry = useDerivedDataEntry({
    draft,
    costViewConstructor
  },({
    draft,
    costViewConstructor
  }) => {
    if (!validateCostViewFilter(draft.filter, costViewConstructor)) {
      throw new Error('');
    }

    return draft.filter;
  });

  const cumulativeGranularity = granularity === 'quarter' ? 'quarter' : 'month'
  const today = useMemo(() => moment(), []);

  const startOfPeriod = useMemo(() => endDate.clone().startOf(cumulativeGranularity), [endDate, cumulativeGranularity]);
  const endOfPeriod = useMemo(() => endDate.clone().endOf(cumulativeGranularity), [endDate, cumulativeGranularity]);

  const startDateAdjusted = cumulative ?
    startOfPeriod :
    startDate;

  const endDateAdjusted = cumulative ?
    endOfPeriod  :
    endDate.isAfter(today) ? today : endDate;

  const granularityAdjusted = cumulative ? 'day' : granularity;

  const prevStartDateAdjusted = cumulative ? 
    startDateAdjusted.clone().subtract(1, 'day').startOf(cumulativeGranularity) :
    startDateAdjusted.clone().subtract(endDateAdjusted.diff(startDateAdjusted, granularity) + 1, granularity)

  const prevEndDateAdjusted = cumulative ? 
    startDateAdjusted.clone().subtract(1, 'day') :
    endDateAdjusted.clone().subtract(endDateAdjusted.diff(startDateAdjusted, granularity) + 1, granularity)

  const startDateStr = startDateAdjusted.format('YYYY-MM-DD');
  const endDateStr = endDateAdjusted.format('YYYY-MM-DD');

  const prevStartDateStr = prevStartDateAdjusted.format('YYYY-MM-DD');
  const prevEndDateStr = prevEndDateAdjusted.format('YYYY-MM-DD');

  useEffect(() => {
    fetchEvents({
      startDate: startDateAdjusted.format('YYYY-MM-DD'),
      endDate: endDateAdjusted.format('YYYY-MM-DD')
    });
    fetchEventTypes({
      startDate: startDateAdjusted.format('YYYY-MM-DD'),
      endDate: endDateAdjusted.format('YYYY-MM-DD')
    });
  }, [startDate, endDate]);

  const requestFilter: DataEntry<CostViewRequestFilter> = useDerivedDataEntry(
    { filterEntry, teamFilters, teamId, onTeamIdChange },
    ({ filterEntry, teamFilters, teamId, onTeamIdChange }) => {
      if (teamId && onTeamIdChange) {
        return {
          src: filterEntry.src,
          filter: {
            operator: 'and',
            condition: [
              teamFilters[filterEntry.src as Source].filter,
              filterEntry.filter 
            ]
          }
        };
      }

      return filterEntry;
    }
  );

  const chartParams = useDerivedDataEntry({
    ...costFilterValue,
    startDate: startDateStr,
    endDate: endDateStr,
    granularity: granularityAdjusted,
    filter: requestFilter
  }, identity);

  const chartDataEntry = useCostViewChart(chartParams);

  const prevChartParams = useDerivedDataEntry({
    ...costFilterValue,
    startDate: prevStartDateStr,
    endDate: prevEndDateStr,
    granularity: granularityAdjusted,
    filter: requestFilter
  }, identity);

  const prevChartDataEntry = useCostViewChart(prevChartParams);

  const drillDownParams = useDerivedDataEntry({
      ...costFilterValue,
      startDate: startDateStr,
      endDate: endDateStr,
      granularity: granularityAdjusted,
      breakdown,
      drillDownSteps,
      filter: requestFilter
  }, identity);

  const drillDownEntry = useCostViewDrillDown(drillDownParams);

  const isCUR = filterEntry.status === 'success' && filterEntry.data.src === 'cur';
  const isFilterSaved = typeof id === 'number'

  return (
    <Page className='pb-[60px]'>
      <Page.Head
        title={<PageTitle viewId={id} />}
        subtitle={filterEntry.status === 'success' && `Source: ${getSourceTitle(filterEntry.data.src)}`}
      >
        <div className='flex items-end ml-5 gap-4'>
          {isFilterSaved && <>
            <DeleteViewButton viewId={id} />
            <UpdateViewButton viewId={id} />
          </>}

          {user?.org_is_data_ready && (
            <CreateViewButton />
          )}

          {isFilterSaved && <>
            <AddToDashboardButton viewId={id} />
          </>}

          {isFilterSaved && isCUR && <>     
            <AlertButton viewId={id} />
            <ReportButton viewId={id} />
          </>}
        </div>

        <div className='flex items-center justify-end min-w-0 ml-auto gap-4 shrink'>
          {user?.org_is_data_ready && isCUR &&
            <CostFilter />
          }

          <DateFilter
            withCumulative
            cumulative={cumulative}
            startDate={startDateAdjusted}
            endDate={endDateAdjusted}
            granularity={cumulative ? cumulativeGranularity : granularity}
            onChange={(params) => {
              setDatesQuery(params);

              if (params.granularity !== granularityAdjusted) {
                logEvent('set_granularity', { 'view': 'cost_view', 'granularity': params.granularity });
              }

              if (params.startDate !== startDateAdjusted || params.endDate !== endDateAdjusted) {
                logEvent('set_period', { 'view': 'cost_view' });
              }
            }}
            onCumulativeChange={() => {
              setCumulative(!cumulative);
            }}
          />

          <FilterButton
            checked={filtersOpened}
            onClick={handleFiltersClick}
          />
        </div>
      </Page.Head>

      <TwoRowLayout
        topLeft={(
          <TotalSection
            viewId={id}
            className='mb-4 grow'
            chartDataEntry={chartDataEntry}
            prevChartDataEntry={prevChartDataEntry}
            startMoment={startDateAdjusted}
            endMoment={endDateAdjusted}
            prevStartMoment={prevStartDateAdjusted}
            prevEndMoment={prevEndDateAdjusted}
            events={events}
            eventTypes={eventTypes}
            selectedEventTypes={selectedEventTypes}
            setEventTypes={setEventTypes}
            granularity={cumulative ? cumulativeGranularity : granularity}
            requestFilter={requestFilter}
          />
        )}
        topRight={
          <FiltersSection viewId={id} />
        }
        bottomLeft={
          isCUR ? (
            <div className='overflow-hidden'>
              <BreakdownSection
                startMoment={startDateAdjusted}
                endMoment={endDateAdjusted}
                prevStartMoment={prevStartDateAdjusted}
                prevEndMoment={prevEndDateAdjusted}
                granularity={granularityAdjusted}
                drillDown={drillDownEntry}
                drillDownEnabled={drillDownSteps.length > 0}
                requestFilter={requestFilter}
              />

              <TagAnalyzerSection
                startDate={startDateStr}
                endDate={endDateStr}
                startMoment={startDateAdjusted}
                endMoment={endDateAdjusted}
                prevStartMoment={prevStartDateAdjusted}
                prevEndMoment={prevEndDateAdjusted}
                granularity={granularityAdjusted}
                requestFilter={requestFilter}
              />
            </div>
          ) : null
        }
        bottomRight={
          isCUR ? (
            <DrilldownSection
              className='min-h-0 shrink'
              startDate={startDateStr}
              endDate={endDateStr}
              granularity={granularityAdjusted}
              filter={filterEntry.data}
              drillDown={drillDownEntry}
              breakdown={breakdown}
              drillDownSteps={drillDownSteps}
              availableSteps={availableSteps}
              availableBreakdowns={availableBreakdowns}
              setCurrentStep={setCurrentStep}
              setBreakdown={setBreakdown}
              swapStepField={swapStepField}
              swapStepValue={swapStepValue}
              removeDrillDown={removeDrillDown}
              rollbackDrillDown={rollbackDrillDown}
            />
          ) : null
        }
        topRightVisible={filtersOpened}
        bottomRightVisible={drillDownOpened}
      />

      {(
        user?.org_is_data_ready &&
        isCUR
      ) && (
        <MoversAndShakers
          id={id}
          filter={filterEntry.data}
          startDate={startDateStr}
          endDate={endDateStr}
          startMoment={startDateAdjusted}
          endMoment={endDateAdjusted}
          prevStartMoment={prevStartDateAdjusted}
          prevEndMoment={prevEndDateAdjusted}
          granularity={granularityAdjusted}
        />
      )}

      <SaveViewDialog viewId={id} />
      <AddToDashboardDialog />
      <DeleteViewDialog />
      <ComparisonDialog />
    </Page>
  )
}

export default CostsOverview;
