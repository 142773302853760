import type {
  DashboardLibraryEntry,
  Dashboard,
  DashboardDraft
} from 'types/dashboards';

import api from 'helpers/api';

export const fetchDashboards = () => api.get<DashboardLibraryEntry[]>('/dashboards');
export const fetchDashboard = (id: number) => api.get<Dashboard>(`/dashboards/${id}`);
export const createDashboard = (dashboard: DashboardDraft) => api.post<Dashboard>(`/dashboards/`, dashboard);
export const updateDashboard = (id: number, dashboard: DashboardDraft) => api.patch<Dashboard>(`/dashboards/${id}`, dashboard);
export const deleteDashboard = (id: number) => api.delete<void>(`/dashboards/${id}`);
