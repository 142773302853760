import type { Widget } from 'types/dashboards';

import { Link, useParams, useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { Page } from 'shared/Page';
import { Icon } from 'shared/Icon';
import { CostFilter } from 'shared/CostFilter';
import { DateFilter } from 'shared/DateFilter';
import { EditableTitle } from 'shared/EditableTitle';
import { StatusOverlay } from 'shared/Overlay';
import { WidgetBlock } from './Widget';
import { AddWidgetButton } from './AddWidgetButton';
import { ActionButtons } from '../UnitMetric/ActionButtons';
import { CopyWidgetDialog, DeleteDialog } from './dialogs';

import { useDashboardsStore } from 'store/dashboards';
import { useDatesQuery } from 'hooks/useDatesQuery';

export const DashboardPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    startDate,
    endDate,
    granularity,
    setDatesQuery
  } = useDatesQuery();

  const key: number | string = id ? +id : 'new';

  const isNew = typeof key === 'string';
  const entry = useDashboardsStore((store) => isNew ? null : store.getEntry(key));
  const draft = useDashboardsStore((store) => store.getDraft(key));

  const createDashboard = useDashboardsStore((store) => store.createEntry);
  const updateDashboard = useDashboardsStore((store) => store.updateEntry);
  const deleteDashboard = useDashboardsStore((store) => store.deleteEntry);

  const setDraft = useDashboardsStore((store) => store.setDraft);

  const actionInProgress = !!useDashboardsStore((store) => store.actions[key]);

  const setWidgets = (widgets: Widget[]) => {
    if (draft.status === 'success') {
      setDraft(key, { ...draft.data, widgets });
    }
  };

  const handleDelete = () => {
    if (entry && entry.status === 'success') {
      deleteDashboard(entry.data.id)
        .then(() => {
          navigate(`/dashboards/library`);
          message.success(<>Dashboard <b>{entry.data.name}</b> deleted</>);
        })
        .catch(() => {
          message.error(<>Cannot delete dashboard <b>{entry.data.name}</b></>);
        })
    }
  };

  const handleUpdate = () => {
    if (!isNew && draft.status === 'success') {
      updateDashboard(key)
        .then(() => {
          message.success(<>Dashboard <b>{draft.data.name}</b> saved</>);
        })
        .catch(() => {
          message.error(<>Cannot save <b>{draft.data.name}</b></>);
        });
    }
  };

  const handleCreate = () => {
    createDashboard(key)
      .then(({ id, name }) => {
        message.success(<>Dashboard <b>{name}</b> created</>);
        navigate(`/dashboards/${id}`);
      })
      .catch(() => {
        message.error(`Cannot create dashboard`);
      })
  }

  return (
    <Page>
      <Page.Head
        title={
          <>
            <Link
              to='/dashboards/library'
              className='text-blue hover:text-blue-hover'
            >
              Dashboards
            </Link>

            <Icon icon='arrow-right' className='mx-2'/>

            {draft.status === 'success' ?
              <EditableTitle
                value={draft.data.name}
                placeholder='Enter dashboard name'
                onChange={(name) => { setDraft(key, { ...draft.data, name }); }}
              /> :
              'Loading'
            }
          </>
        }
      >
        <ActionButtons
          del={{
            disabled: actionInProgress,
            available: !isNew,
            onClick: handleDelete
          }}
          save={{
            available: !isNew,
            disabled: !entry ||
              entry.status !== 'success' ||
              draft.status !== 'success' ||
              draft.data.name === entry.data.name && draft.data.widgets === entry.data.widgets ||
              draft.data.widgets.length === 0 ||
              draft.data.name.length === 0 ||
              actionInProgress,
            onClick: handleUpdate
          }}
          saveAsNew={{
            disabled: draft.status !== 'success' ||
              draft.data.widgets.length === 0 ||
              draft.data.name.length === 0 ||
              actionInProgress,
            onClick: handleCreate
          }}
          newAlert={{ available: false }}
          newReport={{ available: false }}
        />
        <div className='flex items-center ml-auto gap-4'>
          <CostFilter />
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            granularity={granularity}
            onChange={setDatesQuery}
          />
        </div>
      </Page.Head>

      <div className='relative grid grid-cols-12 gap-4'>
        {draft.status === 'success' && draft.data.widgets.map((widget, index) => (
          <WidgetBlock
            key={index}
            widget={widget}
            onChange={(newWidget) => {
              const newWidgets = [...draft.data.widgets];

              newWidgets[index] = newWidget;

              setWidgets(newWidgets);
            }}
            onRemove={() => {
              const newWidgets = [...draft.data.widgets];

              newWidgets.splice(index, 1);

              setWidgets(newWidgets);
            }}
          />
        ))}

        <AddWidgetButton
          onClick={() => {
            if (draft.status === 'success') {
              setWidgets([
                ...draft.data.widgets,
                {
                  type: null,
                  settings: {}
                }
              ]);
            }
          }}
        />

        <StatusOverlay status={draft.status} />
        <DeleteDialog />
      </div>

      <CopyWidgetDialog />
    </Page>
  );
};
