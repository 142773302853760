import { IconButton } from 'shared/Button';
import { useComparisonIds } from '../hooks';
import { useAddToDashboardDialog } from 'store/costLab';

export interface AddToDashboardButtonProps {
  viewId: number;
  disabled?: boolean;
}

export const AddToDashboardButton = (props: AddToDashboardButtonProps) => {
  const {
    viewId,
  } = props;

  const [comparisonIds] = useComparisonIds();
  const openAddToDashboardDialog = useAddToDashboardDialog((store) => store.open);

  return (
    <IconButton
      className='text-[10px] shrink-0'
      icon='service'
      geometry='circle'
      hoverStyle='normal'
      type='tertiary'
      size='xs'
      direction='column'
      onClick={() => {
        openAddToDashboardDialog([viewId, ...comparisonIds]);
      }}
    >
      To Dashboard
    </IconButton>
  );
}
