import { Widget, EmptyWidget, WidgetType } from 'types/dashboards';

import { useState } from 'react';
import { Select } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { Section } from 'shared/Section';

export interface EmptyWidgetBlockProps {
  widget: EmptyWidget;
  onChange: (widget: Widget) => void;
  onRemove: () => void;
}

const DEFAULT_WIDGETS: Record<WidgetType, Widget> = {
  'cost-view-chart': {
    type: 'cost-view-chart',
    width: 6,
    settings: {
      viewId: null,
      granularity: 'day',
      duration: 7,
      costType: 'unblended_cost',
      costDimensions: [],
      costAmortization: false
    }
  },
  'unit-metric-chart': {
    type: 'unit-metric-chart',
    width: 6,
    settings: {
      metricId: null,
      granularity: 'day',
      duration: 7,
      costType: 'unblended_cost',
      costDimensions: [],
      costAmortization: false
    }
  },
  'multi-cost-view-chart': {
    type: 'multi-cost-view-chart',
    width: 6,
    settings: {
      viewIds: [],
      granularity: 'day',
      duration: 7,
      costType: 'unblended_cost',
      costDimensions: [],
      costAmortization: false
    }
  },
}

export const EmptyWidgetBlock = (props: EmptyWidgetBlockProps) => {
  const {
    widget,
    onChange,
    onRemove
  } = props;

  const [widgetType, setWidgetType] = useState<WidgetType | null>(widget.type);

  return (
    <Section
      className='flex items-center justify-center h-[320px] py-8 gap-3 col-span-6'     
    >
      <Select
        className='ml-8 grow'
        value={widgetType}
        onChange={setWidgetType}
        placeholder='Widget Type'
        options={[
          {
            value: 'cost-view-chart',
            label: 'Cost View Chart'
          },
          {
            value: 'unit-metric-chart',
            label: 'Unit Metric Chart'
          },
          {
            value: 'multi-cost-view-chart',
            label: 'Cost View Comparison Chart'
          },
        ]}
      />

      <Button
        disabled={widgetType === null}
        onClick={() => {
          if (widgetType !== null) {
            onChange(DEFAULT_WIDGETS[widgetType]);
          }
        }}
      >
        Add
      </Button>

      <Button
        theme='bordered'
        className='mr-8'
        onClick={onRemove}
      >
        Cancel
      </Button>
    </Section>
  );
};
