import {Checkbox, InputNumber, Select} from 'antd';
import {FormRow, Label} from 'shared/Form';
import { Granularity, Setter } from 'types/common';

interface Value {
  dashboardDates?: boolean;
  duration: number | null;
  granularity: Granularity;
}

export interface DateSettingsControlProps {
  value: Value;
  onChange: Setter<Value>;
}

export const DateSettingsControl = (props: DateSettingsControlProps) => {
  const {
    value,
    onChange
  } = props;

  const {
    dashboardDates,
    duration,
    granularity
  } = value;

  return <>
    <FormRow size='s'>
      <Checkbox
        checked={!!dashboardDates}
        onChange={(event) => {
          onChange({
            ...value,
            dashboardDates: event.target.checked
          });
        }}
      >
        Use dashboard date settings
      </Checkbox>
    </FormRow>

    <div className='grid grid-cols-2 gap-4'>
      <FormRow size='s'>
        <Label size='s'>
          {`Duration (${granularity})`}
        </Label>

        <InputNumber
          disabled={dashboardDates}
          value={duration}
          onChange={(duration) => {
            onChange({
              ...value,
              duration
            });
          }}
        />
      </FormRow>

      <FormRow size='s'>
        <Label size='s'>
          Granularity
        </Label>

        <Select
          className='w-full'
          value={granularity}
          disabled={dashboardDates}
          options={[
            { value: 'day', label: 'Day' },
            { value: 'week', label: 'Week' },
            { value: 'month', label: 'Month' },
            { value: 'quarter', label: 'Quarter' },
            { value: 'year', label: 'Year' }
          ]}
          onChange={(granularity) => {
            onChange({
              ...value,
              granularity
            });
          }}
        />
      </FormRow>
    </div>
  </>;
}
