import type { Moment } from 'moment';
import type { Granularity } from 'types/common';
import { SERVER_DATE_FORMAT } from 'helpers/constants';
import { useCostFilterValue } from 'hooks/costFilter';
import { useCostViewChartStore, useCostViewForecastStore, useCostViewsStore } from 'store/costLab';
import { useQueryParam } from 'hooks/queryParams';
import {minDate} from 'helpers/date';
import moment from 'moment';

export const useComparisonIds = () => useQueryParam({
  name: 'comparisonIds',
  parse: (param: string | null) => param ? param.split(',').map((part) => +part) : [],
  serialize: (value) => value.length ? value.join(',') : null
});

export const useComparison = (ids: number[], params: {
  startDate: Moment,
  endDate: Moment,
  granularity: Granularity,
  cumulative?: boolean,
  forecastStartDate: Moment,
  forecastEndDate: Moment,
}) => {
  const costFilter = useCostFilterValue();
  const {
    startDate,
    endDate,
    granularity,
    cumulative,
    forecastStartDate,
    forecastEndDate
  } = params;

  const views = useCostViewsStore((store) => ids.map(store.getEntry));
  const charts = useCostViewChartStore((store) => views.map((view) => {
    if (view.status !== 'success') {
      return view;
    }

    return store.getEntry({
      ...costFilter,
      startDate: startDate.format(SERVER_DATE_FORMAT),
      endDate: minDate(endDate, moment()).format(SERVER_DATE_FORMAT),
      granularity,
      filter: view.data.filter
    });
  }));

  const forecasts = useCostViewForecastStore((store) => cumulative ? views.map((view) => {
    if (view.status !== 'success') {
      return view;
    }

    return store.getEntry({
      ...costFilter,
      startDate: startDate.format(SERVER_DATE_FORMAT),
      endDate: endDate.format(SERVER_DATE_FORMAT),
      forecastStartDate: forecastStartDate.format(SERVER_DATE_FORMAT),
      forecastEndDate: forecastEndDate.format(SERVER_DATE_FORMAT),
      granularity,
      filter: view.data.filter
    });
  }) : []);

  return views.map((view, index) => {
    const chart = charts[index];
    const forecast = forecasts[index];

    return {
      label: view.status === 'success' ? view.data.name : 'Loading...',
      color: `hsla(${100 * index % 360}, 50%, 50%)`,
      forecastColor: `hsla(${100 * index % 360}, 50%, 50%, 50%)`,
      data: chart.status === 'success' ? chart.data : [],
      forecast: forecast?.status === 'success' ? forecast.data.data : []
    };
  });
}
