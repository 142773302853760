export type DraftKey = 'new';
export const fixedDraftKey = 'new';

export interface IdleDataEntry {
  status: 'idle';
}

export interface LoadingDataEntry {
  status: 'loading';
}

export interface SuccessDataEntry<TData = any> {
  data: TData;
  status: 'success';
}

export interface ErrorDataEntry<TError = any> {
  error: TError;
  status: 'error';
}

export const IDLE_ENTRY: IdleDataEntry = { status: 'idle' };
export const LOADING_ENTRY: LoadingDataEntry = { status: 'loading' };

export type DataEntry<TData = any, TError = any> = IdleDataEntry | LoadingDataEntry | SuccessDataEntry<TData> | ErrorDataEntry<TError>;
export type OptDataEntry<TData = any, TError = any> = TData | DataEntry<TData, TError>;

export const isLoading = <TData, TError = any>(entry: DataEntry<TData, TError>): entry is LoadingDataEntry => entry.status === 'loading';
export const isSuccess = <TData = any, TError = any>(entry: DataEntry<TData, TError>): entry is SuccessDataEntry<TData> => entry.status === 'success';
export const isError = <TData = any, TError = any>(entry: DataEntry<TData, TError>): entry is ErrorDataEntry<TError> => entry.status === 'error';

export const isDataEntry = (entry: any): entry is DataEntry => {
  if (!entry || typeof entry !== 'object') {
    return false;
  }

  const keys = Object.keys(entry);

  return (
    entry.status === 'idle' && keys.length === 1 ||
    entry.status === 'loading' && keys.length === 1 ||
    entry.status === 'success' && keys.length === 2 && keys.includes('data') ||
    entry.status === 'error' && keys.length === 2 && keys.includes('error')
  );
}

export interface NewCRUDEntry<TData = any> {
  data: TData;
  status: 'new';
}

export interface CreatingCRUDEntry<TData = any> {
  data: TData;
  status: 'creating';
}

export interface CreatedCRUDEntry<TData = any> {
  data: TData;
  status: 'created';
}

export interface CreateErrorCRUDEntry<TData = any, TError = any> {
  status: 'create-error';
  data: TData;
  error: TError;
}

export interface UpdatingCRUDEntry<TData = any> {
  status: 'updating';
  data: TData;
}

export interface UpdatedCRUDEntry<TData = any> {
  status: 'updated';
  data: TData;
}

export interface UpdateErrorCRUDEntry<TData = any, TError = any> {
  status: 'update-error';
  data: TData;
  error: TError;
}

export interface DeletingCRUDEntry<TData = any> {
  status: 'deleting';
  data: TData;
}

export interface DeletedCRUDEntry<TData = any> {
  status: 'deleted';
  data: TData;
}

export interface DeleteErrorCRUDEntry<TData = any, TError = any> {
  status: 'delete-error';
  data: TData;
  error: TError;
}

export type CRUDEntry<
  TData = any,
  TNewData = TData,

  TError = any,
  TCreateError = TError,
  TUpdateError = TError,
  TDeleteError = TError
> = DataEntry<TData, TError> |
  NewCRUDEntry<TNewData> |

  CreatingCRUDEntry<TNewData> |
  CreatedCRUDEntry<TNewData> |
  CreateErrorCRUDEntry<TNewData, TCreateError> |

  UpdatingCRUDEntry<TData> |
  UpdatedCRUDEntry<TData> |
  UpdateErrorCRUDEntry<TData, TUpdateError> |

  DeletingCRUDEntry<TData> |
  DeletedCRUDEntry<TData> |
  DeleteErrorCRUDEntry<TData, TDeleteError>;


export interface RemovingListEntry<TData = any> {
  status: 'removing';
  data: TData;
}

export interface RemovedListEntry<TData = any> {
  status: 'removed';
  data: TData;
}

export interface RemoveErrorListEntry<TData = any, TError = any> {
  status: 'remove-error';
  data: TData;
  error: TError;
}

export interface AddingListEntry<TData = any> {
  status: 'adding';
  data: TData;
}

export interface AddedListEntry<TData = any> {
  status: 'added';
  data: TData;
}

export interface AddErrorListEntry<TData = any, TError = any> {
  status: 'add-error';
  data: TData;
  error: TError;
}

export interface LoadedListEntry<TData = any> {
  status: 'loaded';
  data: TData;
}

export type ListEntry<TData = any, TAddError = any, TRemoveError = TAddError> = LoadedListEntry<TData> |
  AddingListEntry<TData> | AddedListEntry<TData> | AddErrorListEntry<TData, TAddError> |
  RemovingListEntry<TData> | RemovedListEntry<TData> | RemoveErrorListEntry<TData, TRemoveError>;

interface ActionIdle<TActionType extends string> {
  type: TActionType;
  status: 'idle';
}

interface ActionInProgress<TActionType extends string> {
  type: TActionType;
  status: 'in-progress';
}

interface ActionError<TActionType extends string, TError = any> {
  type: TActionType;
  status: 'error';
  error: TError;
}

interface ActionSuccess<TActionType extends string, TResult> {
  type: TActionType;
  status: 'success';
  result: TResult;
}

export type Action<TActionType extends string, TError = any, TResult = any> = 
  ActionIdle<TActionType> |
  ActionInProgress<TActionType> |
  ActionSuccess<TActionType, TResult> |
  ActionError<TActionType, TError>;

export interface EditEntry<TData, TUpdatePayload, TAction> {
  source: TData;
  draft: TUpdatePayload;
  action?: TAction;
}

export interface CreateEntry<TCreatePayload, TAction> {
  key: string;
  draft: TCreatePayload;
  action?: TAction;
}


export type DataEntryType<T> = T extends DataEntry<infer D> ? D : never;

export type Resolved<Deps extends {}> = {
  [key in keyof Deps]: DataEntryType<Deps[key]>
}
