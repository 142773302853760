import { IconButton } from 'shared/Button';
import { useNavigate } from 'react-router-dom';

export interface ReportButtonProps {
  viewId: number;
}

export const ReportButton = (props: ReportButtonProps) => {
  const { viewId } = props;
  const navigate = useNavigate();

  return (
    <IconButton
      className='text-[10px] shrink-0 w-9'
      icon='notes'
      geometry='circle'
      hoverStyle='normal'
      type='tertiary'
      size='xs'
      direction='column'
      onClick={() => {
        navigate(`/notification-center?new_view_report=${viewId}`);
      }}
    >
      Report
    </IconButton>
  );
}
