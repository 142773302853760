import type { Record as ImmutableRecord } from 'immutable';
import type {
  Filter,
  FilterCondition,
  FilterGroupCondition,
  ImmutableFilterCondition
} from 'types/filters';
import type { TeamFilterCondition } from 'types/teams';

export interface CostViewFilterItem extends FilterGroupCondition {
  operator: 'and';
  condition: FilterCondition[];
}

export interface CostViewFilterCondition extends FilterGroupCondition {
  operator: 'or';
  condition: CostViewFilterItem[];
}

export interface CostViewFilter extends Filter {
  filter: CostViewFilterCondition;
}

export interface CostViewFilterWithTeam extends Filter {
  filter: {
    operator: 'and',
    condition: [
      TeamFilterCondition,
      CostViewFilterCondition,
    ]
  }
}

export type CostViewRequestFilter = CostViewFilter | CostViewFilterWithTeam;

export type ImmutableCostViewFilter = ImmutableRecord<{
  src: string
  filter: ImmutableFilterCondition;
}>;

export const EMPTY_FILTER: CostViewFilter = {
  src: 'cur',
  filter: {
    operator: 'or',
    condition: []
  }
};
