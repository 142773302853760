import type { Setter } from 'types/common';
import type { MultiCostViewChartWidget } from 'types/dashboards';

import moment from 'moment';
import { useState } from 'react';
import { Select } from 'antd';

import { FormRow, Label } from 'shared/Form';
import { SectionTitle } from 'shared/Section';
import { WidgetWrapper } from './WidgetWrapper';

import { TimelineChart } from 'shared/TimelineChart';
import { CostAttrsControl, DateSettingsControl } from './controls';

import { formatCurrency } from 'helpers/formatter';
import { SERVER_DATE_FORMAT } from 'helpers/constants';

import { useCostViewCharts } from 'hooks/costViewCharts';
import { useCostFilterValue } from 'hooks/costFilter';
import { useDatesQuery } from 'hooks/useDatesQuery';
import { useCostViewsStore } from 'store/costLab';

export interface MultiCostViewChartWidgetBlockProps {
  widget: MultiCostViewChartWidget;
  onChange: Setter<MultiCostViewChartWidget>;
  onRemove: () => void;
}

export const MultiCostViewChartWidgetBlock = (props: MultiCostViewChartWidgetBlockProps) => { 
  const {
    widget,
    onChange,
    onRemove
  } = props;

  const {
    viewIds,
    dashboardCost,
    dashboardDates,
    duration,
  } = widget.settings;

  const library = useCostViewsStore((store) => store.getLibrary({
    startDate: moment().format(SERVER_DATE_FORMAT),
    endDate: moment().subtract(7, 'days').format(SERVER_DATE_FORMAT)
  }));

  const [editing, setEditing] = useState(viewIds.length > 0 || duration === null);

  const dashboardDateParams = useDatesQuery();
  const widgetDateParams = {
    endDate: moment().startOf('day'),
    startDate: moment().startOf('day').subtract(duration, widget.settings.granularity),
    granularity: widget.settings.granularity
  };

  const {
    startDate,
    endDate,
    granularity
  } = dashboardDates ? dashboardDateParams : widgetDateParams;

  const costFilterValue = useCostFilterValue();

  const {
    costType,
    costDimensions,
    costAmortization
  } = dashboardCost ? costFilterValue : widget.settings;

  const charts = useCostViewCharts(viewIds, {
    startDate,
    endDate,
    granularity,
    costType,
    costDimensions,
    costAmortization
  });

  return (
    <WidgetWrapper
      widget={widget}
      onChange={onChange}
      editing={editing}
      onEditingChange={setEditing}
      onRemove={onRemove}
      form={<div className='grid grid-cols-2 gap-5'>
        <div>
          <FormRow size='s'>
            <Label size='s'>
              Cost views
            </Label>

            <Select
              className='w-full'
              size='small'
              mode='tags'
              value={widget.settings.viewIds}
              loading={library.status === 'loading'}
              options={library.status === 'success' ? library.data.map((view) => ({
                value: '' + view.id,
                label: view.name
              })) : []}
              onChange={(viewIds) => {
                onChange({
                  ...widget,
                  settings: {
                    ...widget.settings,
                    viewIds
                  }
                });
              }}
            />
          </FormRow>
          
          <DateSettingsControl
            value={widget.settings}
            onChange={(settings) => {
              onChange({
                ...widget,
                settings: {
                  ...widget.settings,
                  ...settings
                }
              });
            }}
          />
        </div>

        <div>
          <CostAttrsControl
            value={widget.settings}
            onChange={(settings) => {
              onChange({
                ...widget,
                settings: {
                  ...widget.settings,
                  ...settings
                }
              });
            }}
          />
        </div>

      </div>}
    >
      <SectionTitle className='mt-4 ml-5'>
        Cost View Comparison
      </SectionTitle>

      <TimelineChart
        type='line'
        format={formatCurrency}
        startDate={startDate}
        endDate={endDate}
        granularity={granularity}
        series={charts}
      />

      <div className='flex px-5 pb-3 overflow-x-auto gap-4'>
        {charts.map(({ label, color }) => <div className='flex items-center gap-1'>
          <span className='inline-block w-2 h-2 rounded' style={{ background: color }} />
          {label}
        </div>)}
      </div>
    </WidgetWrapper>
  );
}
