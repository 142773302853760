import { message } from 'antd';
import { parseCostViewFilter, toImmutableCostViewFilter } from 'helpers/costViews';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { IconButton } from 'shared/Button';
import { useCostViewsStore, useCostViewConstructor } from 'store/costLab';

export interface UpdateViewButtonProps {
  viewId: number;
}

export const UpdateViewButton = (props: UpdateViewButtonProps) => {
  const {
    viewId
  } = props;

  const costViewConstructor = useCostViewConstructor();
  const view = useCostViewsStore((store) => store.getEntry(viewId));
  const draft = useCostViewsStore((store) => store.getDraft(viewId, costViewConstructor));
  const updateView = useCostViewsStore((store) => store.updateEntry);

  const initialImmutableFilter = useDerivedDataEntry({
    view,
    costViewConstructor
  }, ({
    view,
    costViewConstructor
  }) => {
    const filter = parseCostViewFilter(view.filter, costViewConstructor);
    const immutable = toImmutableCostViewFilter(filter);

    return immutable;
  });

  const currentImmutableFilter = useDerivedDataEntry({
    draft
  }, ({
    draft
  }) => toImmutableCostViewFilter(draft.filter));

  const changed = useDerivedDataEntry({
    view,
    draft,
    initialImmutableFilter,
    currentImmutableFilter,
  }, ({
    view,
    draft,
    initialImmutableFilter,
    currentImmutableFilter,
  }) =>
    view.name !== draft.name ||
    initialImmutableFilter.toString() !== currentImmutableFilter.toString()
  );

  return (
    <IconButton
      className='text-[10px] shrink-0 w-9'
      icon='save'
      geometry='circle'
      hoverStyle='positive'
      type='tertiary'
      size='sm'
      direction='column'
      disabled={changed.status !== 'success' || !changed.data}
      onClick={() => {
        if (view.status === 'success') {
          updateView(viewId)
            .then(() => {
              message.success(<>Costs view <b>{view.data.name}</b> saved</>);
            })
            .catch(() => {
              message.error(<>Unable to save <b>{view.data.name}</b></>);
            });
        }
      }}
    >
      Save
    </IconButton>
  );
}
