import create from 'zustand';

import type { Action, DataEntry } from 'types/dataEntries';
import type { Dashboard, DashboardDraft, DashboardLibraryEntry} from 'types/dashboards';

import {
  fetchDashboards,
  createDashboard,
  updateDashboard,
  deleteDashboard,
  fetchDashboard
} from 'services/dashboards';

import {
  createCreateEntry,
  createDeleteEntry,
  createDiscardDraft,
  createDiscardEntry,
  createFetchLibrary,
  createFinishAction,
  createGetDraft,
  createGetEntry,
  createGetLibrary,
  createSetDraft,
  createSetEntry,
  createStartAction,
  createUpdateEntry
} from 'store/create';
import { identity } from 'helpers/utils';

type DashboardActionType = 'create' | 'update' | 'delete';
type DraftAction = Action<DashboardActionType>;

export interface DashboardsStore {
  entries: Record<number, DataEntry<Dashboard>>;
  drafts: Record<number | string, DataEntry<DashboardDraft>>;
  actions: Record<number | string, DraftAction>;
  library: DataEntry<DashboardLibraryEntry[]>;

  fetchLibrary: () => void;
  getLibrary: () => DataEntry<DashboardLibraryEntry[]>;

  getEntry: (id: number) => DataEntry<Dashboard>;
  setEntry: (id: number, entry: DataEntry<Dashboard>) => void;
  discardEntry: (id: number) => void;

  createEntry: (id: number | string) => Promise<Dashboard>;
  updateEntry: (id: number) => Promise<Dashboard>;
  deleteEntry: (id: number) => Promise<void>;

  getDraft: (id: number | string) => DataEntry<DashboardDraft>;
  setDraft: (id: number | string, draft: DashboardDraft) => void;
  discardDraft: (id: number | string) => void;

  startAction: (id: number | string, actionType: DashboardActionType) => void;
  finishAction: (id: number | string) => void;
}

const NEW_DASHBOARD: DashboardDraft = {
  name: '',
  widgets: [],
};

const getId = ({ id }: Dashboard) => id;

export const useDashboardsStore = create<DashboardsStore>((set, get) => ({
  entries: {},
  drafts: {},
  actions: {},
  library: { status: 'idle' },

  startAction: createStartAction({ get, set }),
  finishAction: createFinishAction({ get, set }),

  fetchLibrary: createFetchLibrary({ get, set, fetchLibrary: fetchDashboards }),
  getLibrary: createGetLibrary({ get }),

  getEntry: createGetEntry({ get, set, fetchEntry: fetchDashboard }),
  setEntry: createSetEntry({ get, set }),
  discardEntry: createDiscardEntry({ get, set }),

  createEntry: createCreateEntry({
    get,
    getId,
    createActionType: 'create' as DashboardActionType,
    serializeDraft: identity<DashboardDraft>,
    createEntry: createDashboard
  }),

  updateEntry: createUpdateEntry({
    get,
    serializeDraft: identity<DashboardDraft>,
    updateEntry: updateDashboard,
    updateActionType: 'update' as const
  }),

  deleteEntry: createDeleteEntry({
    get,
    deleteEntry: deleteDashboard,
    deleteActionType: 'delete' as DashboardActionType
  }),

  getDraft: createGetDraft({
    get,
    emptyDraft: NEW_DASHBOARD,
    parseDraft: ({ name, widgets }: Dashboard): DashboardDraft => ({ name, widgets })
  }),

  setDraft: createSetDraft({ get, set }),
  discardDraft: createDiscardDraft({ get, set })
}));
