import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import { Header, BtnSocialAuth, InputField } from 'components/Auth'
import { validateEmail } from 'helpers/validator'
import { Tabs, Tab, Panes, Pane } from 'shared/Tabs';
import { SSOForm } from './SSOForm';
import { useGlobalState } from 'state/globalState'
import { API_HOST } from 'helpers/api'
import GoogleIcon from 'assets/images/Google.png'
import GithubIcon from 'assets/images/Github.png'

const Login = () => {
  const { login } = useGlobalState()
  const [searchParams] = useSearchParams()
  const [password, setPassword] = useState('')
  const [onceSubmitted, setOnceSubmitted] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [submitting, setSubmitting] = useState(false);

  const [currentTab, setCurrentTab] = useState<'normal' | 'sso'>('normal');

  const emailParam = searchParams.get('email')
  const [email, setEmail] = useState(emailParam ? emailParam : '')

  const isDisabledSubmit = !email || !password || submitting;

  const handleGoogleLogin = () => {
    window.location.href = `${API_HOST}/signin/google`
  }

  const handleGithubLogin = () => {
    window.location.href = `${API_HOST}/signin/github`
  }

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (e.target.value.length > 0 && onceSubmitted) {
      setEmailError('');
    }
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length > 0 && onceSubmitted) {
      setPasswordError('');
    }
  }

  const handleLogin =  async () => {
    if (isDisabledSubmit) return
    if (!onceSubmitted) {
      setOnceSubmitted(true)
    }

    if (!validateEmail(email)) {
      setEmailError('Invalid email format')
      return
    }

    if (password.length == 0) {
      setPasswordError('Password cannot be empty')
      return
    }

    setSubmitting(true);

    login(email, password)
      .catch((err: any) => {
        if (err.response.data.message === 'Domain exists as enabled SSO integration') {
          message.info('This domain has SSO enabled');
          setCurrentTab('sso');
        } else {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <div className="w-full min-h-screen bg-white md:bg-silver-grey pb-[100px]">
      <Header type='login' />
      <div className="mt-[30px] w-full md:w-[560px] mx-auto bg-white md:rounded-[20px] p-[50px] md:shadow-light-shadow">
        <div className='mb-[35px] shadow-border-b'>
          <Tabs value={currentTab} onChange={setCurrentTab}>
            <Tab value='normal'>
              <span className='font-semibold text-[24px]'>
                Sign In
              </span>
            </Tab>
            <Tab value='sso'>
              <span className='font-semibold text-[24px]'>
                Single Sign-On
              </span>
            </Tab>
          </Tabs>
        </div>
        <Panes value={currentTab}>
          <Pane value='normal'>
            <BtnSocialAuth classes='mb-[10px]' icon={GoogleIcon} text='Sign in with Google' onClick={handleGoogleLogin} />
            <BtnSocialAuth classes='mb-[30px]' icon={GithubIcon} text='Sign in with Github' onClick={handleGithubLogin} />
            <div className='flex items-center justify-between'>
              <div className='grow h-[1px] bg-silver-grey-600' />
              <span className='grow-0 mx-[15px] uppercase text-silver-grey-800 text-[12px] leading-[16px] tracking-[1px]'>
                Or Sign in with email
              </span>
              <div className='grow h-[1px] bg-silver-grey-600' />
            </div>

            <InputField label="Email" value={email} error={emailError} onChange={handleChangeEmail} />
            <InputField type="password" label="Password" value={password} error={passwordError} onChange={handleChangePassword} forgotPassword/>
            <button
              type="button"
              disabled={isDisabledSubmit}
              className='w-full h-[46px] mt-[30px] bg-caladon-blue rounded-[10px] font-medium text-[15px] leading-[22px] tracking-[-0.24px] text-white disabled:opacity-25'
              onClick={handleLogin}
            >
              Sign In
            </button>
            <div className='flex justify-center font-medium text-[11px] leading-[13px] tracking-[0.07px] mt-[11.5px]'>
              <span className='text-oxford-blue'>
                Don&apos;t have an account?&nbsp;
              </span>
              <Link className='text-caladon-blue hover:text-caladon-blue' to='/sign-up'>
                Create account now
              </Link>
            </div>
          </Pane>
          <Pane value='sso'>
            <SSOForm />
          </Pane>
        </Panes>
      </div>
    </div>
  );
};

export default Login;
