import type { CostViewChartWidget } from 'types/dashboards';
import { IDLE_ENTRY } from 'types/dataEntries';

import moment from 'moment';
import { Select } from 'antd';
import { useState } from 'react';

import { SectionTitle } from 'shared/Section';
import { PeriodChart } from 'shared/PeriodChart';
import { StatusOverlay } from 'shared/Overlay';
import { WidgetWrapper } from './WidgetWrapper';

import { SERVER_DATE_FORMAT } from 'helpers/constants';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

import { useCostViewChart, useCostViewsStore } from 'store/costLab';
import { FormRow, Label } from 'shared/Form';
import { useDatesQuery } from 'hooks/useDatesQuery';
import { useCostFilterValue } from 'hooks/costFilter';
import {CostAttrsControl, DateSettingsControl} from './controls';

export interface CostViewChartWidgetBlockProps {
  widget: CostViewChartWidget;
  onChange: (widget: CostViewChartWidget) => void;
  onRemove: () => void;
}

export const CostViewChartWidgetBlock = (props: CostViewChartWidgetBlockProps) => {
  const {
    widget,
    onChange,
    onRemove
  } = props;

  const {
    viewId,
    dashboardDates,
    dashboardCost,
    duration,
  } = widget.settings;

  const [editing, setEditing] = useState(!viewId);

  const library = useCostViewsStore((store) => store.getLibrary({
    startDate: moment().format(SERVER_DATE_FORMAT),
    endDate: moment().subtract(7, 'days').format(SERVER_DATE_FORMAT)
  }));
  
  const viewEntry = useCostViewsStore((store) => viewId ?
    store.getEntry(viewId) :
    IDLE_ENTRY
  );

  const dashboardDateParams = useDatesQuery();
  const widgetDateParams = {
    endDate: moment().startOf('day'),
    startDate: moment().startOf('day').subtract(duration, widget.settings.granularity),
    granularity: widget.settings.granularity
  };

  const {
    startDate,
    endDate,
    granularity
  } = dashboardDates ? dashboardDateParams : widgetDateParams;

  const costFilterValue = useCostFilterValue();

  const {
    costType,
    costDimensions,
    costAmortization
  } = dashboardCost ? costFilterValue : widget.settings;

  const chartParams = useDerivedDataEntry({
    startDate: startDate.format(SERVER_DATE_FORMAT),
    endDate: endDate.format(SERVER_DATE_FORMAT),
    granularity,
    costType,
    costDimensions,
    costAmortization,
    viewEntry
  }, ({
    viewEntry,
    ...rest
  }) => ({
    ...rest,
    filter: viewEntry.filter
  }));

  const chart = useCostViewChart(chartParams);


  return (
    <WidgetWrapper
      widget={widget}
      onChange={onChange}
      editing={editing}
      onEditingChange={setEditing}
      onRemove={onRemove}
      form={<div className='grid grid-cols-2 gap-5'>
        <div>
          <FormRow size='s'>
            <Label size='s'>
              Cost view
            </Label>

            <Select
              className='w-full'
              value={viewId + ''}
              loading={library.status === 'loading'}
              options={library.status === 'success' ? [
                {
                  label: '',
                  value: null
                },
                ...library.data.map((view) => ({
                  value: '' + view.id,
                  label: view.name
                }))
              ] : []}
              onChange={(newViewId) => {
                onChange({
                  ...widget,
                  settings: {
                    ...widget.settings,
                    viewId: +newViewId
                  }
                });
              }}
            />
          </FormRow>

          <DateSettingsControl
            value={widget.settings}
            onChange={(settings) => {
              onChange({
                ...widget,
                settings: {
                  ...widget.settings,
                  ...settings
                }
              });
            }}
          />
        </div>

        <div>
          <CostAttrsControl
            value={widget.settings}
            onChange={(settings) => {
              onChange({
                ...widget,
                settings: {
                  ...widget.settings,
                  ...settings
                }
              });
            }}
          />
        </div>
      </div>}
    >
      {(
        viewEntry.status === 'success' &&
        chart.status === 'success' 
      ) ? (
        <>
          <SectionTitle className='mt-4 ml-5'>
            {viewEntry.data.name}
          </SectionTitle>

          <PeriodChart
            granularity={granularity}
            currentKey='current_timeframe_cost'
            previousKey='previous_timeframe_cost'
            data={chart.data}
          />
        </>
      ) : viewId ? (
        <div className={editing ? 'w-1/2 pt-[320px] absolute' : ''}>
          <StatusOverlay status='loading' />
        </div>
      ) : (
        <div className='text-center mr-[50%] pt-[150px] font-medium text-silver-grey-500 text-[24px]'>
          Select view
        </div>
      )}
    </WidgetWrapper>
  );
}
