import { IconButton } from 'shared/Button';
import {useSaveViewDialog} from 'store/costLab';

export interface CreateViewButtonProps {
  disabled?: boolean;
}

export const CreateViewButton = (props: CreateViewButtonProps) => {
  const {
    disabled
  } = props;

  const openSaveViewDialog = useSaveViewDialog((store) => store.open);

  return (
    <IconButton
      className='text-[10px] shrink-0 w-9'
      icon='circle-plus'
      geometry='circle'
      hoverStyle='normal'
      type='tertiary'
      size='xs'
      direction='column'
      disabled={disabled}
      onClick={openSaveViewDialog}
    >
      Save As...
    </IconButton>
  );
}
