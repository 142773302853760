import type { Setter } from 'types/common';
import type { CostFilterFetchParams } from 'types/costViews';

import { useMemo } from 'react';
import { FormRow, Label } from 'shared/Form';
import { Checkbox, Select } from 'antd';

import { COST_DIMENSIONS, COST_TYPES } from 'types/costViews';
import { COST_DIMENSION_LABELS, COST_TYPE_LABELS } from 'shared/CostFilter/constants';

import { useCostColumnStore } from 'store/costColumns';
import { useGlobalState } from 'state/globalState';

interface Value extends CostFilterFetchParams{
  dashboardCost?: boolean;
}

export interface CostAttrsControlProps {
  value: Value;
  onChange: Setter<Value>;
}

export const CostAttrsControl = (props: CostAttrsControlProps) => {
  const {
    value,
    onChange
  } = props;

  const {
    dashboardCost,
    costType,
    costDimensions,
    costAmortization
  } = value;

  const { costColumns } = useCostColumnStore();
  const user = useGlobalState((state) => state.user);
  const costColumnsTabAvailable = useMemo(() => user?.permissions.includes('admin'), [user]);

  return <>
    <FormRow size='s'>
      <Checkbox
        checked={!!dashboardCost}
        onChange={(event) => {
          onChange({
            ...value,
            dashboardCost: event.target.checked
          });
        }}
      >
        Use dashboard cost attributes
      </Checkbox>
    </FormRow>

    <FormRow size='s'>
      <Label size='s'>
        Cost Type
      </Label>
      
      <Select
        className='w-full'
        disabled={dashboardCost}
        value={costType}
        onChange={(costType) => {
          onChange({
            ...value,
              costType
          });
        }}
        options={[
          ...COST_TYPES.map((costType) => ({
            value: costType,
            label: COST_TYPE_LABELS[costType]
          })),
          ...(costColumnsTabAvailable ? costColumns.map((column) => ({
            value: column.id,
            label: column.name
          })) : [])
        ]}
      />

      <Checkbox
        className='mt-1'
        disabled={dashboardCost}
        checked={costAmortization}
        onChange={(event) => {
          onChange({
            ...value,
            costAmortization: event.target.checked
          });
        }}
      >
        Amortization
      </Checkbox>
    </FormRow>

    <FormRow size='s'>
      <Label size='s'>
        Cost Dimensions
      </Label>
      
      <Select
        className='w-full'
        disabled={dashboardCost}
        value={COST_DIMENSIONS.filter((dim) => !costDimensions.includes(dim))}
        mode='multiple'
        onChange={(costDimensions) => {
          onChange({
            ...value,
            costDimensions: COST_DIMENSIONS.filter((dim) => !costDimensions.includes(dim))
          });
        }}
        options={COST_DIMENSIONS.map((value) => ({
          value,
          label: COST_DIMENSION_LABELS[value]
        }))}
      />
    </FormRow>
  </>;
}
