import { useMemo, useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { formatCurrency, formatPercent, formatRange } from 'helpers/formatter';
import { createNullableNumbersComparator } from 'helpers/sort';

import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { Page } from 'shared/Page';
import { Button2 as Button } from 'shared/Button';
import { Legend } from 'shared/Legend';
import { DateText } from 'shared/DateText'
import { PeriodChart } from 'shared/PeriodChart';
import { StatusOverlay } from 'shared/Overlay';
import { NumberCard } from './NumberCard';
import { ArrowButton } from './ArrowButton';
import { LinkSection } from './LinkSection';
import { DiagramSection } from './DiagramSection';
import { Section, SectionFooter, SectionGroupTitle, SectionTitle } from 'shared/Section';
import { ScrollPanes, ScrollPane, ScrollPanesRef } from 'shared/ScrollPanes';
import { RadioButton, RadioButtonGroup } from 'shared/RadioButtonGroup';

import { useUnitMetricsStore } from 'store/unitMetrics';
import { useUnitMetricsLibraryStore } from 'store/unitMetricsLibrary';
import { useCostViewsStore, useCostViewChart } from 'store/costLab';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { identity } from 'helpers/utils';
import {SERVER_DATE_FORMAT} from 'helpers/constants';
import {CostViewSection} from './CostViewSection';

const compareUnitMetrics = createNullableNumbersComparator('wow_change');
const compareCostViews = createNullableNumbersComparator('wow_change');

const INDEXES = [0, 1, 2];

type TabValue = 'overview' | 'cost' | 'unit';

export const SummaryDashboard = () => {
  const {
    unitMetrics: unitMetricsLibrary,
    fetchUnitMetricsLibrary,
    status: unitMetricsLibraryStatus,
  } = useUnitMetricsLibraryStore();

  const DEFAULT_GRANULARITY = 'day'

  const startMoment = moment().add(-8, 'days')
  const endMoment = moment().add(-1, 'days')

  const [startDate, endDate] = useMemo(() => [
    startMoment.format('YYYY-MM-DD'),
    endMoment.format('YYYY-MM-DD'),
  ], []);

  const prevStartMoment = startMoment.clone().subtract(endMoment.diff(startMoment, 'days') + 1, 'days')
  const prevEndMoment = endMoment.clone().subtract(endMoment.diff(startMoment, 'days') + 1, 'days')

  const last30DaysStart = moment().add(-30, 'days');
  const last30DaysEnd = moment().add(-1, 'days');
  const prevLast30DaysStart = last30DaysStart.clone().subtract(last30DaysEnd.diff(last30DaysStart, 'days') + 1, 'days')
  const prevLast30DaysEnd = last30DaysEnd.clone().subtract(last30DaysEnd.diff(last30DaysStart, 'days') + 1, 'days')

  const last30DaysTotalChart = useCostViewChart({
    startDate: last30DaysStart.format('YYYY-MM-DD'),
    endDate: last30DaysEnd.format('YYYY-MM-DD'),
    granularity: 'day',
    costType: 'unblended_cost',
    costDimensions: [],
    costAmortization: false,
    filter: {
      src: 'cur',
      filter: { operator: 'or', condition: [] }
    }
  });

  const prevLast30DaysTotalChart = useCostViewChart({
    startDate: prevLast30DaysStart.format('YYYY-MM-DD'),
    endDate: prevLast30DaysEnd.format('YYYY-MM-DD'),
    granularity: 'day',
    costType: 'unblended_cost',
    costDimensions: [],
    costAmortization: false,
    filter: {
      src: 'cur',
      filter: { operator: 'or', condition: [] }
    }
  });

  const mainChart = useDerivedDataEntry({ last30DaysTotalChart, prevLast30DaysTotalChart }, identity);

  const thisMonthStart = moment().startOf('month');
  const thisMonthEnd = moment().endOf('month');

  const lastMonthStart = moment().add(-1, 'month').startOf('month');
  const lastMonthEnd = moment().add(-1, 'month').endOf('month');

  const {
    getChartDataEntry,
    fetchChartData: fetchUnitChartData
  } = useUnitMetricsStore();

  const costViewLibrary = useCostViewsStore((store) => store.getLibrary({
    startDate: startMoment.format(SERVER_DATE_FORMAT),
    endDate: endMoment.format(SERVER_DATE_FORMAT)
  }));

  const topCostViews = useDerivedDataEntry({
    costViewLibrary
  }, ({
    costViewLibrary
  }) =>
    costViewLibrary.sort(compareCostViews).reverse().slice(0, 5)
  );

  const topUnitMetrics = useMemo(
    () => [...unitMetricsLibrary]
      .sort(compareUnitMetrics)
      .reverse()
      .slice(0, 5),
    [unitMetricsLibrary]
  );

  useEffect(() => {
    fetchUnitMetricsLibrary();
  }, []);

  useEffect(() => {
    topUnitMetrics.forEach(({ id }) => {
      fetchUnitChartData(id, startDate, endDate, DEFAULT_GRANULARITY);
    });
  }, [topUnitMetrics, startDate, endDate]);


  const chartDataEntries = topUnitMetrics.map(({ id }) => getChartDataEntry(id, startDate, endDate));

  const [currentTab, setCurrentTab] = useState<TabValue>('overview');
  const scrollPanesRef = useRef<ScrollPanesRef<string> | null>(null);

  const handleTabsChange = (newCurrentTab: 'overview' | 'unit' | 'cost') => {
    scrollPanesRef.current?.scrollToPane(newCurrentTab);
  }

  return (
    <Page>
      <Page.Head title='Summary Dashboard'>
        <div className='flex ml-auto'>

          <RadioButtonGroup
            name='currentTab'
            value={currentTab}
            onChange={(_, { value }) => {
              handleTabsChange(value);
            }}
          >
            {(['overview', 'cost', 'unit'] as const).map((tab) => (
              <RadioButton
                key={tab}
                value={tab}
              >
                {{
                  overview: 'Overview',
                  cost: 'Cost Views',
                  unit: 'Unit Metrics'
                }[tab]}
              </RadioButton>
            ))}
          </RadioButtonGroup>
        </div>
      </Page.Head>

      <ScrollPanes value={currentTab} onChange={setCurrentTab as (value: string) => void} ref={scrollPanesRef}>
        <ScrollPane value='overview' offset={-92}>
          <SectionGroupTitle>
            Overview
          </SectionGroupTitle>

          <Section className='mb-4'>
            <div className='grid grid-cols-3 gap-4 p-4 max-w-[900px]'>
              <NumberCard
                description='Last 30 days'
                startMoment={last30DaysStart}
                endMoment={last30DaysEnd}
              />
              <NumberCard
                description='This month'
                startMoment={thisMonthStart}
                endMoment={thisMonthEnd}
              />

              <NumberCard
                description='Last month'
                startMoment={lastMonthStart}
                endMoment={lastMonthEnd}
              />
            </div>
          </Section>

          <Section className='relative mb-4'>
            <SectionTitle className='px-5 h-[64px] flex items-center'>
              <Link to={`/costs-overview`}>
                Total Spend for Last 30 Days
              </Link>

              <Button
                to='/costs-overview'
                size='s'
                theme='black'
                layout='inline'
                iconRight='arrow-right'
                className='ml-auto'
              >
                View All
              </Button>
            </SectionTitle>

            <div className='h-[250px] -mt-5'>
              {mainChart.status === 'success' && (
                <PeriodChart
                  data={mainChart.data.last30DaysTotalChart}
                  prevData={mainChart.data.prevLast30DaysTotalChart}
                  granularity='day'
                  currentKey='current_timeframe_cost'
                  previousKey='previous_timeframe_cost'
                />
              )}
            </div>

            <SectionFooter>
              <Legend
                series={[
                  {
                    name: 'Current',
                    color: '#00b4d8',
                    subtitle: formatRange(last30DaysStart, last30DaysEnd, 'day')
                  },
                  {
                    name: 'Previous',
                    color: '#DA5665',
                    subtitle: formatRange(prevLast30DaysStart, prevLast30DaysEnd, 'day')
                  }
                ]}
              />
            </SectionFooter>

            <StatusOverlay status={mainChart.status} />
          </Section>

          <div className='grid grid-cols-3 gap-4'>
            <DiagramSection
              icon='region'
              title='Regions'
              breakdown='regions'
            />

            <DiagramSection
              icon='account'
              title='Accounts'
              breakdown='accounts'
            />

            <DiagramSection
              icon='service'
              title='Services'
              breakdown='services'
            />
          </div>
        </ScrollPane>

        <ScrollPane value='cost' offset={-92}>
          <SectionGroupTitle className='flex'>
            Cost Views

            <Button
              className='ml-auto'
              to='/costs-overview/library'
              size='s'
              theme='black'
              layout='inline'
              iconRight='arrow-right'
            >
              View All
            </Button>
          </SectionGroupTitle>

          <div className='relative grid grid-cols-2 gap-4'>
            {INDEXES.map((index) => {
              if (topCostViews.status !== 'success') {
                return null;
              }

              return (
                <CostViewSection
                  key={index}
                  viewId={topCostViews.data[index].id}
                  startMoment={startMoment}
                  endMoment={endMoment}
                  prevStartMoment={prevStartMoment}
                  prevEndMoment={prevEndMoment}
                />
              );
            })}
            
            <LinkSection
              to='/costs-overview?filtersOpened=true'
            >
              Create New Cost View
            </LinkSection>

            <Section className='relative p-5 col-span-2'>
              <SectionTitle className='mb-5'>
                Top {topCostViews.status === 'success' ? topCostViews.data.length : ''} Cost Views
              </SectionTitle>

              <Table
                size='small'
                dataSource={topCostViews.status === 'success' ? topCostViews.data : []}
                rowKey={({ id }) => id}
                pagination={false}
                columns={[
                  {
                    key: 'name',
                    dataIndex: 'name',
                    title: 'View Name',
                    render: (name: string, { id }: { id: number }) => <Link to={`/costs-overview/${id}`}>{name}</Link>
                  },
                  {
                    key: 'total',
                    title: 'Last 8 days',
                    dataIndex: 'total',
                    render: (total: null | number) => total === null ? 'n/a' : formatCurrency(total)
                  },
                  {
                    key: 'prev_total',
                    title: 'Previous Last 8 days',
                    dataIndex: 'prev_total',
                    render: (prevTotal: null | number) => prevTotal === null ? 'n/a' : formatCurrency(prevTotal)
                  },
                  {
                    key: 'wow_change',
                    title: 'Period-over-period Change %',
                    dataIndex: 'wow_change',
                    render: (_, metric) => metric.prev_total === 0 || !metric.wow_change ? 'n/a' : formatPercent(metric.wow_change - 1)
                  },
                ]}
              />

              <StatusOverlay status={topCostViews.status} />
            </Section>
          </div>
        </ScrollPane>

        <ScrollPane value='unit' offset={-92}>
          <SectionGroupTitle className='flex'>
            Unit Metrics

            <Button
              to='/unit-metrics/library'
              size='s'
              theme='black'
              layout='inline'
              iconRight='arrow-right'
              className='ml-auto'
            >
              View All
            </Button>
          </SectionGroupTitle>

          <div className='relative grid grid-cols-2 gap-4'>
            {INDEXES.map((index) => {
              const unitMetric = topUnitMetrics[index];
              const chartDataEntry = chartDataEntries[index];

              const libraryLoaded = unitMetricsLibraryStatus === 'success';

              if (libraryLoaded && index >= topUnitMetrics.length) {
                return null;
              }

              const status = libraryLoaded ?
                chartDataEntry.status :
                unitMetricsLibraryStatus;

              return (
                <Section className='relative h-[290px]' key={index}>
                  <SectionTitle className='flex px-5 pt-4'>
                    {unitMetric && (
                      <>
                        {unitMetric.name}
                        <ArrowButton to={`/unit-metrics/${unitMetric.id}`} />
                      </>
                    )}
                  </SectionTitle>

                  <PeriodChart
                    height={220}
                    data={chartDataEntry?.chartData || []}
                    granularity={DEFAULT_GRANULARITY}
                    currentKey='current_timeframe_unit_metric'
                    previousKey='previous_timeframe_unit_metric'
                  />

                  <div className='flex mb-5 ml-5 gap-5'>
                    <DateText
                      description={'Current Period'}
                      startMoment={startMoment}
                      endMoment={endMoment}
                    />

                    <DateText
                      description={'Previous Period'}
                      startMoment={prevStartMoment}
                      endMoment={prevEndMoment}
                    />
                  </div>

                  <StatusOverlay status={status} />
                </Section>
              );
            })}
            
            <LinkSection
              to='/unit-metrics'
            >
              Create New Unit Metric
            </LinkSection>

            <Section className='relative p-5 mb-4 col-span-2'>
              <SectionTitle className='mb-5'>
                Top {topUnitMetrics.length} Unit Metrics
              </SectionTitle>

              <Table
                size='small'
                dataSource={topUnitMetrics}
                rowKey={({ id }) => id}
                pagination={false}
                columns={[
                  {
                    key: 'name',
                    dataIndex: 'name',
                    title: 'Metric Name',
                    render: (name: string, { id }: { id: number }) => <Link to={`/unit-metrics/${id}`}>{name}</Link>
                  },
                  {
                    key: 'daily_average',
                    title: 'Last 8 Days',
                    dataIndex: 'daily_average',
                    sorter:  createNullableNumbersComparator('daily_average'),
                    render: (dailyAverage, metric) => <div className='truncate'>
                      {!metric.library_data ? 'New or edited library entries take up to 6 hours to update' : !dailyAverage ? '' : formatCurrency(dailyAverage)}
                    </div>
                  },
                  {
                    key: 'prev_daily_average',
                    title: 'Previous Last 8 days',
                    dataIndex: 'prev_daily_average',
                    sorter:  createNullableNumbersComparator('prev_daily_average'),
                    render: (prevDailyAverage, metric) => !metric.library_data || !prevDailyAverage ? '' : formatCurrency(prevDailyAverage)
                  },
                  {
                    key: 'wow_change_daily',
                    title: 'Period-over-period Change %',
                    dataIndex: 'wow_change',
                    sorter: createNullableNumbersComparator('wow_change_daily'),
                    render: (wowChange, metric) => <div className='truncate'>
                      {metric.prev_daily_average === 0 ? 'Undefined (divide by 0)' : !wowChange ? '' : formatPercent(wowChange)}
                    </div>
                  },
                ]}
              />

              <StatusOverlay status={unitMetricsLibraryStatus} />
            </Section>
          </div>
        </ScrollPane>
      </ScrollPanes>
    </Page>
  );
};
