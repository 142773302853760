import type {ReactNode} from 'react';

export interface LegendProps  {
  className?: string;
  series?: {
    name: ReactNode,
    color: string,
    subtitle?: ReactNode
  }[];
}

export const Legend = ({ series, className = '' }: LegendProps) => (
  <div className={`flex items-center gap-5 ${className}`}>
    {series?.map((serie, index) => (
      <div className='flex items-center my-1 whitespace-nowrap text-body' key={index}>
        <div className='w-[10px] h-[10px] rounded-[50%] mr-2' style={({ backgroundColor: serie.color })} />
        <div>
          {serie.name}
          {serie.subtitle && <div className='text-gray-500 text-caption-2'>
            {serie.subtitle}
          </div>}
        </div>
      </div>
    ))}
  </div>
);
