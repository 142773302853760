import type { Granularity } from 'types/common';
import type { DataEntry } from 'types/dataEntries';
import type { CostViewFilter } from 'types/costViews/filters';
import type { DrillDownStep, DrillDownField, DrillDownData } from 'types/costViews/drillDown';

import { Section, SectionTitle } from 'shared/Section';
import { DrilldownStep } from './DrilldownStep';
import { StatusOverlay } from 'shared/Overlay';
import { Button2 as Button } from 'shared/Button';
import { useGlobalState } from 'state/globalState';
import { useCostOverviewStore } from '../useCostOverviewStore';

export interface DrilldownSectionProps {
  className?: string;
  startDate: string;
  endDate: string;
  granularity: Granularity;
  filter: CostViewFilter;
  breakdown: DrillDownField;
  drillDownSteps: DrillDownStep[];
  drillDown: DataEntry<DrillDownData>;
  availableSteps: DrillDownField[][],
  availableBreakdowns: DrillDownField[],
  setCurrentStep: (value: string) => void;
  setBreakdown: (field: DrillDownField) => void;
  swapStepField: (oldField: DrillDownField, newField: DrillDownField) => void;
  swapStepValue: (field: DrillDownField, value: string) => void;
  removeDrillDown: () => void;
  rollbackDrillDown: () => void;
}

export const DrilldownSection = (props: DrilldownSectionProps) => {
  const {
    className = '',
    startDate,
    endDate,
    granularity,
    filter,
    breakdown,
    drillDownSteps,
    drillDown,
    availableSteps,
    availableBreakdowns,
    setCurrentStep,
    setBreakdown,
    swapStepField,
    swapStepValue,
    removeDrillDown,
    rollbackDrillDown
  } = props;

  const { 
    setDrillDownOpened,
  } = useCostOverviewStore()

  const logEvent = useGlobalState((state) => state.logEvent);

  const hasBackButton = drillDownSteps.length > 1

  const handleClearClick = () => {
    removeDrillDown()
    logEvent('clear_drilldown')
  }

  const handleRollbackClick = () => {
    rollbackDrillDown()
    logEvent('rollback_drilldown')
  }

  return (
    <Section className={`relative flex flex-col ${className}`}>
      <Section.Head wfull className='shrink-0 grow-0'>
        <SectionTitle>
          Drilldown
        </SectionTitle>

        <Button
          className='ml-auto'
          layout='inline'
          theme='black'
          iconLeft='aside-layout'
          onClick={() => {
            setDrillDownOpened(false);
          }}
        >
          Hide
        </Button>
      </Section.Head>

      <div className='flex flex-col min-h-0 px-4 py-3 overflow-y-auto gap-3 shrink grow'>
        {drillDownSteps.map(([field, value], index) => {
          return (
            <DrilldownStep
              startDate={startDate}
              endDate={endDate}
              granularity={granularity}
              filter={filter}
              drillDownSteps={drillDownSteps.slice(0, index)}
              key={field}
              field={field}
              value={value} 
              fieldOptions={availableSteps[index]}
              onFieldChange={(newField: DrillDownField) => {
                swapStepField(field, newField);
                logEvent('swap_drilldown_step', { 'new_field': newField, 'old_field': field })
              }}
              onValueChange={(newValue: string) => {
                swapStepValue(field, newValue);
                logEvent('swap_drilldown_step_value', { 'field': field })
              }}
            />
          );
        })}
        {availableBreakdowns.length > 0 && (
          <DrilldownStep
            startDate={startDate}
            endDate={endDate}
            granularity={granularity}
            filter={filter}
            drillDownSteps={drillDownSteps}
            field={breakdown}
            value={null} 
            fieldOptions={availableBreakdowns}
            onFieldChange={(key) => {
              setBreakdown(key);
              logEvent('set_breakdown', { 'breakdown': key, 'source': 'drilldown_pane' });
            }}
            onValueChange={(key) => {
              setCurrentStep(key);
              logEvent('set_drilldown_current_step', { 'field': breakdown, 'source': 'drilldown_pane' });
            }}
          />
        )}
      </div>
      <div className='flex px-5 py-2 shadow-border-t gap-2'>
        {hasBackButton && (
          <Button
            className='!grow'
            size='m'
            theme='black'
            iconLeft='arrow-back'
            onClick={handleRollbackClick}
          >
            Step Back
          </Button>
        )}

        <Button
          className='!grow'
          theme='black'
          size='m'
          iconLeft='close-circle'
          onClick={handleClearClick}
        >
          Clear and Close
        </Button>
      </div>

      <StatusOverlay status={drillDown.status} />
    </Section>
  )
}
