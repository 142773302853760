import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { FormRow, Label } from 'shared/Form';
import { Button2 as Button } from 'shared/Button';
import { Dialog, DialogBody, DialogFooter} from 'shared/Dialog';
import { useDashboardsStore, useCopyWidgetDialog } from 'store/dashboards';

export const CopyWidgetDialog = () => {
  const { id: widget, close } = useCopyWidgetDialog();
  const navigate = useNavigate();
  const dashboards = useDashboardsStore((store) => store.getLibrary());
  const [dashboardId, setDashboardId] = useState<number | 'new'>('new');

  const dashboardDraft = useDashboardsStore((store) => store.getDraft(dashboardId));
  const setDashboardDraft = useDashboardsStore((store) => store.setDraft);

  return (
    <Dialog
      className='w-[480px]'
      open={!!widget}
      onClose={close}
    >
      <Dialog.Title>
        Copy Widget
      </Dialog.Title>

      <DialogBody>
        <FormRow size='m'>
          <Label size='m'>
            Dashboard
          </Label>

          <Select 
            className='w-full'
            value={dashboardId}
            loading={dashboards.status === 'loading'}
            onChange={setDashboardId}
            options={
              dashboards.status === 'success' ? [
                {
                  value: 'new',
                  label: 'New Dashboard'
                },
                ...dashboards.data.map((dashboard) => ({
                  value: dashboard.id,
                  label: dashboard.name
                })) 
                ] : []
            }
          />
        </FormRow>
      </DialogBody>

      <DialogFooter>
        <Button
          size='m'
          disabled={dashboardDraft.status !== 'success'}
          onClick={() => {
            if (widget && dashboardDraft.status === 'success') {
              setDashboardDraft(dashboardId, {
                ...dashboardDraft.data,
                widgets: [
                  ...dashboardDraft.data.widgets,
                  widget
                ]
              });

              close();
              navigate(`/dashboards/${dashboardId === 'new' ? '' : dashboardId}`);
            }
          }}
        >
          Copy Widget
        </Button>
      </DialogFooter>

    </Dialog>
  );
}
