import type { Moment } from 'moment';
import type { DataEntry } from 'types/dataEntries';
import type { Granularity } from 'types/common';
import type { CostViewRequestFilter } from 'types/costViews';

import { Select } from 'antd';
import { Section, SectionTitle } from 'shared/Section';
import { TagBreakdownTable } from './TagBreakdownTable';
import { useTagKey } from 'hooks/useTagKey';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useGlobalState } from 'state/globalState';
import { useCostViewConstructor } from 'store/costLab';

export interface TagAnalyzerSectionProps {
  className?: string;
  startDate: string;
  endDate: string;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  granularity: Granularity;
  requestFilter: DataEntry<CostViewRequestFilter>;
}

export const TagAnalyzerSection = (props: TagAnalyzerSectionProps) => {
  const {
    className = '',
    startDate,
    endDate,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    granularity,
    requestFilter
  } = props;

  const [tagKey, setTagKey] = useTagKey();
  const logEvent = useGlobalState((state) => state.logEvent);
  const costViewConstructor = useCostViewConstructor();

  const tags = useDerivedDataEntry({
    costViewConstructor,
  }, ({
    costViewConstructor
  }) => (
    (costViewConstructor.cur?.rules.tags?.options || []) as string[]
  ).map(
    (value) => ({ value, label: value })
  ));

  return (
    <Section className={className}>
      <div className='h-[60px] mx-5 flex gap-4 items-center'>
        <SectionTitle>Absolute Costs - Tag Analyzer</SectionTitle>

        <Select
          className='w-[340px]'
          value={tagKey}
          placeholder='Pick a tag key'
          options={tags.status === 'success' ? tags.data : []}
          disabled={tags.status !== 'success'}
          onChange={(key) => {
            setTagKey(key);
            logEvent('set_tag_breakdown')
          }}
        />
      </div>

      <Section.Content>
        {tagKey && (
          <TagBreakdownTable
            startDate={startDate}
            endDate={endDate}
            startMoment={startMoment}
            endMoment={endMoment}
            prevStartMoment={prevStartMoment}
            prevEndMoment={prevEndMoment}
            granularity={granularity}
            requestFilter={requestFilter}
          />
        )}
      </Section.Content>
    </Section>
  );
}
