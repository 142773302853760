import { pluralize } from 'helpers/formatter';
import { useDatesQuery } from 'hooks/useDatesQuery';
import { useState } from 'react';
import { Button2 as Button } from 'shared/Button';
import { Dialog, DialogBody, DialogFooter } from 'shared/Dialog';
import { LibraryBlock } from '../content';
import { useComparisonDialog } from 'store/costLab';

export const ComparisonDialog = () => {
  const {
    startDate,
    endDate
  } = useDatesQuery();

  const [viewType, setViewType] = useState<'all' | 'base' | 'custom'>('all');
  const [searchTerm, setSearchTerm] = useState('');

  const {
    isOpened,
    value,
    setValue,
    apply,
    cancel
  } = useComparisonDialog();

  return (
    <Dialog
      className='w-[1280px]'
      open={isOpened()}
      onClose={cancel}
    >
      <Dialog.Title>
        Select views to compare
      </Dialog.Title>

      <DialogBody>
        <LibraryBlock
          startDate={startDate}
          endDate={endDate}
          viewType={viewType}
          onViewTypeChange={setViewType}
          searchTerm={searchTerm}
          onSearchTermChange={setSearchTerm}
          rowSelection={{
            selectedRowKeys: value,
            onChange: (keys) => {
              setValue(keys.map((key) => +key));
            }
          }}
        />
      </DialogBody>

      <DialogFooter>
        <Button
          size='m'
          onClick={apply}
        >
          Select {value.length} {pluralize(value.length, { one: 'view', other: 'views' })}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
