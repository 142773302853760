import type { Granularity } from 'types/common';

import { Icon } from 'shared/Icon';
import { ValueBox } from 'shared/ValueBox';
import { Button2 as Button } from 'shared/Button';

import { formatCurrency } from 'helpers/formatter';

import { useCumulative } from '../hooks/cumulative';
import { useBudgetDialog } from 'store/budgetDialog';
import { useSaveViewDialog } from 'store/costLab';
import { useCostViewsStore } from 'store/costLab';

export interface BudgetEditorProps {
  viewId: number | string;
  granularity: Granularity;
}

export const BudgetEditor = (props: BudgetEditorProps) => {
  const {
    viewId,
    granularity
  } = props;

  const isSavedView = useCostViewsStore((store) => {
    if (typeof viewId === 'string') {
      return false;
    }

    const view = store.getEntry(viewId);

    return view.status === 'success' && view.data.is_custom;
  });

  const openSaveViewDialog = useSaveViewDialog((store) => store.open);
  const openBudgetEditDialog = useBudgetDialog((store) => store.open);

  const budgetPeriod = granularity === 'quarter' ? 'quarterly' : 'monthly';
  const baseBudget = useCostViewsStore((store) => isSavedView ? store.getBudget(viewId, budgetPeriod) : null);

  const [cumulative] = useCumulative();

  return cumulative ? (
    <ValueBox
      label={`${granularity === 'quarter' ? 'Quarterly' : 'Monthly'} Budget`}
    >
      {!baseBudget ? 
        <Button
          className='mt-1'
          iconLeft='circle-plus'
          theme='black'
          size='xs'
          layout='inline'
          onClick={() => {
            if (isSavedView) {
              openBudgetEditDialog({
                id: -1,
                name: 'budget',
                value: 0,
                period: budgetPeriod
              });
            } else {
              openSaveViewDialog();
            }
          }}
        >
          {isSavedView ? 'Set Budget' : 'Save As New to Set Budget'}
        </Button>
        :
        <Button
          className='!font-normal'
          layout='inline'
          theme='black'
          size='l'
          onClick={() => {
            openBudgetEditDialog(baseBudget);
          }}
        >
          {formatCurrency(baseBudget.value)}
          <Icon icon='edit' />
        </Button>
      }
    </ValueBox>
  ) : null;
};
