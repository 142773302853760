import type { DataEntry } from 'types/dataEntries';
import type { CostViewRequestFilter, DrillDownFetchParams } from 'types/costViews';
import type { Granularity } from 'types/common';
import type { Moment } from 'moment';

import { useMemo } from 'react'
import { DateText } from 'shared/DateText';
import { columnBuilder, Table } from 'shared/Table/Table'
import { StatusOverlay } from 'shared/Overlay';
import { ExportToCsvButton } from 'shared/ExportToCsvButton';
import { useTagKey } from 'hooks/useTagKey';
import { useCostFilterValue } from 'hooks/costFilter';
import { useDrillDownQuery } from 'hooks/useDrillDownQuery';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useCostViewDrillDown } from 'store/costLab';
import { identity } from 'helpers/utils';

export interface TagBreakdownTableProps {
  startDate: string;
  endDate: string;
  granularity: Granularity
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  requestFilter: DataEntry<CostViewRequestFilter>;
}

export const TagBreakdownTable = (props: TagBreakdownTableProps) => {
  const {
    startDate,
    endDate,
    granularity,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    requestFilter
  } = props;

  const [tagKey] = useTagKey();

  const { drillDownSteps } = useDrillDownQuery();
  const costFilter = useCostFilterValue();

  const tagDrillDownParams: DataEntry<DrillDownFetchParams> = useDerivedDataEntry({
    startDate: startDate,
    endDate: endDate,
    granularity,
    drillDownSteps,
    filter: requestFilter,
    ...costFilter,
    breakdown: 'tags' as 'tags',
    tag: tagKey || undefined
  }, identity);

  const tagDrillDown = useCostViewDrillDown(tagDrillDownParams);

  const loadingTagDrilldown = tagDrillDown.status === 'loading';
  const tagDrilldownTableData = tagDrillDown.status === 'success' ? tagDrillDown.data.table_data : [];

  const data = useMemo(() => tagDrilldownTableData.map((row) => (
    { ...row, percent_change_display: row.previous_timeframe_cost === 0 ? 'n/a' : row.pop_change - 1 }
  )), [tagDrilldownTableData]);

  const columns = useMemo(() => {
    if (loadingTagDrilldown || !tagKey) {
      return [];
    }

    return columnBuilder()
      .sortable('tags', 'Tag Value')
      .sortable('current_timeframe_cost', 'Accrued Costs', 'currency', 'descend')
      .sortable('previous_timeframe_cost', 'Prev. Period Costs', 'currency')
      .sortable('percent_change_display', '% Change', 'percent_or_na')
      .sortable('pop_diff', '$ Change', 'currency')
      .columns();
  }, [tagKey, tagDrilldownTableData]);

  return (
    <div className='relative mb-5 isolate'>
      <div className='flex mb-4 gap-5'>
        <DateText
          description={'Current Period'}
          startMoment={startMoment}
          endMoment={endMoment}
        />

        <DateText
          description={'Previous Period'}
          startMoment={prevStartMoment}
          endMoment={prevEndMoment}
        />
      </div>

      <Table columns={columns} data={data} pageSize={10} scrollBodyHeight={300} idColumn={'tags'} />

      <div className='flex mt-5 mb-3'>
        <div className='ml-auto'>
          <ExportToCsvButton fileName='tag_drilldown_table.csv' data={tagDrilldownTableData} />
        </div>
      </div>

      <StatusOverlay status={tagDrillDown.status} />
    </div>
  )
}
