import { Icon } from 'shared/Icon';

export interface InputSearchProps {
  size?: 's' | 'm'
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: React.FocusEventHandler;
  onKeyPress?: React.KeyboardEventHandler;
}

export const InputSearch = (props: InputSearchProps) => {
  const {
    size = 's',
    value,
    placeholder,
    onChange,
    onBlur,
    onKeyPress
  } = props;

  return (
    <div className='flex items-center grow'>
      <Icon
        icon='search'
        className={{
          s: 'w-3 h-3 mr-2',
          m: 'w-4 h-4 mr-2'
        }[size]}
      />

      <input
        type='text'
        className={`w-full text-body bg-transparent outline-none border-b border-b-transparent
          ${{
            s: 'pt-[2px] pb-[1px] pl-5 -ml-5',
            m: 'pt-[6px] pb-[5px] pl-6 -ml-6'
          }[size]}
          hover:border-b-grey-200
          focus:border-b-blue
        `}
        value={value} 
        placeholder={placeholder}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
      />
    </div>
  );
}
