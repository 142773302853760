import type { Granularity } from 'types/common'
import type { Budget } from 'types/costViews';

import { 
  createBudget,
  updateBudget,
  deleteBudget
} from 'services/budgets';

import { message, InputNumber } from 'antd';

import { Dialog, DialogBody } from 'shared/Dialog';
import { Button2 as Button } from 'shared/Button';

import { useBudgetDialog } from 'store/budgetDialog';
import { useCostViewsStore } from 'store/costLab';

export interface BudgetDialogProps {
  viewId: number;
  granularity: Granularity;
}

export const BudgetDialog = (props: BudgetDialogProps) => {
  const { viewId, granularity } = props;
  const { id: budgetToEdit, open, close } = useBudgetDialog();

  const setBudget = useCostViewsStore((store) => store.setBudget);
  const discardBudget = useCostViewsStore((store) => store.discardBudget);

  const timeFrame = granularity === 'quarter' ? 'Quarterly' : 'Monthly'

  const handleCreateClick = (budget: Budget) => {
    createBudget(budget, viewId)
      .then(({ data: newBudget }) => {
        message.success(`${timeFrame} budget created`);
        setBudget(viewId, newBudget);

        close();
      }).catch(() => {
        message.error(`Unable to create budget`);
      });
  };

  const handleUpdateClick = (budget: Budget) => {
    updateBudget(budget)
      .then(({ data: newBudget }) => {
        message.success(`${timeFrame} budget updated`);

        setBudget(viewId, newBudget);
        close();
      }).catch(() => {
        message.error(`Unable to update budget`);
      });
  }

  const handleValueChange = (value: number | null) => {
    if (budgetToEdit) {
      open({
        ...budgetToEdit,
        value: value || 0
      });
    }
  }

  const handleSaveClick = (budget: Budget) => {
    if (budget.id === -1) {
      handleCreateClick(budget);
    } else {
      handleUpdateClick(budget);
    }
  }

  const handleDeleteClick = (budget: Budget) => {
    deleteBudget(budget.id)
      .then(() => {
        message.success(`${timeFrame} budget deleted`);
        discardBudget(viewId, budget.id)
        close();
      }).catch(() => {
        message.error(`Unable to delete budget`);
      });
  }

  return (
    <Dialog
      className='w-[320px]'
      open={budgetToEdit !== null}
      onClose={close}
    >
      {budgetToEdit && <>
        <Dialog.Title>
          {budgetToEdit.id === -1 ? `Set ${timeFrame} Budget` : `Edit ${timeFrame} Budget`}
        </Dialog.Title>

        <DialogBody>
          <InputNumber
            className='w-full'
            type='number'
            size='large'
            prefix='$'
            value={budgetToEdit.value}
            onChange={handleValueChange}
          />
        </DialogBody>

        <div className='flex p-8 pt-5 gap-2'>
          {budgetToEdit.id !== -1 && 
            <Button
              size='m'
              className='!bg-red basis-0 !grow'
              onClick={() => {
                handleDeleteClick(budgetToEdit);
              }}
            >
              Delete
            </Button>
          }

          <Button
            className='basis-0 !grow'
            size='m'
            onClick={() => {
              handleSaveClick(budgetToEdit);
            }}
          >
            Save
          </Button>
        </div>
      </>}
    </Dialog>
  );
}
