import type { Setter } from 'types/common';
import create from 'zustand';

interface ValueDialogStore<TValue> {
  value: TValue;
  setter: Setter<TValue> | null;

  setValue: Setter<TValue>;
  isOpened: () => boolean;

  open: (value: TValue, setter: Setter<TValue>) => void;
  apply: () => void;
  cancel: () => void;
}

export const createValueDialogStore = <TValue>(defaultValue: TValue) => create<ValueDialogStore<TValue>>((set, get) => ({
  value: defaultValue,
  setter: null,

  setValue: (value: TValue) => {
    set({ value });
  },

  isOpened: () => get().setter !== null,

  open: (value: TValue, setter: Setter<TValue>) => {
    set({
      value,
      setter
    });
  },

  apply: () => {
    const {
      value,
      setter
    } = get();

    if (setter !== null) {
      setter(value);
      set({
        value: defaultValue,
        setter: null
      });
    }
  },

  cancel: () => {
    set({
      value: defaultValue,
      setter: null
    });
  }
}));
